import { useLoaderData, Link } from "react-router-dom";
import { loadFromLibrary } from "../../util/libraryLoader";

import PageContent from "../../components/layout/PageContent";
import StoryCard from "../../components/ui/StoryCard";
import BtnJaunty from "../../components/ui/BtnJaunty";

function CreatedStoriesPage() {
  // Get loaded stories
  const data = useLoaderData();
  if (data && data.isError) {
    return <p>data.error.message</p>;
  }
  const stories = data;

  return (
    <>
      <PageContent title="">
        <Link to="/factory">
          <BtnJaunty
            colour="green"
            imgSrc="/img/art/machine.png"
            text="Write a new story"
          />
        </Link>
        <div className="story-cards-container">
          {stories.map((story) => {
            return <StoryCard story={story} key={story.id} />;
          })}
        </div>
      </PageContent>
    </>
  );
}

export default CreatedStoriesPage;

export async function loader({ request, params }) {
  return await loadFromLibrary("mine");
}
