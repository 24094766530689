import Modal from "../ui/Modal";
import classes from "./AvatarModal.module.css";
import Camera, { IMAGE_TYPES } from "react-html5-camera-photo";
import axios from "axios";
import { getAuthToken } from "../../util/auth";
import { json } from "react-router-dom";
import AvatarChoice from "./AvatarChoice";
import { serverHost } from "../../util/usefulServerCalls";

const AvatarModal = function (props) {
  function handleChooseAvatar(dataUri) {
    sendPhotoToServer(dataUri);
  }

  async function sendPhotoToServer(dataUri) {
    // Send photo to server
    console.log("📸 Sending avatar");
    const response = await axios.post(
      `${serverHost()}/users/myPhoto`,
      {
        photo: dataUri,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      }
    );

    if (response.data.status !== "success") {
      throw json({ message: "Could not choose avatar." }, { status: 500 });
    }

    // Reload the user
    props.userCtx.reloadUser();

    // Close the modal
    props.onClose();

    // Set user photo within context
  }

  //   function sendToServer({
  //     action: 'post',
  //     url: 'http://3.211.10.116/api/v1/users/me/profile-image',
  //     data: {
  //       image: dataUri
  //     },
  //   })

  const avatarFilenames = [
    // "questionMark.png",
    // "bunny.png",
    "button.png",
    "cat.png",
    "1.png",
    "2.png",
    "3.png",
    "4.png",
    "5.png",
    "6.png",
    "7.png",
    "8.png",
    "9.png",
    "10.png",
    "11.png",
    "12.png",
    "13.png",
    "14.png",
    "15.png",
    "16.png",
    "17.png",
    "18.png",
    "19.png",
    "20.png",
    "21.png",
    "22.png",
    "23.png",
    "24.png",
    "25.png",
    "26.png",
    "27.png",
    "28.png",
  ];
  // Create <Avatars>
  let avatarChoices = avatarFilenames.map((filename) => {
    return (
      <AvatarChoice
        onClick={handleChooseAvatar}
        imgSrc={`/img/users/${filename}`}
      />
    );
  });

  return (
    <Modal onClose={props.onClose} isShowing={props.isShowing}>
      <div className={classes.avatarModal}>
        <h2 className="SOMETHING">Choose a picture for your profile!</h2>
        <div className={classes.avatarGrid}>
          {avatarChoices}
          {/* <AvatarChoice
            onClick={handleChooseAvatar}
            imgSrc="/img/users/questionMark.png"
          />
          <AvatarChoice
            onClick={handleChooseAvatar}
            imgSrc="/img/users/finn.png"
          />

          <AvatarChoice
            onClick={handleChooseAvatar}
            imgSrc="/img/users/bunny.png"
          />

          <AvatarChoice
            onClick={handleChooseAvatar}
            imgSrc="/img/users/button.png"
          />

          <AvatarChoice
            onClick={handleChooseAvatar}
            imgSrc="/img/users/cat.png"
          /> */}
        </div>
      </div>
    </Modal>
  );
};

export default AvatarModal;
