import classes from "./AvatarChoice.module.css";

function AvatarChoice(props) {
  return (
    <div
      className={classes.avatarChoice}
      onClick={() => {
        props.onClick(props.imgSrc);
      }}
    >
      <img src={props.imgSrc} alt={props.alt} />
    </div>
  );
}

export default AvatarChoice;
