import React, { Suspense, useState } from "react";
import { getAuthToken } from "../util/auth";
import PageContent from "../components/layout/PageContent";
import {
  Link,
  Form,
  json,
  defer,
  Await,
  useLoaderData,
} from "react-router-dom";
import axios from "axios";
import classes from "./FriendsPage.module.css";

import FriendsPanel from "../components/friends/FriendsPanel";
import FindNewFriendsPanel from "../components/friends/FindNewFriendsPanel";
import TitleBar from "../components/ui/TitleBar";
import { serverHost } from "../util/usefulServerCalls";
import FriendsContext from "../store/FriendsContext";

function FriendsPage() {
  ////////////////////////////
  // SETUP
  ////////////////////////////
  const resData = useLoaderData();
  const loadedFriends = resData.friends;

  // Use state to track friends
  const [friends, setFriends] = useState(loadedFriends);
  // console.log("Friends (state): ", friends);

  // Use context to track friends
  // TODO - doubling up here, can I just use context and take out some of code otherwise?
  const friendsCtx = React.useContext(FriendsContext);

  ////////////////////////////
  // HANDLERS
  ////////////////////////////

  // Respond to friend request
  const respondToFriendRequestHandler = async (id, userResponse) => {
    console.log("Responding to friend request with response: ", userResponse);
    const response = await axios.patch(
      `${serverHost()}/friendships/${id}/respond?response=${userResponse}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    // TODO add error from response
    setFriends((prevFriends) => {
      return prevFriends.map((friend) => {
        if (friend.id === id) {
          friend.status = userResponse + "ed";
        }
        return friend;
      });
    });
    // Update friends context
    friendsCtx.fetchFriends();
  };

  // Remove a friend
  const removeFriendHandler = async (id, role) => {
    const response = await axios.patch(
      `${serverHost()}/friendships/${id}?role=${role}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    // TODO add error from response
    setFriends((prevFriends) => {
      return prevFriends.map((friend) => {
        if (friend.id === id) {
          friend.status = "removed";
        }
        return friend;
      });
    });
    // Update friends context
    friendsCtx.fetchFriends();
  };

  ////////////////////////////
  // ELEMENTS
  ////////////////////////////

  const friendsAccepted = friends.filter((friend) => {
    return friend.status === "accepted";
  });
  const friendsAcceptedCount = friendsAccepted.length;

  const friendsPending = friends.filter((friend) => {
    return friend.status === "pending";
  });
  const friendsPendingCount = friendsPending.length;
  // console.log("PENDING FRIENDS", friendsPending);

  ////////////////////////////
  // RENDER
  ////////////////////////////

  return (
    <>
      <div className={classes.friendsPageContainer}>
        {/* Panel to find new friends */}
        <FindNewFriendsPanel />

        {/* Panel to show friends - pending */}
        {friendsPendingCount > 0 && (
          <div className={classes.friendsPanelContainer}>
            <Suspense fallback={<p>Loading...</p>}>
              <TitleBar text="People who want to be your friend" />
              <Await resolve={friends}>
                {(f) => (
                  <FriendsPanel
                    friends={f}
                    setFriends={setFriends}
                    filter="incoming"
                    onRespond={respondToFriendRequestHandler}
                    // onRemove={removeFriendHandler}
                  />
                )}
              </Await>
            </Suspense>
          </div>
        )}

        {/* Friends loading */}
        {friendsAcceptedCount > 0 && (
          <div className={classes.friendsPanelContainer}>
            <Suspense fallback={<p>Loading...</p>}>
              <TitleBar
                // text={`You have ${friendsAcceptedCount.length} friend${
                //   friendsAcceptedCount.length === 1 ? "" : "s"
                // }`}
                text="Your friends"
              />
              <Await resolve={friends}>
                {(f) => (
                  <FriendsPanel
                    friends={f}
                    setFriends={setFriends}
                    filter="accepted"
                    // onRespond={respondToFriendRequestHandler}
                    onRemove={removeFriendHandler}
                  />
                )}
              </Await>
            </Suspense>
          </div>
        )}

        {/* <div>
            <h1>👀 Find new friends:</h1>
            <Form method="POST">
              <input
                name="recipientEmail"
                placeholder="Enter an email address"
              ></input>
              <input
                name="requestMessage"
                placeholder="Your message for them"
              ></input>
              <button type="submit">Add friend</button>
              <br></br>
              <br></br>
              <i>
                Or perhaps have a unique link here that they can share with
                their friends themselves. Try both?
              </i>
            </Form>
          </div> */}
      </div>
    </>
  );
}

export default FriendsPage;

async function loadFriends() {
  const token = await getAuthToken();
  const response = await fetch(`${serverHost()}/friendships?status=relevant`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw json({ message: "Could not get friends." }, { status: 500 });
  }

  const resData = await response.json();
  console.log("loadFriends response: ", resData);
  return resData.data;
}

export async function loader({ request, params }) {
  return defer({
    friends: await loadFriends(),
  });
}

// Action that runs when the form is submitted (via router)
export async function action({ request }) {
  // Get the form data
  const data = await request.formData();
  const inviteData = {
    recipientEmail: data.get("recipientEmail"),
    requestMessage: data.get("requestMessage"),
  };

  try {
    const token = await getAuthToken();
    // Log in using fetch (since if error we want to return the response)
    // Axios will just throw an error which means we don't get the response
    let response = await fetch(`${serverHost()}/friendships`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(inviteData),
    });
    response = await response.json();

    // Return so we are not redirected, and render errors on the form
    if (response.status !== "success") {
      alert(
        "Something went wrong and we couldn't send a friend request. Please refresh the page."
      );
      throw json(
        { message: "Could not send a friend request." },
        { status: 500 }
      );
      return response;
    }

    // Show success message
    alert("Friend request sent! It said: " + inviteData.requestMessage);
    console.log(response);
    return response;
  } catch (error) {
    // If we throw a 500, the message will be shown on error page
    throw json(
      { message: "Could not send a friend request." },
      { status: 500 }
    );
  }
}
