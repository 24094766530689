import { redirect } from "react-router-dom";

// Duration token has left before expiry
export function getTokenDuration() {
  const storedExpirationDate = localStorage.getItem("expiration");
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();
  return duration;
}

// Gets the token from local storage, and sets as EXPIRED if expired
export function getAuthToken() {
  const token = localStorage.getItem("token");

  if (!token || token === "undefined" || token === "EXPIRED") {
    console.log("No token found");
    return null;
  }

  const tokenDuration = getTokenDuration();

  if (tokenDuration < 0) {
    return "EXPIRED";
  }

  return token;
}

// Used to load the token in the router
export function tokenLoader() {
  const token = getAuthToken();
  return token;
}

// Return whether user is logged in, to protect routes
export function isLoggedInLoader() {
  const token = getAuthToken();
  if (!token || token === "EXPIRED" || token === "undefined") {
    return redirect("/login");
  } else {
    return null;
  }
}
