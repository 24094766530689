import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  NavLink,
} from "react-router-dom";
import storyParameterOptionsMaker from "../../util/storyParameterOptionsMaker";
import Slider from "@mui/material/Slider";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import EmojiPicker from "emoji-picker-react";
import TitleBar from "../ui/TitleBar";
import BtnJaunty from "../ui/BtnJaunty";
import StoryLives from "./StoryLives";

import classes from "./StorySetup.module.css";

function StorySetup() {
  // This is from outlet on story root, where story is loaded
  const [
    storyContent,
    setStoryContent,
    sharesFromMe,
    setSharesFromMe,
    sharesWithMe,
    setSharesWithMe,
    storyParameters,
    setStoryParameters,
  ] = useOutletContext();

  // If story is not loaded, redirect to story root. Can't use Navigate for this as needs to be in useEffect which isn't working
  // how I expect it to.
  let params = useParams();
  let storyIdInURL = params.id;
  if (!storyContent.id) {
    window.location.replace(`/story/${storyIdInURL}`);
  }

  // Things we can set up:
  // - Main character's name
  // - Main character's gender
  // - 2nd character's name
  // - 2nd character gender
  // - Lives
  // - Emoji for lives

  // Story parameters that can be set up
  // const [storyParameters, setStoryParameters] = useState({
  //   difficulty: [],
  //   lives: 5,
  //   livesEmoji: "❤️",
  // });

  // Story parameter options
  // Build an object
  // We only offer the options that are available in the story
  let storyParameterOptions = storyParameterOptionsMaker(storyContent);
  let chooseDifficulty = storyParameterOptions.difficulty.length > 1;
  let chooseLives = true;
  let chooseEmoji = true;

  // Emoji settings
  let emojiPreviewConfig = {
    defaultEmoji: "fe0f",
    defaultCaption: "",
    showPreview: false,
  };

  // Change emoji state
  const handleChangeEmoji = (emoji) => {
    console.log("Setting emoji to: ", emoji);
    setStoryParameters((storyParameters) => {
      return { ...storyParameters, livesEmoji: emoji };
    });
  };

  // Change number of lives
  const handleChangeNumberOfLives = (value) => {
    console.log("Setting number of lives to: ", value);
    setStoryParameters((storyParameters) => {
      return { ...storyParameters, numberOfLives: value };
    });
  };

  // Select difficulty of story
  const handleSelectDifficulty = (value) => {
    console.log("Setting difficulty to: ", value);
    setStoryParameters((storyParameters) => {
      return { ...storyParameters, difficulty: value };
    });
  };

  return (
    <>
      {/* <div className={classes.livesPreviewContainer}> */}
      <StoryLives
        livesEmoji={storyParameters.livesEmoji}
        numberOfLives={storyParameters.numberOfLives}
      />
      {/* </div> */}

      <div className={classes.storySetupContainer}>
        <p>
          Here you can decide how many 'helpers' you have. Each helper allows
          you to listen to a word you can't read. Can you finish the whole story
          without using up all of your helpers?
        </p>
        {chooseLives && (
          <div className={classes.sliderContainer}>
            <TitleBar text="Number of helpers" />
            <div className={classes.slider}>
              <Slider
                defaultValue={5}
                step={1}
                min={1}
                max={10}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event, newValue) => {
                  handleChangeNumberOfLives(newValue);
                }}
              />
            </div>
          </div>
        )}
        {chooseEmoji && (
          <div>
            <TitleBar text="Type of helpers" />
            <EmojiPicker
              searchDisabled={false}
              previewConfig={emojiPreviewConfig}
              width="30em"
              height="20rem"
              onEmojiClick={(emojiObject) => {
                handleChangeEmoji(emojiObject.emoji);
              }}
            />
          </div>
        )}
      </div>
      <div className={classes.bottomRightCornerBtn}>
        {/* <NavLink to={`/story/${storyContent.id}/read`}> */}
        <BtnJaunty
          text="Read the story!"
          colour="purple"
          to={`/story/${storyContent.id}/read`}
          imgSrc="/img/art/elements/arrowForward.png"
        />
        {/* </NavLink> */}
      </div>
    </>
  );

  // return (
  //   <div className="flex-col">
  //     <h1>Set up the story</h1>

  //     {/* Choose difficulty at some stage - leave out for now */}
  //     {/* {chooseDifficulty && (
  //       <div>
  //         <h2>Choose difficulty</h2>
  //         <ToggleButtonGroup
  //           color="primary"
  //           value={storyParameters.difficulty}
  //           exclusive
  //           onChange={(e, value) => {
  //             handleSelectDifficulty(value);
  //           }}
  //           aria-label="Level"
  //         >
  //           <ToggleButton value="easy">🐱</ToggleButton>
  //           <ToggleButton value="medium">🐯</ToggleButton>
  //           <ToggleButton value="hard">🦁</ToggleButton>
  //         </ToggleButtonGroup>
  //       </div>
  //     )} */}
  //     {chooseLives && (
  //       <div>
  //         <h2>Choose lives</h2>
  //         <Slider
  //           defaultValue={5}
  //           step={1}
  //           min={1}
  //           max={10}
  //           aria-label="Default"
  //           valueLabelDisplay="auto"
  //           onChange={(event, newValue) => {
  //             handleChangeNumberOfLives(newValue);
  //           }}
  //         />
  //       </div>
  //     )}
  //     {chooseEmoji && (
  //       <div>
  //         <h2>Choose emoji</h2>
  //         {/* <div className="flex-row">
  //           <button>❤️</button>
  //           <button>🧡</button>
  //           <button>💛</button>
  //           <button>💚</button>
  //           <button>💙</button>
  //           <button>💜</button>
  //         </div> */}
  //         <EmojiPicker
  //           searchDisabled={true}
  //           previewConfig={emojiPreviewConfig}
  //           width="30em"
  //           height="20rem"
  //           onEmojiClick={(emojiObject) => {
  //             handleChangeEmoji(emojiObject.emoji);
  //           }}
  //         />
  //       </div>
  //     )}
  //   </div>
  // );
}

export default StorySetup;
