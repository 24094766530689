import classes from "./FrontPage.module.css";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "../components/ui/Modal2";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import BtnJaunty from "../components/ui/BtnJaunty";
import UserContext from "../store/UserContext";
import { useRouteLoaderData, useNavigate } from "react-router-dom";
import PaperCard from "../components/ui/PaperCard";
import { serverHost } from "../util/usefulServerCalls";
import { useCustomNavigate } from "../util/navigateUtils";

function FrontPage(props) {
  // Create a signup form with email and password fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [
    isWaitingForSignupServerResponse,
    setIsWaitingForSignupServerResponse,
  ] = useState(false);
  const [isWaitingForEmailValidation, setIsWaitingForEmailValidation] =
    useState(false);

  // Modal for users who have already created a story
  const [showAlreadyCreatedStoryModal, setShowAlreadyCreatedStoryModal] =
    useState(false);

  // Views, fly in and out
  const [view, setView] = useState("start");
  const [stepDirection, setStepDirection] = useState("forwards");

  // Navigate
  const navigate = useNavigate();

  // User context and token to determine if logged in
  const userCtx = useContext(UserContext);
  const token = useRouteLoaderData("root");

  // Check parameters for "signup" as a state
  const urlParams = new URLSearchParams(window.location.search);
  const paramState = urlParams.get("state");

  // Show initial logo after 1 second, version depends on user context
  useEffect(() => {
    let timeout = 700;
    paramState === "signup" ? (timeout = 0) : (timeout = 700);
    setTimeout(() => {
      // Let's go if already logged in
      if (userCtx.user && token) {
        setView("letsGoMessage");
      }
      // Check query parameters for signup
      else if (paramState === "signup") {
        // If no view, show signup form
        if (!view) {
          setView("signupForm");
        } else {
          setView(undefined);
          setTimeout(() => {
            setView("signupForm");
          }, 600);
        }
      } else {
        setView("welcomeMessage");
      }
    }, timeout);
    return () => {
      clearTimeout();
    };
  }, [paramState]);

  // If there are query parameters, we may want to go immediately to the signup form
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const state = urlParams.get("state");
  //   if (state === "signup") {
  //     console.log("STATE IS SIGNUP");
  //     setView("signupForm");
  //     // handleSignupFormButtonClick();
  //   }
  // }, []);

  // Handle signup form submission
  const handleSignupSubmit = async (event) => {
    event.preventDefault();
    setIsWaitingForSignupServerResponse(true);

    // Use axios to send a POST request to the server
    let URL = `${serverHost()}/users/signup`;

    const response = await axios.post(URL, {
      name: name,
      email: email,
      password: password,
      passwordConfirm: password,
    });

    // If error, show error message
    if (response.data.status !== "success") {
      setError(response.data.message);
      return;
    }

    // If successful, tell them to check email
    setIsWaitingForSignupServerResponse(false);
    setIsWaitingForEmailValidation(true);
  };

  // Handle signup form button click
  const handleSignupFormButtonClick = () => {
    setStepDirection("forwards");
    setView(undefined);
    setTimeout(() => {
      setView("signupForm");
    }, 600);
  };

  // Handle try story button click
  const handleTryStoryClick = () => {
    // If user has already created a story, we can tell from localstorage
    // Show modal if so
    if (localStorage.getItem("generatedLoggedOutStory")) {
      setShowAlreadyCreatedStoryModal(true);
      return;
    }

    // If first time, go to factory
    setStepDirection("forwards");
    setView(undefined);
    setTimeout(() => {
      navigate(`/factory`);
    }, 800);
  };

  /////////////////////////////////////
  // COMPONENTS
  /////////////////////////////////////

  // For non-users, show welcome message/logo
  const welcomeMessage = (
    <CSSTransition
      in={view === "welcomeMessage"}
      timeout={600}
      classNames={
        stepDirection === "forwards"
          ? "TransitionSideFlyInForwards"
          : "TransitionSideFlyInBackwards"
      }
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.welcomeMessage}>
        <img
          src="/img/art/frontpage_logo.png"
          alt="Whizz Bang Story Maker"
          className={`${classes.logo} pulseSubtle`}
        />
        <div className={classes.welcomeActionButtons}>
          <img
            src="/img/art/frontpage_signup.png"
            alt="Sign up"
            className={`${classes.signupButtonImg} pulse`}
            onClick={handleSignupFormButtonClick}
          />
          <img
            src="/img/art/frontpage_tryit.png"
            alt="Try it"
            className={`${classes.signupButtonImg} ${classes.tryitButtonImg} pulse`}
            onClick={handleTryStoryClick}
          />
        </div>
      </div>
    </CSSTransition>
  );

  // For non-users, show signup form if they click the button
  const signupForm = (
    <CSSTransition
      in={view === "signupForm"}
      timeout={600}
      classNames={
        stepDirection === "forwards"
          ? "TransitionSideFlyInForwards"
          : "TransitionSideFlyInBackwards"
      }
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.signupFormContainer}>
        <PaperCard>
          <form onSubmit={handleSignupSubmit} className={classes.form}>
            <h1>Create your account</h1>
            <div className="form__field">
              <label htmlFor="name" className="form__primaryLabel">
                Name
              </label>
              <label htmlFor="email" className="form__secondaryLabel">
                This should be the child's first name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                autoFocus
                className="form__cleanInput"
                onChange={(event) => setName(event.target.value)}
                placeholder="Name"
              />
            </div>
            <div className="form__field">
              <label htmlFor="email" className="form__primaryLabel">
                Email
              </label>
              <label htmlFor="email" className="form__secondaryLabel">
                For younger children, this should be a supervising grown-up's
                email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                className="form__cleanInput"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="form__field">
              <label htmlFor="password" className="form__primaryLabel">
                Set your password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                className="form__cleanInput"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <BtnJaunty
              type="submit"
              text={isWaitingForSignupServerResponse ? "Loading..." : "Sign up"}
            />
            {error && <p className={classes.error}>{error}</p>}
          </form>
        </PaperCard>
      </div>
    </CSSTransition>
  );

  // For users, show "let's go" message
  const letsGoMessage = (
    <CSSTransition
      in={view === "letsGoMessage"}
      timeout={600}
      classNames={
        stepDirection === "forwards"
          ? "TransitionSideFlyInForwards"
          : "TransitionSideFlyInBackwards"
      }
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.welcomeMessage}>
        <img
          src="/img/art/frontpage_logo.png"
          alt="Whizz Bang Story Maker"
          className={`${classes.logo} pulseSubtle`}
        />
        <div className={classes.welcomeActionButtons}>
          {/* <img
            src="/img/art/frontpage/letsGo_hover.png"
            alt="Sign up"
            className={`${classes.signupButtonImg} pulse`}
            onClick={() => {
              setView(undefined);
              setTimeout(() => {
                // Navigate URL to home
                navigate("/home");
              }, 300);
            }}
          /> */}
          <BtnJaunty
            text={`Let's go ${userCtx.user.name}!`}
            onClick={() => {
              // Slide off screen
              setView(undefined);
              // Navigate URL to home
              setTimeout(() => {
                // Navigate URL to home
                navigate("/home");
              }, 500);
            }}
            colour="blue"
            imgSrc="/img/art/elements/arrowForward.png"
            size="large"
            pulse={true}
          />
        </div>
      </div>
    </CSSTransition>
  );

  // For users who have just signed up, show email validation notice
  const emailValidationNotice = (
    <Modal isShowing={isWaitingForEmailValidation}>
      <div className={classes.modalContent}>
        <img className="small-icon" src="/img/art/envelope.png" alt="Email" />
        <h2 className={classes.emailValidationTitle}>
          Please check your email {name}!
        </h2>
        <p>
          To start using Whizz Bang Story Maker,{" "}
          <b>please click the link in the email we have just sent you.</b>
        </p>
        <p>
          (If you cannot find it, please peer into the dreaded promotions, spam
          folders etc.)
        </p>
      </div>
    </Modal>
  );

  // Go to homepage with state
  const { goToHomepageWithState } = useCustomNavigate();

  // For users trying to create multiple stories, show modal
  const signupModal = (
    <Modal
      onClose={() => setShowAlreadyCreatedStoryModal(false)}
      isShowing={showAlreadyCreatedStoryModal}
    >
      <div className={classes.homeModalContent}>
        <div className={classes.modalHeaderContainer}>
          <h1 className={classes.modalHeader}>You need to create an account</h1>
          <br />
          <br />
          <p className={classes.modalParagraph}>
            You have already tried writing{" "}
            <Link
              to={`/story/${localStorage.getItem("generatedLoggedOutStory")}`}
            >
              <u>a story</u>
            </Link>
            . Please sign up to create more. You can connect with friends, share
            stories, and receive personalised stories made by your friends.
            <br /> <br />
          </p>
        </div>

        <div className={classes.modalButtonContainer}>
          <BtnJaunty
            onClick={() => {
              // This is a redirect, but with a query parameter of state=signup
              setShowAlreadyCreatedStoryModal(false);
              goToHomepageWithState("signup");
            }}
            text="Create your child's account now!"
            imgSrc="/img/art/book-stars.png"
            imgAlt="Sign up"
            colour="green"
          />
        </div>
      </div>
    </Modal>
  );

  // Classes for background image (greyscale when other info is there)
  let backgroundImageClasses = classes.backgroundImage;
  if (view === "welcomeMessage" || view === "letsGoMessage") {
    backgroundImageClasses += " " + classes.backgroundImageMuted;
  }
  return (
    <>
      {/* {isWaitingForEmailValidation && <h1>Please validate your email</h1>} */}
      {/* <button onClick={() => setIsWaitingForEmailValidation(true)}>Test</button> */}
      {emailValidationNotice}
      {welcomeMessage}
      {signupForm}
      {letsGoMessage}
      <div className={backgroundImageClasses} />
      {signupModal}
    </>
  );
}

export default FrontPage;
