import { Link } from "react-router-dom";
import classes from "./BigPictureLink.module.css";
import { useState } from "react";

// This is a component that shows a big picture with a link on top of it. It is used in the homepage and story homepage.
// Note that if to='modal', then it can take an onClick function, which will be called when the button is clicked.

function BigPictureLink({
  imgSrc,
  imgSrcHover,
  to,
  alt,
  text,
  className,
  height,
  hint,
  onClick,
}) {
  // Hover state
  const [over, setOver] = useState(false);

  // Style override
  const style = height ? { height: height } : {};

  // Hint (if shown)
  let hintDOM = "";
  if (hint) {
    hintDOM = (
      <div
        className={`
          hint
          ${hint.placement === "left" ? "hintLeft" : ""}
          ${hint.placement === "right" ? "hintRight" : ""}
          
          `}
      >
        <div className="wiggle">
          <p className="hintText">{hint.text}</p>
        </div>
      </div>
    );
  }

  // If it's a normal link, we just use link
  let innerLink = (
    <Link to={to}>
      <div
        className={classes.imgContainer}
        onMouseOver={() => setOver(true)}
        onMouseOut={() => setOver(false)}
      >
        <img style={style} src={over ? imgSrcHover : imgSrc} alt={alt} />
      </div>
    </Link>
  );

  // However, if there's a modal (rare), we need to use a button
  if (to === "modal") {
    innerLink = (
      <div onClick={onClick}>
        <div
          className={classes.imgContainer}
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
        >
          <img style={style} src={over ? imgSrcHover : imgSrc} alt={alt} />
        </div>
      </div>
    );
  }

  return (
    <div className={`${classes.bigPictureContainer}`}>
      {/* Show the hint if it is there  */}
      {hintDOM}

      {/* Show the img */}
      <div className={`${classes.bigPictureLink}  ${className}`}>
        <h1>{text}</h1>
        {innerLink}
      </div>
    </div>
  );
}

export default BigPictureLink;
