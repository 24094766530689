import { Link, json } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import FavouritesContext from "../../store/FavouritesContext";
import axios from "axios";
import { getAuthToken } from "../../util/auth";
import ShareCard from "../shares/ShareCard";
import Card from "../ui/Card";
import classes from "./StoryCard.module.css";
import UserImage from "../ui/UserImage";
import FavouriteControllerButton from "./FavouriteControllerButton";
import UserComment from "../ui/UserComment";
import UserContext from "../../store/UserContext";
import PaperImage from "../ui/PaperImage";
import { commentOnShareOnServer } from "../../util/usefulServerCalls";
import { serverHost } from "../../util/usefulServerCalls";

function StoryCard({ story, shareDetails, isShared }) {
  const { favourites, addFavourite, removeFavourite, itemIsFavourite } =
    useContext(FavouritesContext);
  const userCtx = useContext(UserContext);

  // Unpack shareDetails, these only exist if we want to show shares underneath
  // Only on share related pages(s)
  if (!shareDetails) {
    shareDetails = {};
  }
  const {
    shareId,
    sharerId,
    sharerPhoto,
    sharerName,
    sharerMessage,
    recipientComment,
  } = shareDetails;
  const sharerIncludedMessage = !!sharerMessage;

  // State for recipient comment
  const [recipientCommentState, setRecipientCommentState] =
    useState(recipientComment);

  ////////////////////////////
  // HANDLERS
  ////////////////////////////

  const removeFromFavouritesHandler = (storyId) => {
    // Ask user via alert if they are sure, if they are in the trasure trove
    if (window.location.href.includes("favourites")) {
      const proceed = window.confirm(
        "Are you sure you want to remove this from your favourites?"
      );
      if (!proceed) {
        return;
      }
    }
    // Remove from favourites
    removeFavourite(storyId); // Use context function
  };

  const addToFavouritesHandler = (storyId) => {
    addFavourite(storyId); // Use context function
  };

  // Use ref to allow user to comment on the share
  const commentRef = useRef();

  // Handler for comment on a share
  const commentOnShareHandler = async (comment) => {
    // Send comment to server, as recipient given the page we are on
    commentOnShareOnServer(comment, shareId, "recipient");
    setRecipientCommentState(comment);
  };

  // story.title.toLowerCase().charCodeAt(0) - 97 - 12 / 12  is a number between -1 and 1
  // Random initial rotation between -1 and 1 degrees
  let randomRotationCard =
    ((story.title.toLowerCase().charCodeAt(0) - 97 - 12) / 12) * 2;
  if (Math.abs(randomRotationCard) > 5) {
    randomRotationCard = 0;
  }

  // Random initial rotation between -1 and 1 degrees
  const randomRotationImage =
    ((story.title.toLowerCase().charCodeAt(1) - 97 - 12) / 12) * 2;

  // Random initial rotation between -25 and 25
  const randomRotationPaperclip =
    ((story.title.toLowerCase().charCodeAt(2) - 97 - 12) / 12) * 20;

  // Random initial rotation between -1 and 1 degrees
  let randomXPaperclip =
    ((story.title.toLowerCase().charCodeAt(0) - 97) / 24) * 200 + 20;
  if (Math.abs(randomXPaperclip) > 250) {
    randomXPaperclip = 20;
  }
  const randomYPaperclip =
    ((story.title.toLowerCase().charCodeAt(1) - 97) / 24) * 30;

  // Paperclip hue (-90 to +90)
  const paperclipHue =
    ((story.title.toLowerCase().charCodeAt(4) - 97 - 12) / 12) * 360;

  // Should paperclip appear
  const paperclipShouldAppear =
    story.title.toLowerCase().charCodeAt(7) - 97 < 6;

  // Four random values between 5 and 15
  const randomValues = Array.from(
    { length: 4 },
    () => Math.floor(Math.random() * 10 + 5)
    // 10
  );

  ////////////////////////////
  // ELEMENTS
  ////////////////////////////

  // Do not return anything if story has no images - for now, this is a bug
  console.log(story);
  if (!story.coverImage) {
    return null;
  }

  return (
    <>
      {/* Need first div to hold card and comments together, if comments appear */}
      <div>
        <div
          className={`${classes.storyCard} ${classes.skewShadow}`}
          style={{
            // Rotate can't be on paper layer due to z-index issues with the shadow
            transform: "rotate(" + randomRotationCard + "deg)",
          }}
        >
          {paperclipShouldAppear && (
            <img
              className={classes.storyCard__paperclip}
              src="/img/art/paperclip.png"
              style={{
                transform: "rotate(" + randomRotationPaperclip + "deg)",
                left: randomXPaperclip + "px",
                top: -randomYPaperclip - 20 + "px",
                filter: "hue-rotate(" + paperclipHue + "deg)",
              }}
            />
          )}
          <div
            className={`${classes.storyCard__paper} ${
              itemIsFavourite(story._id) && classes.storyCard__paper_fav
            }`}
            style={{
              borderRadius:
                "255px " +
                randomValues[0] +
                "px 295px " +
                randomValues[1] +
                "px/" +
                randomValues[2] +
                "px 225px " +
                randomValues[3] +
                "px 255px",
            }}
          >
            <Link to={`/story/${story.id}`}>
              {/* <div
                className={classes.storyCard__image}
                style={{
                  // Rotate can't be on paper layer due to z-index issues with the shadow
                  transform: "rotate(" + -2.5 * randomRotationImage + "deg)",
                  left: randomRotationImage + "px",
                }}
              >
                <img
                  src={story.coverImage}
                  style={{
                    borderRadius:
                      "255px " +
                      randomValues[0] / 2 +
                      "px 295px " +
                      randomValues[1] / 2 +
                      "px/" +
                      randomValues[2] / 2 +
                      "px 225px " +
                      randomValues[3] / 2 +
                      "px 255px",
                  }}
                />
              </div> */}
              <PaperImage seedText={story.title} imgSrc={story.coverImage} />
            </Link>
            <Link to={`/story/${story.id}`}>
              <div className={classes.storyCard__details}>
                <h1 className={classes.storyCard__title}>{story.title}</h1>
                <div className={classes.authorContainer}>
                  <UserImage user={story.author} />
                  <p>By {story.author.name}</p>
                </div>
              </div>
            </Link>
            <div className={classes.storyCard__favourite}>
              <FavouriteControllerButton
                storyId={story.id}
                isFavourite={itemIsFavourite(story.id)}
                onAdd={addToFavouritesHandler}
                onRemove={removeFromFavouritesHandler}
              />
            </div>
          </div>
        </div>
        {/* Lower part of story card */}
        <div
          style={{
            // Rotate can't be on paper layer due to z-index issues with the shadow
            transform: "rotate(" + randomRotationCard + "deg)",
          }}
        >
          {isShared && (
            <div className={classes.shareMessages}>
              {sharerIncludedMessage && (
                <UserComment
                  name={sharerName}
                  imgSrc={sharerPhoto}
                  comment={sharerMessage}
                />
              )}

              <UserComment
                name={userCtx.user.name}
                imgSrc={userCtx.user.photo}
                comment={recipientCommentState}
                canSubmit={true}
                onSubmit={commentOnShareHandler}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default StoryCard;
