import classes from "./WordHelper.module.css";
import { CSSTransition } from "react-transition-group";
import { GrAssistListening } from "react-icons/gr";

function WordHelper({
  word,
  wordSplit,
  wordHelperModalShowing,
  setWordHelperModalShowing,
  onEarClick,
  isSpeaking,
  setRightArrowIsVisible,
  setLeftArrowIsVisible,
}) {
  const handleWordHelperModalClick = () => {
    setWordHelperModalShowing(false);
    setRightArrowIsVisible(true);
    setLeftArrowIsVisible(true);
  };

  console.log("WordHelper: ", word, wordSplit, wordHelperModalShowing);

  const splitSounds = (wordSplit) => {
    function isSplitDigraph(sound) {
      const vowels = ["a", "e", "i", "o", "p", "A", "E", "I", "O", "U"];
      return (
        sound.length === 3 &&
        vowels.includes(sound[0]) &&
        (sound[2] === "e" || sound[2] === "E")
      );
    }

    // Split the word into sounds, based on -
    let sounds = wordSplit.split("-");
    let soundContainer = sounds.map((sound, index) => {
      let className;
      if (sound.length === 1) {
        className = classes.phonicDot;
        return (
          <span key={index} className={className + " " + classes.phonicGap}>
            {sound}
          </span>
        );
      } else if (isSplitDigraph(sound)) {
        className = classes.phonicSplit;
        return (
          <span
            className={classes.phonicSplitContainer + " " + classes.phonicGap}
          >
            <span className={classes.phonicSplit}>{sound}</span>
            <svg className={classes.phonicSplitCurve} viewBox="0 0 100 50">
              <path
                d="M 0,70 Q 50,0 100,70"
                stroke="orange"
                strokeWidth="7"
                fill="none"
              />
            </svg>
          </span>
        );
      } else if (sound.length > 1) {
        className = classes.phonicUnderline;
        return (
          <span key={index} className={className + " " + classes.phonicGap}>
            {sound}
          </span>
        );
      }
      return (
        <span key={index} className={className + " " + classes.phonicGap}>
          {sound}
        </span>
      );
    });
    return soundContainer;
  };

  let sounds = "";
  if (wordSplit) {
    sounds = splitSounds(wordSplit);
  }

  // Overly complex hidden logic below. Can use the commented code below instead.
  // And then need to add transition groups.
  return (
    <>
      {/* <>
        <div
          className={`${classes.wordHelper} ${
            wordHelperModalShowing ? "" : classes.hiddenOpacity
          }`}
        >
          <h1
            className={`${classes.word} ${
              isSpeaking ? classes.isSpeaking : ""
            }`}
          >
            {word}
          </h1>
          <GrAssistListening
            className={classes.earIcon}
            onClick={() => onEarClick(word)}
          />
        </div>
        <div
          className={`${classes.wordHelperModal} ${
            wordHelperModalShowing ? "" : classes.hiddenDisplay
          }`}
          onClick={handleWordHelperModalClick}
        ></div>
      </> */}

      {/* {wordHelperModalShowing && ( */}

      <CSSTransition
        //1. State (enter/exit)
        in={wordHelperModalShowing}
        //2. Duration
        timeout={500}
        //3. Class name
        // classNames="wordHelperSlideUp"
        classNames="TransitionSoftFade"
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.wordHelper}>
          {/* Show word and helper unless they are the same */}
          {word !== wordSplit && <h1 className={classes.word}>{word}</h1>}
          <h1 className={classes.wordSplit}>{sounds}</h1>
          {/* <h1 className={classes.wordSplit}>{wordSplit}</h1> */}
          <GrAssistListening
            className={classes.earIcon}
            onClick={() => onEarClick(word)}
          />
        </div>
      </CSSTransition>

      <CSSTransition
        //1. State (enter/exit)
        in={wordHelperModalShowing}
        //2. Duration
        timeout={200}
        //3. Class name
        classNames="wordHelperTransition"
      >
        <div
          className={classes.wordHelperModal}
          onClick={handleWordHelperModalClick}
        ></div>
      </CSSTransition>
    </>
  );
}

export default WordHelper;
