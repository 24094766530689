import { Form, useActionData, Link, useNavigation } from "react-router-dom";
import classes from "./LoginForm.module.css";
import PaperCard from "../ui/PaperCard";
import BtnJaunty from "../ui/BtnJaunty";

function LoginForm() {
  // Show loading spinner while submitting
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  // Deal with errors
  const actionData = useActionData(); // This is returned only if there is an error
  return (
    <>
      {/* <div className="story-cards-container"> */}
      <div className={classes.formContainer}>
        <PaperCard>
          <h1>Log in</h1>
          <br />
          <br />
          <Form method="post" className={classes.form}>
            {/* Only if there is an error */}
            {/* {actionData && actionData.errors && (
              <ul>
                {Object.values(actionData.errors).map((err) => (
                  <li key={err} className="form__error">
                    {err}
                  </li>
                ))}
              </ul>
            )} */}
            {actionData && actionData.message && <p>{actionData.message}</p>}
            <div className="form__field">
              <label htmlFor="email" className="form__primaryLabel">
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                autoFocus
                required
                className="form__cleanInput"
              />
            </div>
            <div className="form__field">
              <label htmlFor="image" className="form__primaryLabel">
                Password
              </label>
              <input
                id="password"
                type="password"
                name="password"
                required
                className="form__cleanInput"
              />
            </div>
            <BtnJaunty
              type="submit"
              text={isSubmitting ? "Logging in..." : "Log in"}
            />
          </Form>
        </PaperCard>
      </div>
      {/* </div> */}
    </>
  );
}

export default LoginForm;
