// Creates an object with the options for each parameter that can be set

function storyParameterOptionsMaker(storyContent) {
  // Story parameter options
  // Build an object
  let storyParameterOptions = {
    difficulty: [],
  };

  // Difficulty options
  if (storyContent.text.easy.length > 0) {
    storyParameterOptions.difficulty.push("easy");
  }
  if (storyContent.text.medium.length > 0) {
    storyParameterOptions.difficulty.push("medium");
  }
  if (storyContent.text.hard.length > 0) {
    storyParameterOptions.difficulty.push("hard");
  }

  return storyParameterOptions;
}

export default storyParameterOptionsMaker;
