import { useRouteError } from "react-router-dom";
import MainNavigation from "../components/layout/MainNavigation";
import classes from "./ErrorPage.module.css";
import PageContent from "../components/layout/PageContent";
import { CSSTransition } from "react-transition-group";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  // Automatic navigate if logged out
  const navigate = useNavigate();

  const error = useRouteError();
  console.log("This is the error");
  console.log(error);

  // State for if error message is showing
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Set up error message
  useEffect(() => {
    setTimeout(() => {
      setShowErrorMessage(true);
    }, 1000);

    // return () => {
    //   setShowErrorMessage(false);
    // };
  }, []);

  let title = "Whizz Bang Crash!";
  let message =
    "Something went wrong! Please try again, or contact whizzbang@storymaker.world for help.";

  // Axios errors
  if (error.response) {
    // console.log(error.response)
    if (error.response.data.message) {
      message = error.response.data.message;
      console.log("This is the error message", message);
      // If JWT malformed, we should log out:
      console.log("Redirecting to log out");
      navigate("/logout");
    } else if (error.response.status === 404) {
      title = "Not found!";
      message = "Could not find resource or page.";
    }
  }

  // From course / if we throw json error ourselves and want to parse it
  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = "Not found!";
    message = "Could not find resource or page.";
  }

  return (
    <>
      <MainNavigation />
      <PageContent>
        <CSSTransition
          in={showErrorMessage}
          timeout={900}
          classNames="TransitionModalFlyIn"
          mountOnEnter
          unmountOnExit
        >
          <div className={classes.errorPageContainer}>
            <div className={classes.crashImageContainer}>
              <img src="/img/art/animated/crash.png" alt="Crash" />
            </div>
            <div className={classes.textContainer}>
              <h2>{title}</h2>
              <p>{message}</p>
            </div>
            {/* TODO: image of broken robot here */}
          </div>
        </CSSTransition>
      </PageContent>
    </>
  );
}

export default ErrorPage;
