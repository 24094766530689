import Modal from "../ui/Modal2";
import BtnJaunty from "../ui/BtnJaunty";
import classes from "./StoryCreationError.module.css";

function StoryCreationError(props) {
  const goBackStepHandler = () => {
    props.setStep(8); // Setting step to 8 will go back to the previous step
    props.onClose(); // Close the modal
  };
  return (
    <Modal onClose={props.onClose} isShowing={props.isShowing}>
      <div className={classes.errorContainer}>
        <h2>Oh no! Something went wrong.</h2>
        <img className={classes.img} src="/img/art/animated/crash.png" alt="" />
        <p>
          This happens occasionally with robots... please try again, and cross
          your fingers a bit harder next time!
        </p>
        <br />
        <br />
        <BtnJaunty
          text="Click here to try again!"
          imgSrc="/img/art/elements/arrowBackward.png"
          onClick={() => {
            goBackStepHandler();
          }}
          colour="blue"
        />
      </div>
    </Modal>
  );
}

export default StoryCreationError;
