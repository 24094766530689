// Controls a list of friends of the user
import { getAuthToken } from "../util/auth";
import { serverHost } from "../util/usefulServerCalls";
import React, { createContext, useState, useEffect } from "react";
import { json } from "react-router-dom";

const FriendsContext = createContext({
  friends: [],
  fetchFriends: () => {},
  friendsAcceptedCount: 0,
  friendsPendingCount: 0,
});

export const FriendsContextProvider = (props) => {
  const [friends, setFriends] = useState([]);
  const [friendsAcceptedIds, setFriendsAcceptedIds] = useState([]);
  const [friendsAcceptedCount, setFriendsAcceptedCount] = useState(0);
  const [friendsPendingCount, setFriendsPendingCount] = useState(0);

  // Load friends
  useEffect(() => {
    fetchFriends();
  }, []);

  // Pull out friends accepted IDs. We use these within the SharedStoriesPage to filter out stories from friends we haven't accepted yet
  useEffect(() => {
    const friendsAcceptedIds = friends.map((friend) => {
      // Only return if accepted
      if (friend.status === "accepted") {
        return friend.otherId._id;
      }
    });
    // Remove duplicates
    const friendsAcceptedIdsUnique = [...new Set(friendsAcceptedIds)];
    setFriendsAcceptedIds(friendsAcceptedIdsUnique);
  }, [friends]);

  async function fetchFriends() {
    const token = getAuthToken();
    const response = await fetch(
      `${serverHost()}/friendships?status=relevant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw json({ message: "Could not get friends." }, { status: 500 });
    }

    const resData = await response.json();
    const friends = resData.data;

    const friendsAccepted = friends.filter((friend) => {
      return friend.status === "accepted";
    });
    const friendsAcceptedCount = friendsAccepted.length;

    const friendsPending = friends.filter((friend) => {
      return friend.status === "pending";
    });
    const friendsPendingCount = friendsPending.length;

    setFriends(resData.data);
    setFriendsAcceptedCount(friendsAcceptedCount);
    setFriendsPendingCount(friendsPendingCount);
    // Friends accepted IDs also set in useEffect

    console.log("👉 Friends loaded", resData.data);
    console.log("👉 Friends accepted count", friendsAcceptedCount);
    console.log("👉 Friends pending count", friendsPendingCount);
  }

  return (
    <FriendsContext.Provider
      value={{
        friends: friends,
        fetchFriends: fetchFriends,
        friendsAcceptedCount: friendsAcceptedCount,
        friendsPendingCount: friendsPendingCount,
        friendsAcceptedIds: friendsAcceptedIds,
      }}
    >
      {props.children}
    </FriendsContext.Provider>
  );
};

export default FriendsContext;
