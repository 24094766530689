import React, { createContext, useState } from "react";

export const AudioContext = createContext();

export const AudioContextProvider = ({ children }) => {
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <AudioContext.Provider value={{ audio, setAudio, isPlaying, setIsPlaying }}>
      {children}
    </AudioContext.Provider>
  );
};
