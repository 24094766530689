import { useEffect, useContext } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import ShareThisStoryPanel from "../shares/ShareThisStoryPanel";
import PutInLibraryPanel from "../shares/PutInLibraryPanel";
import UserContext from "../../store/UserContext";
import SharesWithMePanel from "../shares/SharesWithMePanel";
import TitleBar from "../ui/TitleBar";

import classes from "./StoryShare.module.css";
import BtnJaunty from "../ui/BtnJaunty";

function StoryShare() {
  // This is from outlet on story root, where story is loaded
  const [
    storyContent,
    setStoryContent,
    sharesFromMe,
    setSharesFromMe,
    sharesWithMe,
    setSharesWithMe,
  ] = useOutletContext();

  // If story is not loaded, redirect to story root. Can't use Navigate for this as needs to be in useEffect which isn't working
  // how I expect it to.
  let params = useParams();
  let storyIdInURL = params.id;
  if (!storyContent.id) {
    window.location.replace(`/story/${storyIdInURL}`);
  }

  console.log("Shares with me", sharesWithMe);
  // Scenarios:
  // 1. Story is written by a friend and shared by them
  // 2. Story is public (written elsewhere) and shared by friend
  // 3. Story is public and wasn't shared with me (I just found it)
  // 4. Story is written by me and not shared with anyone
  // 5. Story is written by me and shared with friends
  // 6. Story is written by me, shared with public, and friend shared it with me

  // Server calls for this story ID:
  // Get shares where I am the sharer
  // Get shares where it was shared with me

  // User context
  const userCtx = useContext(UserContext);

  // Controls for what panels are shown
  const isSharable =
    storyContent.author._id === userCtx.user._id ||
    storyContent.privacy === "public";

  const userIsAuthor = storyContent.author._id === userCtx.user._id;

  const wasSharedWithMe = sharesWithMe.length > 0;

  // const sharesFromMeDOM = (
  //   <>
  //     {sharesFromMe.map((share) => {
  //       return (
  //         <div key={share._id}>
  //           I shared this with {share.recipientId.name} and they said<br></br>
  //           <i>{share.recipientComment}</i>
  //         </div>
  //       );
  //     })}
  //   </>
  // );

  return (
    <>
      {/* // TODO - add in this card again */}
      {/* {wasSharedWithMe && <SharesWithMePanel sharesWithMe={sharesWithMe} />} */}
      {/* {wasSharedWithMe && <SharesWithMePanel sharesWithMe={sharesWithMe} />} */}

      {/* Panel for user to share with others. We pass through who they are already sharing with */}
      {isSharable && (
        <>
          <ShareThisStoryPanel
            alreadyShared={sharesFromMe}
            storyContent={storyContent}
          />
        </>
      )}

      {/* Allowing find new friends */}
      {/* {isSharable && (
        <article className={classes.section}>
          <FindNewFriendsPanel />
        </article>
      )} */}

      {/* Panel for users saying it's not sharable */}
      {!isSharable && (
        <>
          <article className={classes.section}>
            <TitleBar text="You can't share this story" />
            <p>
              You did not write this story, so unfortunately you can't share it
              with friends.
            </p>
          </article>
        </>
      )}
      {!isSharable && (
        <>
          <article className={classes.section}>
            <TitleBar text="Write your own story to share!" />
            <p>You can still write your own story and share it with friends.</p>
            <br></br>
            <BtnJaunty
              colour="green"
              imgSrc="/img/art/machine.png"
              text="Write a new story"
              to="/factory"
            />
          </article>
        </>
      )}

      {/* Panel for user to put story in library or class  TODO */}
      {/* {userIsAuthor && (
        <>
          <PutInLibraryPanel
            storyContent={storyContent}
            setStoryContent={setStoryContent}
          />
        </>
      )} */}
    </>
  );
}

export default StoryShare;
