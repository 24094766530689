import React, {
  useState,
  useReducer,
  useRef,
  useEffect,
  useContext,
} from "react";
import BuilderCard2 from "./BuilderCard2";
import FullScreenCard from "../../components/ui/FullScreenCard";
import CreatingStoryPlaceholder from "./CreatingStoryPlaceholder";
import BtnChoice from "../../components/ui/BtnChoice";
import BtnMinor from "../../components/ui/BtnMinor";
import BtnForFreeform from "../../components/ui/BtnForFreeform";
import FreeformEntry from "../../components/ui/FreeformEntry";
import LoadingOverlay from "./LoadingOverlay";
import StoryCreationError from "../../components/story/StoryCreationError";
import { getAuthToken } from "../../util/auth";
import axios from "axios";
import { Link, json, useNavigate } from "react-router-dom";
import UserContext from "../../store/UserContext";
import { serverHost } from "../../util/usefulServerCalls";
import Modal from "../../components/ui/Modal2";

import classes from "./MagicWriterPage.module.css";

import randomColor from "randomcolor";
import BtnJaunty from "../../components/ui/BtnJaunty";
import FriendsContext from "../../store/FriendsContext";

const initialBuildState = {
  NAME: "",
  ANIMAL: "",
  DESCRIPTION: "",
  MOOD: "",
  DIFFICULTY: "",
  HINT: "",
};

const reducer = (state, action) => {
  if (action.type) {
    return { ...state, [action.type]: action.payload };
  }
  // No longer using switch
  // switch (action.type) {
  //   case "SET_MAIN_CHARACTER_NAME":
  //     return { ...state, mainCharacterName: action.payload };
  //   case "SET_MAIN_CHARACTER_ANIMAL":
  //     return { ...state, mainCharacterAnimal: action.payload };
  //   case "SET_MAIN_CHARACTER_DESCRIPTION":
  //     return { ...state, mainCharacterDescription: action.payload };
  //   case "SET_STORY_HINT":
  //     return { ...state, storyHint: action.payload };
  //   case "SET_STORY_MOOD":
  //     return { ...state, storyMood: action.payload };
  //   case "DIFFICULTY":
  //     return { ...state, storyDifficulty: action.payload };
  //   default:
  //     return initialBuildState;
  // }
};

function Builder(props) {
  // Reducer
  const [buildState, dispatch] = useReducer(reducer, initialBuildState);

  // Navigation
  const navigate = useNavigate();

  // States
  const [step, setStep] = useState(undefined); // Set to 1 after a second
  const STEP_CREATE_STORY = 7; // Used in a few places so needs to be consistent
  const [isLoading, setIsLoading] = useState(false);
  const [builtStoryId, setBuiltStoryId] = useState(false);
  const [builtStory, setBuiltStory] = useState({});
  const [stepDirection, setStepDirection] = useState("forwards");
  const [creationError, setCreationError] = useState(false); // True if server throws and error and shows the modal in this case

  // Set step to 1 after a second
  useEffect(() => {
    setTimeout(() => {
      setStep(1); // Should be 1
    }, 300);
  }, []);

  // Animation
  const animationTime = 600;

  // User context
  const userCtx = useContext(UserContext);
  const userName = userCtx.user.name;

  // Next step
  // Note - we add a small delay for animation/DOM purposes
  const nextStepHandler = function () {
    console.log("Next step", step);
    setStepDirection("forwards");
    let nextStep = step + 1;
    setStep(undefined);
    setTimeout(() => {
      setStep(nextStep);
    }, animationTime);
  };

  const setStepHandler = function (step) {
    console.log("Set step", step);
    setStepDirection("forwards");
    setStep(undefined);
    setTimeout(() => {
      setStep(step);
    }, animationTime);
  };

  // Previous step
  // Note - we add a small delay for animation/DOM purposes
  const previousStepHandler = function () {
    // setStep((step) => step - 1);
    setStepDirection("backwards");
    let nextStep = step - 1;
    setStep(undefined);
    setTimeout(() => {
      setStep(nextStep);
    }, animationTime);
  };

  // Function for dispatching info and moving to next step
  const dispatchInfo = function (dispatchObj, e) {
    e.preventDefault();
    dispatch({
      type: dispatchObj.type,
      payload: dispatchObj.payload,
    });
    nextStepHandler();
  };

  // Function for dispatching info and moving to next step (but called from within another component, where event.preventDefault() already dealt with)
  // Special cases for certain types, e.g. when we want to add an animal to a list
  const dispatchInfoWithType = function (dispatchObj) {
    // e.preventDefault(); Not needed as in the underlying function where this is called
    dispatch({
      type: dispatchObj.type,
      payload: dispatchObj.payload,
    });

    // Special cases
    if (dispatchObj.type === "ANIMAL") {
      // Add animal to button list so it appears if we return to the page
      setCustomAnimalArray((animalArray) => [
        ...animalArray,
        dispatchObj.payload,
      ]);
    }

    nextStepHandler();
  };

  // Make story
  const makeStoryHandler = async function () {
    // Show loading screen whilst we write story (animation, chance to choose emojis etc.)
    setStepHandler(99);
    // setIsLoading(true);

    // Write story
    const textResponse = await fetch(`${serverHost()}/stories/magic/write`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify(buildState),
    });
    if (!textResponse.ok) {
      // alert("Could not write the story.");
      setCreationError(true);
      // throw json(
      //   { message: "Could not write the story." },
      //   {
      //     status: 500,
      //   }
      // );
    }
    const storyTextOnly = await textResponse.json();
    const storyId = storyTextOnly.data._id;
    console.log("textResponse", textResponse);

    // Handle errors here TODO
    setBuiltStory(storyTextOnly.data);

    // Generate words that are missing (but not asynch, as we want to show the story first)
    console.log("Calling magic admin");
    const wordResponse = fetch(`${serverHost()}/stories/magic/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({ storyId: storyId }),
    });
    // console.log("wordResponse", wordResponse);
    // if (!wordResponse.ok) {
    //   // alert("Could not get words.");
    //   setCreationError(true);
    //   throw json(
    //     { message: "Could not write the story." },
    //     {
    //       status: 500,
    //     }
    //   );
    // }

    // Generate images
    console.log("Now generating images");
    const imgResponse = await fetch(
      `${serverHost()}/stories/magic/illustrate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
        body: JSON.stringify({
          storyId: storyId,
          illustrationStyle: buildState.ILLUSTRATION_STYLE,
        }),
      }
    );
    // let imgResponse = await axios.post(
    //   `${serverHost()}/stories/magic/illustrate`,
    //   {
    //     storyId: storyId,
    //     illustrationStyle: buildState.ILLUSTRATION_STYLE,
    //   },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + getAuthToken(),
    //     },
    //   }
    // );
    console.log("imgResponse", imgResponse);

    if (!imgResponse.ok) {
      // alert("Could not get illustrate the story.");
      setCreationError(true);
    }
    const imgResponseData = await imgResponse.json();
    console.log("imgResponseData", imgResponseData);
    setBuiltStory(imgResponseData.data);

    // Set browser as having generated a story before (in local storage)
    // if they are logged out
    console.log(
      "We are checking userCtx.user to see if they are logged in:",
      userCtx.user
    );
    if ((userCtx.user && !userCtx.user._id) || !userCtx.user) {
      localStorage.setItem("generatedLoggedOutStory", storyId);
    }

    // Go to step 100 (i.e. finished and ready!)
    setStepHandler(undefined);
    setTimeout(() => {
      setStepHandler(100);
    }, 300);

    // Bump up the story count
    userCtx.incrementPoints({ type: "written", points: 1 });

    // Redirect to story page
    // navigate(`/story/${storyId}`);
  };

  // Character buttons set by me
  const animalArray = [
    "Unicorn",
    "Cat",
    // "Bus",
    "Panda",
    "Octopus",
    "Shark",
    "Owl",
    // "Cloud",
    // "Dragon",
    "Fox",
    "Carrot",
    // "Hand",
    // "Planet",
    // "Tree",
  ];
  const originalAnimalButtons = animalArray.map((animal) => {
    return (
      <BtnChoice
        btnText={animal}
        onClick={dispatchInfo.bind(null, { type: "ANIMAL", payload: animal })}
        isSelected={buildState.ANIMAL === animal}
        imgSrc={animal}
        key={animal}
      />
    );
  });

  // For anything that is not in the original array, user has entered it themselves
  const [customAnimalArray, setCustomAnimalArray] = useState([]);
  const customAnimalButtons = customAnimalArray.map((animal) => {
    // Does not have an image
    return (
      <BtnChoice
        btnText={animal}
        onClick={dispatchInfo.bind(null, { type: "ANIMAL", payload: animal })}
        isSelected={buildState.ANIMAL === animal}
        imgSrc={"Question-Mark"}
        key={animal + Math.random()}
      />
    );
  });
  // Concatenate
  const animalButtons = originalAnimalButtons.concat(customAnimalButtons);

  // Mood buttons
  const moodArray = [
    "😆 Funny",
    "🤩 Exciting",
    "😌 Calm",
    "🪄 Magical",
    "😨 Scary",
    "😮 Mysterious",
    "🙂 Happy",
    "😔 Sad",
  ];
  const moodButtons = moodArray.map((mood) => {
    return (
      <BtnChoice
        btnText={mood}
        onClick={dispatchInfo.bind(null, { type: "MOOD", payload: mood })}
        isSelected={buildState["MOOD"] === mood}
        // imgSrc={animal}
        key={mood}
      />
    );
  });

  // Get all friends names from context
  const friendsCtx = useContext(FriendsContext);
  const friends = friendsCtx.friends;
  const friendButtons = friends.map((friend) => {
    return (
      <BtnChoice
        btnText={friend.otherId.name}
        onClick={dispatchInfo.bind(null, {
          payload: friend.otherId.name,
          type: "NAME",
        })}
        isSelected={buildState["NAME"] === friend.otherId.name}
      />
    );
  });
  console.log("friends buttons");
  console.log(friends);

  // Navigate to story, after a short delay to allow
  // last animation to finish
  const navigateToStory = function (builtStoryId) {
    // Remove card
    setStepHandler(undefined);
    setTimeout(() => {
      navigate(`/story/${builtStoryId}`);
    }, animationTime + 200);
  };

  // Backgound colour
  // Chances each time step changes, but not for 'undefined' (i.e. when we are doing a page transition)
  let backgroundColour;
  if (step > 0) {
    backgroundColour = {
      backgroundColor: randomColor({
        luminosity: "bright",
        hue: "blue",
        saturation: "light",
        alpha: "0.7",
      }),
    };
  }

  const firstQuestionLoggedIn = (
    <BuilderCard2
      // CSS transition
      key={1}
      isShowing={step === 1}
      stepDirection={stepDirection}
      timeout={animationTime}
      // Content
      imgSrc="/img/robots/question/normal.png"
      question="What is the character's name?"
      freeformIfChosen={[
        <BtnForFreeform
          onFreeformSubmit={dispatchInfoWithType}
          freeFormSubmitPayloadType="NAME" // Sends back as payload within submit function
          btnText={"Write another name myself"}
          key={0}
        />,
      ]}
      buttonChoices={[
        <BtnChoice
          btnText={userName}
          onClick={dispatchInfo.bind(null, {
            payload: userName,
            type: "NAME",
          })}
          isSelected={buildState["NAME"] === userName}
        />,
      ].concat(friendButtons)}
    />
  );
  const firstQuestionLoggedOut = (
    <BuilderCard2
      // CSS transition
      key={1}
      isShowing={step === 1}
      stepDirection={stepDirection}
      timeout={animationTime}
      // Content
      imgSrc="/img/robots/question/normal.png"
      question="Let's write a story! What is the character's name?"
      freeformField={
        <FreeformEntry
          initialValue={buildState["NAME"]}
          freeFormSubmitPayloadType="NAME"
          onFreeformSubmit={dispatchInfoWithType}
        />
      }
    />
  );

  //////////////////////////////
  // FINAL RENDER
  // Cards appear 1 by 1 depending on the step
  // Each handler moves step on by 1
  // Then put to 99 for loading screen
  // 99 is the loading screen
  // 100 is the finished screen
  //////////////////////////////
  return (
    <>
      {creationError && (
        <StoryCreationError
          setStep={setStep}
          isShowing={creationError}
          onClose={() => {
            setCreationError(false);
            setStep(STEP_CREATE_STORY); // If they click outside modal, go back to step 8
          }}
        />
      )}
      <div className={classes.builderPage} style={backgroundColour}>
        {isLoading && <LoadingOverlay builtStoryId={builtStoryId} />}
        {userCtx.user && userCtx.user._id
          ? firstQuestionLoggedIn
          : firstQuestionLoggedOut}
        <BuilderCard2
          // CSS transition
          key={2}
          isShowing={step === 2}
          stepDirection={stepDirection}
          timeout={animationTime}
          // Content
          imgSrc="/img/robots/question/unicorn.png"
          question={`What is ${buildState["NAME"]}?`}
          backButton={
            <BtnMinor btnText="← Back" onClick={previousStepHandler} />
          }
          freeformIfChosen={[
            <BtnForFreeform
              onFreeformSubmit={dispatchInfoWithType}
              freeFormSubmitPayloadType="ANIMAL" // Sends back as payload within submit function
              btnText={"Make up my own - it can be anything!"}
              key={0}
            />,
          ]}
          buttonChoices={animalButtons}
        ></BuilderCard2>
        <BuilderCard2
          // CSS transition
          key={3}
          isShowing={step === 3}
          stepDirection={stepDirection}
          timeout={animationTime}
          // Content
          imgSrc="/img/robots/question/heart.png"
          question={`Tell me more! What is ${buildState["NAME"]} like? What does ${buildState["NAME"]} look like?`}
          backButton={
            <BtnMinor btnText="← Back" onClick={previousStepHandler} />
          }
          skipButton={
            <BtnMinor
              btnText="Skip →"
              onClick={dispatchInfoWithType.bind(null, {
                type: "DESCRIPTION",
                payload: "",
              })}
            />
          }
          preQuestion={
            // buildState.ANIMAL &&
            `${buildState["NAME"]} the ${buildState[
              "ANIMAL"
            ].toLowerCase()} - nice!`
          }
          freeformField={
            <FreeformEntry
              initialValue={buildState["DESCRIPTION"]}
              freeFormSubmitPayloadType="DESCRIPTION"
              onFreeformSubmit={dispatchInfoWithType}
            />
          }
        />
        <BuilderCard2
          // CSS transition
          key={4}
          isShowing={step === 4}
          stepDirection={stepDirection}
          timeout={animationTime}
          // Content
          imgSrc="/img/robots/question/hat1.png"
          question={`What do you want to happen in your story?`}
          backButton={
            <BtnMinor btnText="← Back" onClick={previousStepHandler} />
          }
          skipButton={
            <BtnMinor
              btnText="Skip →"
              onClick={dispatchInfoWithType.bind(null, {
                type: "HINT",
                payload: "",
              })}
            />
          }
          freeformField={
            <FreeformEntry
              initialValue={buildState["HINT"]}
              freeFormSubmitPayloadType="HINT"
              onFreeformSubmit={dispatchInfoWithType}
            />
          }
        />
        <BuilderCard2
          // CSS transition
          key={-1}
          isShowing={step === -1}
          stepDirection={stepDirection}
          timeout={animationTime}
          // Content
          imgSrc="/img/robots/question/happy.png"
          question={`What mood would you like your story to have?`}
          backButton={
            <BtnMinor btnText="← Back" onClick={previousStepHandler} />
          }
          skipButton={
            <BtnMinor
              btnText="Skip →"
              onClick={dispatchInfoWithType.bind(null, {
                type: "MOOD",
                payload: "",
              })}
            />
          }
          buttonChoices={moodButtons}
        />
        {/* <BuilderCard2
        // CSS transition
        isShowing={step === 6}
        stepDirection={stepDirection}
        timeout={animationTime}
        // Content
        question={`Would you like to include rhyme in your story?`}
        backButton={<BtnMinor btnText="← Back" onClick={previousStepHandler} />}
        skipButton={
          <BtnMinor
            btnText="Skip →"
            onClick={dispatchInfoWithType.bind(null, {
              type: "RHYME",
              payload: "",
            })}
          />
        }
        buttonChoices={[
          <BtnChoice
            btnText="No"
            onClick={dispatchInfo.bind(null, {
              type: "RHYME",
              payload: "No",
            })}
            isSelected={buildState["RHYME"] === "No"}
          />,
          <BtnChoice
            btnText="Yes"
            onClick={dispatchInfo.bind(null, {
              type: "RHYME",
              payload: "Yes",
            })}
            isSelected={buildState["RHYME"] === "Yes"}
          />,
        ]}
      /> */}
        <BuilderCard2
          // CSS transition
          key={5}
          isShowing={step === 5}
          stepDirection={stepDirection}
          timeout={animationTime}
          // Content
          question={`What kind of pictures would you like?`}
          imgSrc="/img/robots/question/mirror.png"
          backButton={
            <BtnMinor btnText="← Back" onClick={previousStepHandler} />
          }
          skipButton={
            <BtnMinor
              btnText="Skip →"
              onClick={dispatchInfoWithType.bind(null, {
                type: "ILLUSTRATION_STYLE",
                payload: "",
              })}
            />
          }
          buttonChoices={[
            <BtnChoice
              btnText="Magical"
              onClick={dispatchInfo.bind(null, {
                type: "ILLUSTRATION_STYLE",
                payload: "Magical",
              })}
              isSelected={buildState["ILLUSTRATION_STYLE"] === "Magical"}
              imgSrc="Magical"
              largeImage="True"
            />,
            <BtnChoice
              btnText="Comic"
              onClick={dispatchInfo.bind(null, {
                type: "ILLUSTRATION_STYLE",
                payload: "Comic",
              })}
              isSelected={buildState["ILLUSTRATION_STYLE"] === "Comic"}
              imgSrc="Comic"
              largeImage="True"
            />,
            <BtnChoice
              btnText="Glass"
              imgSrc="Glass"
              onClick={dispatchInfo.bind(null, {
                type: "ILLUSTRATION_STYLE",
                payload: "Glass",
              })}
              isSelected={buildState["ILLUSTRATION_STYLE"] === "Glass"}
              largeImage="True"
            />,
            // <BtnChoice
            //   btnText="Pen"
            //   imgSrc="Pen"
            //   onClick={dispatchInfo.bind(null, {
            //     type: "ILLUSTRATION_STYLE",
            //     payload: "Pen",
            //   })}
            //   isSelected={buildState["ILLUSTRATION_STYLE"] === "Pen"}
            //   largeImage="True"
            // />,
            <BtnChoice
              btnText="Toy"
              imgSrc="Toy"
              onClick={dispatchInfo.bind(null, {
                type: "ILLUSTRATION_STYLE",
                payload: "Toy",
              })}
              isSelected={buildState["ILLUSTRATION_STYLE"] === "Toy"}
              largeImage="True"
            />,
            // <BtnChoice
            //   btnText="Paper"
            //   imgSrc="Paper"
            //   onClick={dispatchInfo.bind(null, {
            //     type: "ILLUSTRATION_STYLE",
            //     payload: "Paper",
            //   })}
            //   isSelected={buildState["ILLUSTRATION_STYLE"] === "Paper"}
            //   largeImage="True"
            // />,
            <BtnChoice
              btnText="Paper"
              imgSrc="Paper"
              onClick={dispatchInfo.bind(null, {
                type: "ILLUSTRATION_STYLE",
                payload: "Paper",
              })}
              isSelected={buildState["ILLUSTRATION_STYLE"] === "Paper"}
              largeImage="True"
            />,
          ]}
        />
        <BuilderCard2
          // CSS transition
          key={6}
          isShowing={step === 6}
          stepDirection={stepDirection}
          timeout={animationTime}
          // Content
          imgSrc="/img/robots/question/hat2.png"
          question={`Which Whizz Bang level?`}
          backButton={
            <BtnMinor btnText="← Back" onClick={previousStepHandler} />
          }
          skipButton={
            <BtnMinor
              btnText="Skip →"
              onClick={dispatchInfoWithType.bind(null, {
                type: "DIFFICULTY",
                payload: "",
              })}
            />
          }
          buttonChoices={[
            <BtnChoice
              btnText="1. Starter"
              onClick={dispatchInfo.bind(null, {
                type: "DIFFICULTY",
                payload: "1",
              })}
              isSelected={buildState["DIFFICULTY"] === "1"}
              imgSrc={"1"}
              largeImage="True"
              textBelow="Example text"
              textBelowOnClick={`${buildState["NAME"]} sees Sam. Sam is fun!`}
            />,
            <BtnChoice
              btnText="2. Growing"
              onClick={dispatchInfo.bind(null, {
                type: "DIFFICULTY",
                payload: "2",
              })}
              isSelected={buildState["DIFFICULTY"] === "2"}
              imgSrc={"2"}
              largeImage="True"
              textBelow="Example text"
              textBelowOnClick={`${buildState["NAME"]} likes Sam. Sam is a very fun kid!`}
            />,
            <BtnChoice
              btnText="3. Skilled"
              onClick={dispatchInfo.bind(null, {
                type: "DIFFICULTY",
                payload: "3",
              })}
              isSelected={buildState["DIFFICULTY"] === "3"}
              imgSrc={"3"}
              largeImage="True"
              textBelow="Example text"
              textBelowOnClick={`${buildState["NAME"]} loves Sammy. They have lots of fun together!`}
            />,
            <BtnChoice
              btnText="4. Expert"
              onClick={dispatchInfo.bind(null, {
                type: "DIFFICULTY",
                payload: "4",
              })}
              isSelected={buildState["DIFFICULTY"] === "4"}
              imgSrc={"4"}
              largeImage="True"
              textBelow="Example text"
              textBelowOnClick={`${buildState["NAME"]} thinks Charlie is great. They always have exciting times together!`}
            />,
            <BtnChoice
              btnText="5. Ninja"
              onClick={dispatchInfo.bind(null, {
                type: "DIFFICULTY",
                payload: "5",
              })}
              isSelected={buildState["DIFFICULTY"] === "5"}
              imgSrc={"5"}
              largeImage="True"
              textBelow="Example text"
              textBelowOnClick={`${buildState["NAME"]} thinks Charlie is the best friend anyone could have. Nobody else goes on such intriguing adventures!`}
            />,
          ]}
        />
        <BuilderCard2
          key={STEP_CREATE_STORY}
          isShowing={step === STEP_CREATE_STORY}
          stepDirection={stepDirection}
          timeout={animationTime}
          question={`Right, we're all set!`}
          imgSrc="/img/robots/question/heart.png"
          backButton={
            <BtnMinor btnText="← Back" onClick={previousStepHandler} />
          }
          buttonChoices={[
            <BtnChoice
              btnText="Make my story!"
              onClick={makeStoryHandler}
              imgSrc={"Robot"}
              key={1}
            />,
          ]}
        />
        <FullScreenCard
          isShowing={step === 99}
          stepDirection={stepDirection}
          timeout={500}
        >
          <CreatingStoryPlaceholder story={builtStory} />
        </FullScreenCard>
        <BuilderCard2
          key={100}
          isShowing={step === 100}
          stepDirection={stepDirection}
          timeout={animationTime}
          // imgSrc="/img/robots/question/happy.png"
          question={`"${builtStory.title}" is ready!`}
          imgSrc={builtStory.coverImage}
        >
          {/* <StoryIsReady story={builtStory}/> */}
          {/* HEREIAM - make this into a nice component, including author image */}
          <BtnJaunty
            text="Read my story!"
            imgSrc="/img/art/elements/arrowForward.png"
            onClick={() => {
              navigateToStory(builtStory.id);
            }}
            colour="purple"
          />
          {/* <button
          onClick={() => {
            navigateToStory(builtStory.id);
          }}
        >
          Read my story!
        </button> */}
        </BuilderCard2>
        {/* <button
        onClick={() => {
          console.log(buildState);
        }}
      >
        Show build state
      </button> */}
        {/* <button
        onClick={() => {
          testPoints();
        }}
      >
        Increment points!
      </button> */}
      </div>
    </>
  );
}

export default Builder;
