import axios from "axios";
import { useRouteLoaderData, Link } from "react-router-dom";
import PageContent from "../components/layout/PageContent";

function EditProfilePage() {
  // Get loaded user
  const user = useRouteLoaderData("profile");
  if (!user) {
    return <p>Could not get user</p>;
  }

  return (
    <>
      <Link to="..">⬅️ Back to profile</Link>
      <PageContent title="✏️ Edit your profile here!">
        <h1>{user.name}</h1>
        <p>{user.email}</p>
      </PageContent>
    </>
  );
}

export default EditProfilePage;
