import classes from "./TitleBar.module.css";

function TitleBar({ text }) {
  return (
    <div className={classes.title}>
      <h2>{text}</h2>
    </div>
  );
}

export default TitleBar;
