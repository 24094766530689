import React from "react";
import axios from "axios";

import ScrollToTop from "./components/util/ScrollToTop";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";
import FrontPage from "./pages/FrontPage";
import HomePage from "./pages/HomePage";
import LoginPage, { action as loginAction } from "./pages/auth/LoginPage";
import MagicLinkPage from "./pages/MagicLinkPage";
import FavouritesPage from "./pages/FavouritesPage";
import FriendsPage from "./pages/FriendsPage";
import { action as inviteFriendAction } from "./pages/FriendsPage";
import ProfilePage from "./pages/ProfilePage";
import EditProfilePage from "./pages/EditProfilePage";
import StoryHomePage from "./pages/StoryHomePage";
import ExitPage from "./pages/ExitPage";
import TestPage from "./pages/TestPage";
import CreditPage from "./pages/credit/CreditPage";

// Context
import { LoadedStoriesContextProvider } from "./store/LoadedStoriesContext";

// Library
import LibraryRoot from "./pages/library/LibraryRoot";
import PublicStoriesPage from "./pages/library/PublicStoriesPage";
import CreatedStoriesPage from "./pages/library/CreatedStoriesPage";
import SharedStoriesPage from "./pages/library/SharedStoriesPage";
import LibraryPage from "./pages/LibraryPage";

// Factory
import FactoryRoot from "./pages/factory/FactoryRoot";
import MagicWriterPage from "./pages/factory/MagicWriterPage";
import ManualWriterPage from "./pages/factory/ManualWriterPage";
import FactoryPage from "./pages/factory/FactoryPage";

// Read a story
import StoryRoot from "./pages/story/StoryRoot";
import StoryReader from "./components/story/StoryReader";
import StorySetup from "./components/story/StorySetup";
import StoryShare from "./components/story/StoryShare";

// Loaders
import { loader as publicStoriesLoader } from "./pages/library/PublicStoriesPage";
import { loader as createdStoriesLoader } from "./pages/library/CreatedStoriesPage";
import { loader as sharedStoriesLoader } from "./pages/library/SharedStoriesPage";
import { loader as singleStoryLoader } from "./pages/StoryHomePage";
import { loader as favouriteStoriesLoader } from "./pages/FavouritesPage";
import { loader as profileLoader } from "./pages/ProfilePage";
import { loader as friendsLoader } from "./pages/FriendsPage";
import { loader as magicLinkLoader } from "./pages/MagicLinkPage";
import { tokenLoader } from "./util/auth";
import { isLoggedInLoader } from "./util/auth";

// Actions
import { action as logoutAction } from "./pages/auth/LogoutAction";

// Create router with main pages
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <RootLayout />
      </>
    ),
    errorElement: <ErrorPage />,
    loader: tokenLoader,
    id: "root",
    children: [
      { index: true, element: <FrontPage /> },
      {
        path: "/testing",
        element: <TestPage />,
      },
      {
        path: "/home",
        element: <HomePage />,
      },
      {
        path: "/bye",
        element: <ExitPage />,
      },
      {
        path: "/credit",
        element: <CreditPage />,
      },
      {
        path: "/login",
        id: "login",
        element: <LoginPage />,
        action: loginAction,
      },
      {
        path: "/logout",
        action: logoutAction,
      },
      {
        path: "/welcome/:magicLink",
        element: <MagicLinkPage />,
        loader: magicLinkLoader,
      },
      {
        path: "/factory",
        element: <MagicWriterPage />,
        // loader: isLoggedInLoader,
      },
      // When it was only logged in users who could write, and we had more options
      // {
      //   path: "/factory",
      //   // element: <FactoryRoot />,
      //   loader: isLoggedInLoader,
      //   children: [
      //     {
      //       path: "",
      //       element: <FactoryPage />,
      //     },
      //     {
      //       path: "magic",
      //       element: <MagicWriterPage />,
      //     },
      //     {
      //       path: "write",
      //       element: <ManualWriterPage />,
      //     },
      //   ],
      // },
      {
        path: "/library",
        element: <FactoryRoot />,
        // element: <LibraryRoot />,
        id: "library",
        // loader: publicStoriesLoader,
        children: [
          {
            path: "",
            element: <LibraryPage />,
          },
          {
            path: "public",
            element: <PublicStoriesPage />,
            loader: publicStoriesLoader,
          },
          {
            path: "mine",
            element: <CreatedStoriesPage />,
            loader: createdStoriesLoader,
          },
          {
            path: "shared",
            element: <SharedStoriesPage />,
            loader: sharedStoriesLoader,
          },
        ],
      },
      {
        path: "/favourites",
        element: <FavouritesPage />,
        loader: favouriteStoriesLoader,
      },
      {
        path: "/friends",
        element: <FriendsPage />,
        action: inviteFriendAction,
        loader: friendsLoader,
      },
      {
        path: "/profile",
        id: "profile", // for useRouteLoaderData
        loader: profileLoader,
        // element: <ProfilePage />,
        children: [
          {
            index: true,
            element: <ProfilePage />,
          },
          {
            path: "edit",
            element: <EditProfilePage />,
          },
        ],
      },
      {
        path: "/story/:id",
        element: (
          <LoadedStoriesContextProvider>
            <StoryRoot />
          </LoadedStoriesContextProvider>
        ),
        loader: singleStoryLoader,
        children: [
          {
            index: true,
            element: <StoryHomePage />,
          },
          {
            path: "setup",
            element: <StorySetup />,
          },
          {
            path: "read",
            element: <StoryReader />,
          },
          {
            path: "share",
            element: <StoryShare />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <>
      <div className="largeScreensOnly">
        <div className="largeScreensOnly__container">
          <h2 style={{ fontSize: "4rem" }}>Please use a larger screen!</h2>
          <div className="largeScreensOnly__content">
            <div>
              <p>
                For the time being, Whizz Bang Story Maker has been made for
                larger screens. If you are on a tablet, you can try rotating
                your device to landscape. We will be up and running on mobile
                soon!
              </p>
            </div>
            <img className="width100px" src="/img/art/robot-head.png" />
          </div>
        </div>
      </div>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
