import BtnJaunty from "../ui/BtnJaunty";
import Modal from "../ui/Modal2";
import classes from "./Nux.module.css";

const Nux = function ({
  title,
  text,
  onClose,
  isShowing,
  greenButtonText = "OK, got it!",
}) {
  return (
    <Modal onClose={onClose} isShowing={isShowing}>
      <div className={classes.nuxContent}>
        <h1>{title}</h1>
        {/* <p>Image of helpers goes here</p> */}
        <p>{text}</p>
        <BtnJaunty
          text={greenButtonText}
          imgSrc="/img/art/elements/greenTick.png"
          //   onClick={props.onRestart}
          onClick={onClose}
          colour="green"
        />
      </div>
    </Modal>
  );
};

export default Nux;
