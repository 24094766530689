import { useState, useEffect, useContext } from "react";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import StoryPage from "./StoryPage";
// import ProgressBar from "../ui/ProgressBar";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ReadsContext from "../../store/ReadsContext";
import StoryLives from "./StoryLives";
import GameOverModal from "./GameOverModal";
import StoryCompletedCard from "./StoryCompletedCard";
import FullScreenCard from "../ui/FullScreenCard";
import GameOver from "./GameOver";
import Nux from "../ui/Nux";
import { useSpeechSynthesis } from "react-speech-kit";
import classes from "./StoryReader.module.css";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import { serverHost } from "../../util/usefulServerCalls";
import { getAuthToken } from "../../util/auth";
import { GrAssistListening } from "react-icons/gr";
import BtnJaunty from "../ui/BtnJaunty";
import ShareThisStoryModal from "../shares/ShareThisStoryModal";
import { AudioContext } from "../../store/AudioContext";

function StoryReader() {
  // This is from outlet on story root, where story is loaded
  const [
    storyContent,
    setStoryContent,
    sharesFromMe,
    setSharesFromMe,
    sharesWithMe,
    setSharesWithMe,
    storyParameters,
    setStoryParameters,
  ] = useOutletContext();

  console.log("Outlet contexts", storyContent.author); //HEREIAM NEEDS A FIX

  // If story is not loaded, redirect to story root. Can't use Navigate for this as needs to be in useEffect which isn't working
  // how I expect it to.
  let params = useParams();
  let storyIdInURL = params.id;
  if (!storyContent.id) {
    window.location.replace(`/story/${storyIdInURL}`);
  }

  // Story and images
  const story = storyContent.text.medium;
  const images = storyContent.images;

  // Navigate
  const navigate = useNavigate();

  // State for if story has been completed
  const [storyCompleted, setStoryCompleted] = useState(false);

  // State for showing the intro nux
  const [showIntroNux, setShowIntroNux] = useState(true);

  // State for showing the 'share' modal
  const [showShareThisStoryModal, setShowShareThisStoryModal] = useState(false);

  // Set state for story position
  const [storyPosition, setStoryPosition] = useState(undefined);
  const [progressBarPosition, setProgressBarPosition] = useState(0);
  // Progress bar
  const progress100 = (progressBarPosition / (story.length - 1)) * 100;

  // Set state for story position, first page must animate in
  useEffect(() => {
    setStoryPosition(0);
  }, []);

  // Set state for lives
  const [numberOfLives, setNumberOfLives] = useState(
    storyParameters.numberOfLives
  );

  // Set state for game over
  const [gameIsOver, setGameIsOver] = useState(false);

  // Set state for arrow visibility
  const [rightArrowIsVisible, setRightArrowIsVisible] = useState(true);
  const [leftArrowIsVisible, setLeftArrowIsVisible] = useState(true);

  // Set state for whether sight words are marked in red
  const [sightWordsMarked, setSightWordsMarked] = useState(false);

  // If story is completed, contact server to increment read count for story
  const readsCtx = useContext(ReadsContext);
  function incrementReadCountInContext(id) {
    readsCtx.addRead(id);
  }
  useEffect(() => {
    if (storyCompleted) {
      axios
        .post(
          `${serverHost()}/stories/${storyContent.id}/read`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getAuthToken(),
            },
          }
        )
        .then((res) => {
          console.log("Story read count incremented");
          incrementReadCountInContext(storyContent.id);
        })
        .catch((err) => {
          console.log("Error incrementing story read count", err);
        });
    }
  }, [storyCompleted]);

  // Set story complete if progress reaches 100%
  useEffect(() => {
    if (progress100 > 100) {
      setStoryCompleted(true);
    }
  }, [progress100]);

  // Speech synthesis
  const { speak, voices, speaking } = useSpeechSynthesis();

  // Voice preferences in order
  const voicePreferences = [
    "Google UK English Female",
    "Karen",
    "Victoria",
    "Samantha",
  ];

  // Find first voice available
  const voiceToUse = voices.filter((voice) =>
    voicePreferences.includes(voice.name)
  )[0];

  // Set state for step direction
  const [stepDirection, setStepDirection] = useState("forwards");

  // Click the ear to hear word and lose a life
  const handleEarClick = function (word) {
    setNumberOfLives((lives) => lives - 1);
    sayWord(word);
  };

  const sayWord = function (word) {
    const rate = 1;
    // const pitch = 2;
    speak({
      text: word,
      voice: voiceToUse,
      rate,
      pitch: 1.1,
    });
  };

  // Game over if run out of lives
  useEffect(() => {
    if (numberOfLives <= 0) {
      setTimeout(() => {
        setGameIsOver(true);
      }, 1000);
    }
  }, [numberOfLives]);

  // Animation time
  const animationTime = 600;

  // Time until the 'next' arrow appears
  const timeUntilNextStepAvailable = 3000;

  // Next step
  const nextStepHandler = function () {
    // Delay before moving to next step, and set to undefined in between
    // so that the next step doesn't start before the animation has finished
    let nextStep = storyPosition + 1;
    setStepDirection("forwards");
    setStoryPosition(undefined);
    setRightArrowIsVisible(false);
    setTimeout(() => {
      setStoryPosition(nextStep);
      setProgressBarPosition((progress) => progress + 1);
    }, animationTime);
    setTimeout(() => {
      setRightArrowIsVisible(true);
    }, animationTime + timeUntilNextStepAvailable);
  };

  // Previous step
  const previousStepHandler = function () {
    // Delay before moving to next step, and set to undefined in between
    // so that the next step doesn't start before the animation has finished
    let nextStep = storyPosition - 1;
    setStepDirection("backwards");
    setStoryPosition(undefined);
    setTimeout(() => {
      setStoryPosition(nextStep);
      setProgressBarPosition((progress) => progress - 1);
    }, animationTime);
  };

  // Story content
  const pageContent = story.map((paragraph, index) => {
    return (
      // index === storyPosition && (
      <CSSTransition
        in={index === storyPosition}
        timeout={600}
        classNames={
          stepDirection === "forwards"
            ? "TransitionSideFlyInForwards"
            : "TransitionSideFlyInBackwards"
        }
        mountOnEnter
        unmountOnExit
      >
        <div className="flex-row">
          <StoryPage
            key={index}
            text={paragraph}
            image={images[index]}
            onEarClick={handleEarClick}
            isSpeaking={speaking}
            setRightArrowIsVisible={setRightArrowIsVisible}
            setLeftArrowIsVisible={setLeftArrowIsVisible}
            sightWordsMarked={sightWordsMarked}
          >
            {/* image={images[index]} */}
            {paragraph}
          </StoryPage>
        </div>
      </CSSTransition>
      // )
    );
  });

  // // PUT INTO ARROW NEXT STEP FUNCTION FOR NOW
  // // Finishing music
  // function playMusic(url) {
  //   var audio = new Audio(url);
  //   audio.play();
  // }

  // // Music on finish
  // useEffect(() => {
  //   if (progress100 > 100) {
  //     const audioChoices = ["ska.mp3", "nyan.mp3", "boogie.mp3", "battare.mp3"];
  //     const randomAudio =
  //       audioChoices[Math.floor(Math.random() * audioChoices.length)];
  //     playMusic("/mp3/celebration/" + randomAudio);
  //   }
  // }, [progress100]);

  // Handle restart after game over
  function gameOverRestartHandler() {
    setGameIsOver(false);
    navigate(`/story/${storyIdInURL}`);
  }

  console.log("sharesFromMe", sharesFromMe);

  // Share story button (except for if it's not sharable)
  const shareButton = (
    <BtnJaunty
      text="Share this story!"
      imgSrc="/img/art/send.png"
      colour="green"
      size="tiny"
      onClick={() => {
        setShowShareThisStoryModal(true);
      }}
    />
  );

  // Audio
  const { setAudio, setIsPlaying } = useContext(AudioContext);

  const playAudio = (audioFile) => {
    const audio = new Audio(audioFile);
    setAudio(audio);
    audio.play();
    setIsPlaying(true);
  };

  return (
    <>
      <div className={classes.BookPagesContainer}>
        <div className={classes.fixedToTop}>
          {progress100 <= 100 && (
            <>
              {/* <ProgressBar progress={progress100} /> */}
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  variant="determinate"
                  value={progress100}
                  thickness={100}
                />
              </Box>
            </>
          )}

          {/* Various content on top of screen whilst reading */}
          {progress100 <= 100 && (
            <div className={classes.topBarColumns}>
              <StoryLives
                livesEmoji={storyParameters.livesEmoji}
                numberOfLives={numberOfLives}
              />

              {/* Share button */}
              {shareButton}

              {/* Red words / sight words toggle */}
              <BtnJaunty
                onClick={() => {
                  setSightWordsMarked((marked) => !marked);
                }}
                text={sightWordsMarked ? "Hide red words" : "Show red words"}
                imgSrc={
                  sightWordsMarked
                    ? "/img/art/elements/greysplat.png"
                    : "/img/art/elements/redsplat.png"
                }
                colour="white"
                size="tiny"
              />
            </div>
          )}
        </div>

        <div className={classes.restOfPage}>
          {/* Game over modal, appears when lives run out */}
          {
            <GameOver
              onRestart={gameOverRestartHandler}
              isShowing={gameIsOver}
            />
          }

          {/* Intro nux */}
          {
            <Nux
              title="Welcome to the story!"
              text={
                <>
                  <br />
                  <p>{`If you can't read a word, tap on it for help!`}</p>
                  <br />
                  <p>
                    {`You can then press the`}{" "}
                    <span>
                      <GrAssistListening className={classes.earIcon} />
                    </span>{" "}
                    {`logo to hear the word spoken. But careful - this will use up one of your ${storyParameters.livesEmoji} helpers!`}
                  </p>
                  <br />
                </>
              }
              isShowing={showIntroNux}
              onClose={() => setShowIntroNux(false)}
            />
          }

          {/* Share this story modal */}
          <ShareThisStoryModal
            isShowing={showShareThisStoryModal}
            onClose={() => setShowShareThisStoryModal(false)}
            alreadyShared={sharesFromMe}
          />

          {/* Main page content, i.e. the pages of the book */}
          {pageContent}

          {/* Finished content, i.e. the end of the book */}
          {/* {progress100 > 100 && finishedContent} */}
          <FullScreenCard isShowing={progress100 > 100} timeout={500}>
            {/* {finishedContent} */}
            <StoryCompletedCard
              storyId={storyIdInURL}
              showShareModal={setShowShareThisStoryModal}
            />
          </FullScreenCard>
        </div>

        <div className={classes.buttonControls}>
          {/* <button
          className={progress100 === 0 ? "invisible" : undefined}
          onClick={previousStepHandler}
        >
          ← Back
        </button>
        <button
          className={progress100 > 100 ? "invisible" : undefined}
          onClick={nextStepHandler}
        >
          Next →
        </button> */}
          {progress100 > 0 && progress100 <= 100 && (
            <CSSTransition
              in={leftArrowIsVisible}
              timeout={1000}
              classNames="TransitionSoftFade"
              mountOnEnter
              unmountOnExit
            >
              <img
                src="/img/art/elements/arrowBackward.png"
                alt="Back"
                onClick={previousStepHandler}
                className={classes.leftArrow}
              />
            </CSSTransition>
          )}
          {progress100 <= 100 && (
            <CSSTransition
              in={rightArrowIsVisible}
              timeout={1000}
              classNames="TransitionSoftFade"
              mountOnEnter
              unmountOnExit
            >
              <img
                src="/img/art/elements/arrowForward.png"
                alt="Forward"
                onClick={() => {
                  nextStepHandler();
                  if (progress100 >= 100) {
                    console.log("Progress is 100 or more");
                    const audioChoices = [
                      "ska.mp3",
                      "nyan.mp3",
                      "boogie.mp3",
                      "battare.mp3",
                    ];
                    const randomAudio =
                      audioChoices[
                        Math.floor(Math.random() * audioChoices.length)
                      ];
                    // var audio = new Audio("/mp3/celebration/" + randomAudio);
                    // audio.play();
                    playAudio("/mp3/celebration/" + randomAudio);
                  }
                }}
                className={classes.rightArrow}
              />
            </CSSTransition>
          )}
        </div>
      </div>
    </>
  );
}

export default StoryReader;
