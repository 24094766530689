import axios from "axios";
import { getAuthToken } from "./auth";
import { json } from "react-router-dom";
import { serverHost } from "./usefulServerCalls";

export async function loadFromLibrary(mode) {
  console.log("loadFromLibrary with mode: ", mode);
  // Get auth token
  const token = getAuthToken();

  // Load data with axios
  const response = await axios.get(`${serverHost()}/stories?privacy=${mode}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.data.status !== "success") {
    throw json(
      { message: "Could not get stories." },
      {
        status: 500,
      }
    );
  }
  const stories = response.data.data;
  console.log("[loadFromLibrary] Returning...");
  console.log(stories);
  return stories;
}
