import {
  Outlet,
  useNavigation,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";
import { useEffect } from "react";

import MainNavigation from "../components/layout/MainNavigation";
import SecondaryNavigation from "../components/layout/SecondaryNavigation";
import ModalOverlay from "../components/layout/ModalOverlay";
import BottomBanner from "../components/layout/BottomBanner";
import StopButton from "../components/layout/StopButton";

import { getTokenDuration } from "../util/auth";

import classes from "./RootLayout.module.css";

function RootLayout() {
  const navigation = useNavigation();

  // Log out user if token is expired
  const token = useRouteLoaderData("root"); // Could just use useRouteData() here
  const submit = useSubmit(); // So that we can submit logout form
  useEffect(() => {
    // If no token, do nothing
    if (!token) {
      return;
    }
    if (token === "EXPIRED") {
      submit(null, { action: "/logout", method: "post" });
      return;
    }

    // Log user out when token expires, set this timer when we load the page (in case user is on the page for a while without reloading)
    const tokenDuration = getTokenDuration();
    // console.log("Token duration: ", tokenDuration, "ms");
    setTimeout(() => {
      submit(null, { action: "/logout", method: "post" });
    }, tokenDuration);
  }, [token, submit]);

  // Hack, we need to adjust the screen height to account for the navigation bar being inside the story root. Yuk.
  // Check if '/story' is in URL
  const isStoryRoot =
    window.location.pathname.includes("/story") ||
    window.location.pathname.includes("/profile");
  const screenHeightClass = isStoryRoot
    ? classes.screenHeightAdjusted
    : classes.screenHeight;

  return (
    <>
      {navigation.state === "loading" && <ModalOverlay />}

      <MainNavigation />
      <SecondaryNavigation />
      {/* <main className={classes.mainContainer}> */}
      <main className={screenHeightClass}>
        <Outlet />
        <StopButton />
      </main>
      {/* Show bottom banner only if needed */}
      {/* <BottomBanner /> */}
      {/* </main> */}
    </>
  );
}

export default RootLayout;
