import classes from "./MainNavCounterIcon.module.css";
import { Link } from "react-router-dom";

function MainNavCounterIcon({ to, imgSrc, imgAlt, counter, newIcon }) {
  return (
    <>
      <Link to={to}>
        <div className={classes.navIconContainer}>
          <div className={classes.navIcon}>
            <img src={imgSrc} alt={imgAlt} />
            <div className={classes.counter}>
              <p>{counter}</p>
            </div>
          </div>
          {newIcon && <p className={classes.AdditionalCounter}>New</p>}
        </div>
      </Link>
    </>
  );
}

export default MainNavCounterIcon;
