import { useRef, useState } from "react";
import BtnJaunty from "../ui/BtnJaunty";
import TitleBar from "../ui/TitleBar";
import classes from "./FindNewFriendsPanel.module.css";
import { getAuthToken } from "../../util/auth";
import { json } from "react-router-dom";
import { serverHost } from "../../util/usefulServerCalls";
import axios from "axios";

function FindNewFriendsPanel({ storyIdToShare }) {
  //////////////////////
  // SETUP
  //////////////////////
  // Create email ref
  const emailRef = useRef();
  const messageRef = useRef();

  const [inviteSent, setInviteSent] = useState(false);
  const [isSendingInvite, setIsSendingInvite] = useState(false);

  //////////////////////
  // HANDLERS
  //////////////////////
  async function sendInviteHandler() {
    setIsSendingInvite(true);
    // Get email
    const email = emailRef.current.value;
    const message = messageRef.current.value;

    try {
      const token = await getAuthToken();
      // Log in using fetch (since if error we want to return the response)
      // Axios will just throw an error which means we don't get the response
      let response = await fetch(`${serverHost()}/friendships`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          recipientEmail: email,
          requestMessage: message,
        }),
      });
      response = await response.json();

      // Return so we are not redirected, and render errors on the form
      if (response.status !== "success") {
        alert(
          "Something went wrong. We couldn't send this friend request. Please refresh the page."
        );
        throw json(
          { message: "Could not send a friend request." },
          { status: 500 }
        );
      }

      // If success, we now want to share the story with this friend if we are on the story page
      if (!storyIdToShare) {
        setIsSendingInvite(false);
        setInviteSent(true);
        return response;
      }

      const response2 = await axios.post(
        `${serverHost()}/stories/${storyIdToShare}/shares`,
        {
          recipientId: null,
          recipientEmail: email,
          sharerMessage: "",
        },
        {
          headers: { Authorization: `Bearer ${getAuthToken()}` },
        }
      );

      // Error case
      if (response2.data.status !== "success") {
        console.log("Something went wrong...");
        throw json(
          { message: "Could not share story with friend." },
          { status: 500 }
        );
      }
      // let response2 = await fetch(`${serverHost()}/shares`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      //   body: JSON.stringify({
      //     storyId: storyIdToShare,
      //     recipientEmail: email,
      //     sharerMessage: message,
      //   }),
      // });
      // response2 = await response2.json();

      // // Return so we are not redirected, and render errors on the form
      // if (response2.status !== "success") {
      //   alert(
      //     "Something went wrong. We couldn't share this story with your friend. Please refresh the page."
      //   );
      //   throw json(
      //     { message: "Could not share this story with your friend." },
      //     { status: 500 }
      //   );
      // }

      // Show success message
      setIsSendingInvite(false);
      setInviteSent(true);
      return response;
    } catch (error) {
      // If we throw a 500, the message will be shown on error page
      throw json(
        { message: "Could not send a friend request." },
        { status: 500 }
      );
    }
  }

  function newInviteHandler() {
    setInviteSent(false);
    setIsSendingInvite(false);
    messageRef.current.value = "";
    emailRef.current.value = "";
  }

  return (
    // <div>
    <div className={classes.findNewFriendsPanel}>
      {/* <TitleBar text="Find new friends" /> */}
      {/* Title now elsewhere */}
      <div className="form__field">
        <label htmlFor="email" className="form__primaryLabel">
          Email
        </label>
        <label htmlFor="email" className="form__secondaryLabel">
          Your friend's email, or the email of a grown-up who can help them.
        </label>
        <input
          className="form__cleanInput"
          type="email"
          id="email"
          name="email"
          ref={emailRef}
        />
      </div>
      <div className="form__field">
        <label htmlFor="message" className="form__primaryLabel">
          Message
        </label>
        <label htmlFor="message" className="form__secondaryLabel">
          We will send this message to your friend.
        </label>
        <input
          className="form__cleanInput"
          type="message"
          id="message"
          name="message"
          ref={messageRef}
        />
      </div>
      <div className="form__buttonRow">
        <BtnJaunty
          text={
            isSendingInvite
              ? "Sending..."
              : inviteSent
              ? "Sent!"
              : storyIdToShare
              ? "Send invite and share story!"
              : "Send invite!"
          }
          colour="purple"
          onClick={sendInviteHandler}
          disabled={isSendingInvite || inviteSent}
          imgSrc={
            isSendingInvite ? "" : inviteSent ? "" : "/img/art/envelope.png"
          }

          // size="medium"
        />
        {inviteSent && (
          <BtnJaunty
            text="Start a new invite"
            colour="green"
            onClick={newInviteHandler}
            size="medium"
          />
        )}
      </div>
    </div>
    // </div>
  );
}

export default FindNewFriendsPanel;
