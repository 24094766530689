import { NavLink } from "react-router-dom";
import classes from "./SecondaryNavigation.module.css";

function SecondaryNavigation() {
  function addressContains(str) {
    return window.location.href.includes(str);
  }

  const banners = [
    {
      address: "/library/shared",
      imgSrc: "/img/art/book.png",
      imageAlt: "Library",
      text: "Library > Shared with me",
      link: "/library",
      colour: "#ff8a1c",
    },
    {
      address: "/library/mine",
      imgSrc: "/img/art/book.png",
      imageAlt: "Library",
      text: "Library > My stories",
      link: "/library",
      colour: "#ff8a1c",
    },
    {
      address: "/library/public",
      imgSrc: "/img/art/book.png",
      imageAlt: "Library",
      text: "Library > Everyone's stories",
      link: "/library",
      colour: "#ff8a1c",
    },
    {
      address: "/library",
      imgSrc: "/img/art/book.png",
      imageAlt: "Library",
      text: "Library",
      colour: "#ff8a1c",
    },
    {
      address: "/favourites",
      imgSrc: "/img/art/chest.png",
      imageAlt: "Favourites",
      text: "My box of favourites",
      colour: "#56b5f0",
    },
    {
      address: "/factory",
      imgSrc: "/img/art/machine.png",
      imageAlt: "Factory",
      text: "Write a story",
      colour: "#f04941",
    },
    {
      address: "/friends",
      imgSrc: "/img/art/robot-head.png",
      imageAlt: "Friends",
      text: "Friends",
      colour: "#017560",
      textColour: "#fff",
    },
  ];

  let banner = banners.find((banner) => addressContains(banner.address));
  let linkTo = undefined;
  if (banner) {
    if (banner.link) {
      linkTo = banner.link;
    } else {
      linkTo = banner.address;
    }
  }

  let bannerDOM = null;
  if (banner) {
    bannerDOM = (
      <nav className={classes.secondaryNavigation}>
        <div style={{ backgroundColor: banner.colour }}>
          <NavLink to={linkTo}>
            <div className={classes.secondaryNavImgAndText}>
              <img
                className={classes.secondaryNavImg}
                src={banner.imgSrc}
                alt={banner.imageAlt}
              />{" "}
              <h2 className={classes.text} style={{ color: banner.textColour }}>
                {banner.text}
              </h2>
            </div>
          </NavLink>
        </div>
      </nav>
    );
  }

  return bannerDOM;
}

export default SecondaryNavigation;
