import PageContent from "../../components/layout/PageContent";
import LoginForm from "../../components/auth/LoginForm";
import axios from "axios";
import { redirect, json } from "react-router-dom";
import { serverHost } from "../../util/usefulServerCalls";

import classes from "./../FrontPage.module.css";

function LoginPage() {
  return (
    <>
      <PageContent>
        <div className={classes.backgroundImage} />
        <LoginForm />
      </PageContent>
    </>
  );
}

export default LoginPage;

// Action that runs when the form is submitted (via router)
export async function action({ request, params }) {
  // Get the form data
  const data = await request.formData();
  const emailData = {
    email: data.get("email"),
    password: data.get("password"),
  };

  // Remove any existing token
  localStorage.removeItem("token");
  localStorage.removeItem("expiration");

  // Send email address to server
  try {
    // Log in using fetch (since if error we want to return the response)
    // Axios will just throw an error which means we don't get the response
    let response = await fetch(`${serverHost()}/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    });
    response = await response.json();

    // Return so we are not redirected, and render errors on the form
    if (response.status !== "success") {
      return response;
    }

    // Save token and company to local storage
    const token = response.token;
    localStorage.setItem("token", token);
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 24); // TODO: Change this
    localStorage.setItem("expiration", expiration.toISOString());

    // Redirect to validation page
    return redirect("/home");
  } catch (error) {
    // If we throw a 500, the message will be shown on error page
    throw json({ message: "Could not log in." }, { status: 500 });
  }
}
