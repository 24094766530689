// Export a function to go to signup form
// https://chat.openai.com/c/75f08396-14b9-48dd-857e-1b6b76d0685f
import { useNavigate } from "react-router-dom";

export function useCustomNavigate() {
  const navigate = useNavigate();

  const goToHomepageWithState = (stateValue) => {
    navigate(`/?state=${stateValue}`);
  };

  return {
    goToHomepageWithState,
  };
}
