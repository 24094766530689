import React, { useContext, useState, useEffect } from "react";
import FavouritesContext from "../store/FavouritesContext";
import PageContent from "../components/layout/PageContent";
import { getAuthToken } from "../util/auth";
import { json, useLoaderData, Link } from "react-router-dom";
import StoryCard from "../components/ui/StoryCard";
import BtnJaunty from "../components/ui/BtnJaunty";
import TitleBar from "../components/ui/TitleBar";
import { serverHost } from "../util/usefulServerCalls";

function FavouritesPage() {
  // Use favourites context. We want to remove a story from this list if it is removed from favourites
  // on this page
  const { itemIsFavourite, favourites } = useContext(FavouritesContext);

  // Get loaded stories from loader
  const stories = useLoaderData();

  // State to show advert/modal if all stories are removed
  const [showLibraryAdvert, setShowLibraryAdvert] = useState(false);

  // Use effect to show modal if all stories have been removed
  useEffect(() => {
    setTimeout(() => {
      if (favourites.length === 0) {
        // Show modal
        setShowLibraryAdvert(true);
      }
    }, 0);
  }, [favourites]);

  // Show error if there is one (from the loader)
  // if (stories && stories.isError) {
  //   return <p>data.error.message</p>;
  // } else if (stories.length === 0) {
  //   setShowLibraryAdvert(true);
  // }

  // Upsell element
  const noStoriesUpsell = (
    <div className="upsell">
      <TitleBar text="You don't have any ❤️ favourites yet..." />
      <p>
        <BtnJaunty to="/factory" text="Write a story" colour="purple" /> or{" "}
        <BtnJaunty to="/library" text="Visit the library" colour="purple" /> to
        read a story, then add it to your favourites!
      </p>
    </div>
  );

  const moreStoriesUpsell = (
    <div className="upsell">
      <TitleBar text="Want to add more ❤️ favourites?" />
      <p>
        <BtnJaunty to="/factory" text="Write a story" colour="purple" /> or{" "}
        <BtnJaunty to="/library" text="Visit the library" colour="purple" /> to
        add more!
      </p>
    </div>
  );

  return (
    <>
      {showLibraryAdvert && noStoriesUpsell}

      <div className="story-cards-container">
        {stories.map((story) => {
          if (itemIsFavourite(story.storyId.id)) {
            return <StoryCard story={story.storyId} key={story.id} />;
          }
        })}
      </div>

      {!showLibraryAdvert && moreStoriesUpsell}
    </>
  );
}

export default FavouritesPage;

export const loader = async () => {
  // Get auth token
  const token = getAuthToken();
  const response = await fetch(`${serverHost()}/favourites`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw json(
      { message: "Could not get favourite stories." },
      {
        status: 500,
      }
    );
  }

  const resData = await response.json();
  console.log(resData.data);
  return resData.data; // This is the story object
};
