import axios from "axios";
import { getAuthToken } from "./auth";
import { json } from "react-router-dom";

export async function commentOnShareOnServer(comment, shareId, actorRole) {
  // Send comment to server, with actor role (recipient or sharer)
  try {
    const response = await axios.post(
      `${serverHost()}/shares/${shareId}/comment?actor=${actorRole}`,
      {
        comment,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      }
    );
    if (response.data.status !== "success") {
      throw json({ message: "Could not comment on share." }, { status: 500 });
    }
    return response.data;
  } catch (e) {
    console.log("Error: ", e);
    throw new Error("Could not comment on share.");
  }
}

export function serverHost() {
  // console.log("We are calling serverHost()");
  // console.log("window.location.href: ", window.location.href);
  // Production
  if (window.location.href.includes("storymaker.world")) {
    return "https://storymaker.world/api/v1";
  }

  // Development
  else if (window.location.href.includes("localhost")) {
    return "http://localhost:3099/api/v1";
  }

  // Staging
  else if (window.location.href.includes("netlify.app")) {
    console.log("We are on the staging version");
    return "https://staging-whizzbang-backend.storymaker.world/api/v1";
  } else {
    console.log("Unknown host.");
    return;
  }
}
