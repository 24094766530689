import classes from "./StoryLives.module.css";
import { GrAssistListening } from "react-icons/gr";

function StoryLives({ livesEmoji, numberOfLives, position }) {
  // Create string of N emojis
  let livesString = "";
  for (let i = 0; i < numberOfLives; i++) {
    livesString += livesEmoji;
  }
  return (
    <>
      <div className={classes.storyLives}>
        <div>
          <span>
            <GrAssistListening className={classes.earIcon} />
          </span>{" "}
          {livesString}
        </div>
      </div>
    </>
  );
}

export default StoryLives;
