import PaperCard from "../../components/ui/PaperCard";
import useWindowDimensions from "../../util/useWindowDimensions";
import classes from "./CreatingStoryPlaceholder.module.css";
import { CSSTransition } from "react-transition-group";
import { useState, useEffect } from "react";

const CreatingStoryPlaceholder = (props) => {
  // const [builtStory, setBuiltStory] = useState(props.story);

  const [step, setStep] = useState(0);
  const [storyTitle, setStoryTitle] = useState(undefined);
  const storyTitleIsReady = !!storyTitle;
  const [giphyChoice, setGiphyChoice] = useState({ link: "", description: "" });
  const [writerRobot, setWriterRobot] = useState("Bluebell");
  const [illustratorRobot, setIllustratorRobot] = useState("Sparky");
  const giphyLinks = [
    { link: "14uXQbPS73Y3qU", description: "a greedy hamster" },
    { link: "33zX3zllJBGY8", description: "a speedy elephant" },
    { link: "2GrNmaGsI2ybu", description: "a funny kitty" },
    { link: "c1c1M1a2yZDd9aVReu", description: "a clever puppy" },
    { link: "lowAFsBujmjQ0MQ55i", description: "a hard-working dog" },
    // { link: "Wq3andvvOQ4uf3yak1", description: "the world's coolest dog" },
    { link: "6GoN2XydMRV65YBdp2", description: "a hard-working beaver" },
    { link: "11BbGyhVmk4iLS", description: "how fast they can type" },
    { link: "UFGj6EYw5JhMQ", description: "a fast-typing monster" },
    { link: "ule4vhcY1xEKQ", description: "some cats helping them type" },
    { link: "y5OffROvBod0s", description: "their secret kitty helper" },
    { link: "3o85xsz5nqVjzphbZC", description: "a story-writing cat" },
    { link: "bYUbS6XYDi3Ze", description: "a hard-working kitty" },
  ];

  const writerRobots = ["Bluebell", "Gray", "Bumble"];
  const illustratorRobots = ["Sparky", "Splatter", "Clanky"];

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment step
      setStep((prevStep) => prevStep + 1); // Adjust speed here, 1 = 1 step per second
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (props.story && props.story.title) {
      setStoryTitle(props.story.title);
    }
  }, [props.story]);

  let randomGiphy;
  let randomWriterRobot;
  let randomIllustratorRobot;
  useEffect(() => {
    randomGiphy = giphyLinks[Math.floor(Math.random() * giphyLinks.length)];
    setGiphyChoice(randomGiphy);

    randomWriterRobot =
      writerRobots[Math.floor(Math.random() * writerRobots.length)];
    setWriterRobot(randomWriterRobot);

    randomIllustratorRobot =
      illustratorRobots[Math.floor(Math.random() * illustratorRobots.length)];
    setIllustratorRobot(randomIllustratorRobot);
  }, []);
  return (
    <div className={classes.sideBySide}>
      <div className={classes.column}>
        <CSSTransition
          in={step >= 1}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={-2}>
            <h2 className={classes.title}>🔨 Hold on tight for 1 minute!</h2>
          </PaperCard>
        </CSSTransition>

        <CSSTransition
          in={step >= 3}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={3}>
            <p className={classes.paragraph}>We are now building your story.</p>
          </PaperCard>
        </CSSTransition>

        <CSSTransition
          in={step >= 6}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={1}>
            <p className={classes.paragraph}>
              We've asked {writerRobot} to help write the story.
            </p>
          </PaperCard>
        </CSSTransition>

        <div className={classes.robotContainer}>
          <CSSTransition
            in={step >= 9}
            timeout={600}
            classNames={"TransitionSideFlyInForwards"}
            mountOnEnter
            unmountOnExit
          >
            <img
              className={classes.robotImage}
              src="/img/robots/question/happy.png"
            />
          </CSSTransition>

          <CSSTransition
            in={step >= 12}
            timeout={600}
            classNames={"TransitionSideFlyInForwards"}
            mountOnEnter
            unmountOnExit
          >
            <img
              className={classes.robotImage}
              src={`/img/robots/helpers/${writerRobot}.png`}
            />
          </CSSTransition>
        </div>

        <CSSTransition
          in={step >= 14}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={-2}>
            <p className={classes.paragraph}>
              They've started writing!
              {/* <br />
            <br />
            They say <span className={classes.highlightedText}>Thank You!</span>
            &nbsp;&nbsp;for your brilliant ideas. */}
            </p>
          </PaperCard>
        </CSSTransition>

        <CSSTransition
          in={step >= 16}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={-3}>
            <p className={classes.paragraph}>
              They want to show you a video of {giphyChoice.description} while
              you wait!
            </p>
          </PaperCard>
        </CSSTransition>
      </div>

      <div className={classes.column}>
        <CSSTransition
          in={step >= 20}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={2}>
            <img
              className={classes.giphyImage}
              src={`https://media.giphy.com/media/${giphyChoice.link}/giphy.gif`}
            />
          </PaperCard>
        </CSSTransition>

        <CSSTransition
          in={step >= 24 && storyTitleIsReady}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={-1}>
            <p className={classes.paragraph}>
              They have decided on the title for your story! It is...
            </p>
          </PaperCard>
        </CSSTransition>

        <CSSTransition
          in={step >= 27 && storyTitleIsReady}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard colour="purple">
            <p className={classes.storyTitle}>{storyTitle}</p>
          </PaperCard>
        </CSSTransition>
      </div>

      <div className={classes.column}>
        <CSSTransition
          in={step >= 33 && storyTitleIsReady}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={-1}>
            <p className={classes.paragraph}>
              An art robot, {illustratorRobot}, has started drawing the
              pictures.
            </p>
          </PaperCard>
        </CSSTransition>
        <CSSTransition
          in={step >= 37 && storyTitleIsReady}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <div className={classes.robotContainer}>
            <img
              className={classes.robotImage}
              src={`/img/robots/helpers/${illustratorRobot}.png`}
            />
          </div>
        </CSSTransition>
        <CSSTransition
          in={step >= 40 && storyTitleIsReady}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={1}>
            <p className={classes.paragraph}>
              She's doing it very carefully. She is colouring in the shapes
              right now!
            </p>
          </PaperCard>
        </CSSTransition>
        <CSSTransition
          in={step >= 44 && storyTitleIsReady}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={3}>
            <p className={classes.paragraph}>Almost done...</p>
          </PaperCard>
        </CSSTransition>
        <CSSTransition
          in={step >= 56 && storyTitleIsReady}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={3}>
            <p className={classes.paragraph}>Just finishing off!</p>
          </PaperCard>
        </CSSTransition>
        <CSSTransition
          in={step >= 100 && storyTitleIsReady}
          timeout={600}
          classNames={"TransitionSideFlyInForwards"}
          mountOnEnter
          unmountOnExit
        >
          <PaperCard rotate={1}>
            <p className={classes.paragraph}>
              Sorry, this is taking a bit longer but we're still going! Nearly
              done...
            </p>
          </PaperCard>
        </CSSTransition>

        {/* Robot gif */}
        {/* <img src="https://media.tenor.com/NI4MdF92b4cAAAAC/robot-bot.gif" /> */}
      </div>
    </div>
  );
};

export default CreatingStoryPlaceholder;
