import { json, useParams, redirect } from "react-router-dom";
import axios from "axios";

import { serverHost } from "../util/usefulServerCalls";

function MagicLinkPage() {
  return (
    <div>
      <h1>Magic Link Page</h1>
    </div>
  );
}

export default MagicLinkPage;

export const loader = async () => {
  // Attempt to load user from server using magic link
  const magicLink = window.location.pathname.split("/")[2];

  const response = await fetch(`${serverHost()}/users/verify/${magicLink}`, {
    method: "GET",
  });
  const data = await response.json();

  ///////// NOT SURE IF THIS SECTION WORKING ///////////
  if (data.error && data.error.statusCode === 410) {
    alert(
      "This link has expired. We have sent a new email - please try that one."
    );
    // Create a page showing the above message TODO
    alert("Need to create a page for this");
  }
  if (data.error && data.error.statusCode === 404) {
    // This may be reached by someone using the magic link email as their regular login route
    // Check if there is already a valid token, if so, just redirect to home and use that token
    // If not, show an error page

    // Check if there is a valid token
    const token = localStorage.getItem("token");
    if (token !== "undefined" && token !== "EXPIRED" && !!token) {
      const expiration = localStorage.getItem("expiration");
      const now = new Date().toISOString();
      if (expiration > now) {
        // If token is valid, redirect to home
        return redirect("/home");
      }
    }

    // If no valid token, go to login page
    return redirect("/login");
  } else if (data.error) {
    throw json({ message: data.message }, { status: 500 });
  }

  ///////// TRY THIS INSTEAD ///////////
  if (data.status === "fail") {
    console.log("Returned a fail status from magic link");
    // Check if there is a valid token
    const token = localStorage.getItem("token");
    if (token !== "undefined" && token !== "EXPIRED" && !!token) {
      console.log("There is already a token");
      const expiration = localStorage.getItem("expiration");
      const now = new Date().toISOString();
      if (expiration > now) {
        // If token is valid, redirect to home
        console.log("Which is still valid, so let's just use it");
        return redirect("/home");
      } else {
        console.log("But it has expired");
      }
    } else {
      console.log("And no valid token so asking for login");
      // If no valid token, go to login page
      localStorage.removeItem("token");
      localStorage.removeItem("expiration");
      return redirect("/login");
    }
  }

  console.log("This is the data containing the token", data);
  // Save token and company to local storage
  const token = data.token;

  if (token) {
    console.log("Attempting to set the token", token);
    localStorage.setItem("token", token);
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 24); // TODO: Change this
    localStorage.setItem("expiration", expiration.toISOString());

    // If success, redirect to home page
    return redirect("/home");
  } else {
    console.log("No token returned... go to login page");
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    return redirect("/login");
  }
};

//   // Axios get request
//   const response = await axios.get(
//     `${serverHost()}/users/verify/${magicLink}`
//   );
//   console.log("This is the response:");
//   console.log(response);

//   // If error, throw error
//   if (response.status !== "success") {
//     console.log("This is the error:", response.response);
//     if (response.error.statusCode === 410) {
//       alert(
//         "This link has expired. We have sent a new email - please try that one."
//       );
//     }
//     if (response.status === 404) {
//       alert("This link is invalid. Go to login.");
//     }
//     // else {
//     //   throw json({ message: response.message }, { status: 500 });
//     // }
//   }
//   // Save token and company to local storage
//   const token = response.data.token;
//   console.log("Attempting to set the token", response.data.token);
//   localStorage.setItem("token", token);
//   const expiration = new Date();
//   expiration.setHours(expiration.getHours() + 24); // TODO: Change this
//   localStorage.setItem("expiration", expiration.toISOString());

//   // If success, redirect to home page
//   return redirect("/home");
// };

//   const response = await fetch(`/api/magic/${magicLink}`);
//   const data = await response.json();
//   if (data.status === "success") {
//     return data;
//   }
//   return { error: data.message };
// };
