import classes from "./BtnChoice.module.css";
import Modal from "../ui/Modal2";
import { useState } from "react";

function BtnChoice(props) {
  // Random number between -1 and 1
  const randomRot = Math.random() * 2 - 1;

  let showAlert = () => {
    alert(props.textBelowOnClick);
  };

  // Modal
  const [modalShowing, setModalShowing] = useState(false);

  return (
    // <div>
    <>
      <div className={classes.btnChoiceWrapper}>
        <button
          className={`${classes.btnChoice} ${
            props.isSelected ? classes.btnChoiceSelected : ""
          } ${props.isDisabled ? classes.btnChoiceDisabled : ""} ${
            props.isSkip ? classes.btnChoiceSkip : ""
          }}`}
          onClick={props.onClick}
        >
          <div
            className={`${classes.btnChoiceContentHolder} ${
              props.largeImage ? classes.btnChoiceContentHolderLarge : ""
            }`}
            style={{ transform: "rotate(" + randomRot + "deg)" }}
          >
            {props.imgSrc && (
              <img src={"/img/icons/" + props.imgSrc + ".png"} />
            )}
            {props.btnText}
          </div>
        </button>
        {/* If text below */}
        {props.textBelow && (
          <div
            onClick={() => setModalShowing(true)}
            className={classes.textBelow}
          >
            {props.textBelow}
          </div>
        )}
      </div>
      <Modal isShowing={modalShowing} onClose={() => setModalShowing(false)}>
        <div className={classes.modalContent}>
          <p>{props.textBelowOnClick}</p>
        </div>
      </Modal>
    </>

    // </div>
  );
}

export default BtnChoice;
