import Modal from "../ui/Modal";
import classes from "./CameraModal.module.css";
import Camera, { IMAGE_TYPES } from "react-html5-camera-photo";
import axios from "axios";
import { getAuthToken } from "../../util/auth";
import { json } from "react-router-dom";
import { serverHost } from "../../util/usefulServerCalls";

const CameraModal = function (props) {
  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    console.log("takePhoto");
    console.log(dataUri);
    sendPhotoToServer(dataUri);
  }

  async function sendPhotoToServer(dataUri) {
    // Send photo to server
    const response = await axios.post(
      `${serverHost()}/users/myPhoto`,
      {
        photo: dataUri,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      }
    );
    if (response.data.status !== "success") {
      throw json({ message: "Could not upload photo." }, { status: 500 });
    }

    // Reload the user
    props.userCtx.reloadUser();

    // Close the modal
    props.onClose();

    // Set user photo within context
  }

  //   function sendToServer({
  //     action: 'post',
  //     url: 'serverHost()/users/me/profile-image',
  //     data: {
  //       image: dataUri
  //     },
  //   })

  return (
    <Modal onClose={props.onClose} isShowing={props.isShowing}>
      <div className={classes.cameraModal}>
        <h2>Press the white button to take your photo!</h2>
        <Camera
          imageType={IMAGE_TYPES.JPG}
          idealResolution={{ width: 250, height: 250 }}
          imageCompression={0.3}
          isImageMirror={true}
          onTakePhoto={(dataUri) => {
            handleTakePhoto(dataUri);
          }}
        />
      </div>
    </Modal>
  );
};

export default CameraModal;
