import React, { Suspense, useEffect, useState, useContext } from "react";
import Nux from "../components/ui/Nux";
import Modal from "../components/ui/Modal2";
import {
  useLoaderData,
  json,
  defer,
  Await,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import axios from "axios";
import PaperImage from "../components/ui/PaperImage";

import { serverHost } from "../util/usefulServerCalls";

import classes from "./StoryHomePage.module.css";
import BigPictureLink from "../components/ui/BigPictureLink";

import UserContext from "../store/UserContext";
import BtnJaunty from "../components/ui/BtnJaunty";
// import copyURLToClipboard from "../components/util/CopyToClipboard";
import ShareThisStoryModal from "../components/shares/ShareThisStoryModal";

function StoryHomePage() {
  // Get loaded data - using loaderData without Await/Suspense
  // const data = useLoaderData();
  // if (data === null) {
  //   return <p>Sorry, we were unable to load this story.</p>;
  // }
  // const story = data;
  // return (
  //   <>
  //     <PageContent title="">
  //       <h3>{story.title}</h3>
  //       <div>
  //         {story.text.easy.map((paragraph, index) => {
  //           return <p key={index}>⭐️ {paragraph}</p>;
  //         })}
  //       </div>
  //     </PageContent>
  //   </>
  // );

  // Navigate
  const navigate = useNavigate();

  // Show share modal (for quick share)
  const [showShareModal, setShowShareModal] = useState(false);

  // Show create new modal (for creating new story - upsell to create account)
  const [showCreateNewStoryModal, setShowCreateNewStoryModal] = useState(false);
  useState(false);

  // Detect if a user is logged in using token and auth
  const { isLoggedIn } = useContext(UserContext);
  const userCtx = useContext(UserContext);
  console.log("USER CONTEXT", userCtx);

  // This is from outlet on story root, where story is loaded
  const [
    storyContent,
    setStoryContent,
    sharesFromMe,
    setSharesFromMe,
    sharesWithMe,
    setSharesWithMe,
    storyParameters,
    setStoryParameters,
  ] = useOutletContext();

  let coverImage = storyContent.coverImage;

  return (
    <>
      <div className={classes.homeContainer}>
        <div
          className={classes.coverImageContainer}
          onClick={() => {
            navigate("read");
          }}
        >
          <PaperImage seedText={"rotationSeed"} imgSrc={coverImage} />
        </div>
        <div className={classes.readSetupShareContainer}>
          <div className={classes.readStoryBtn}>
            <BigPictureLink
              to="read"
              alt="Read the story"
              imgSrc="/img/art/menus/read_base.png"
              imgSrcHover="/img/art/menus/read_hover.png"
              height="30vh"
              className="pulse"
            />
          </div>
          {/* <h1 className={classes.readStoryBtn} onClick={handleReadStory}>
            Read story
          </h1> */}

          {/* Logged in: the share button goes to new page */}
          {userCtx.user._id && (
            <>
              <div className={classes.setupStoryBtn}>
                <BigPictureLink
                  to="setup"
                  alt="Change the story settings"
                  imgSrc="/img/art/menus/settings_base.png"
                  imgSrcHover="/img/art/menus/settings_hover.png"
                  height="25vh"
                  className="pulseSubtle"
                />
              </div>
              <div className={classes.shareStoryBtn}>
                <BigPictureLink
                  to="share"
                  alt="Share the story with friends"
                  imgSrc="/img/art/menus/share_base.png"
                  imgSrcHover="/img/art/menus/share_hover.png"
                  height="25vh"
                  className="pulseSubtle"
                />
              </div>
            </>
          )}

          {/* Not logged in - the share button just creates a modal */}
          {!userCtx.user._id && (
            <>
              <div className={classes.setupStoryBtn}>
                <BigPictureLink
                  onClick={() => setShowShareModal(true)}
                  to="modal"
                  alt="Share the story with friends"
                  imgSrc="/img/art/menus/share_base.png"
                  imgSrcHover="/img/art/menus/share_hover.png"
                  height="25vh"
                  className="pulseSubtle"
                />
              </div>
              <div className={classes.shareStoryBtn}>
                <BigPictureLink
                  onClick={() => setShowCreateNewStoryModal(true)}
                  to="modal"
                  alt="Make another story!"
                  imgSrc="/img/art/menus/makeanother_base.png"
                  imgSrcHover="/img/art/menus/makeanother_hover.png"
                  height="25vh"
                  className="pulseSubtle"
                />
              </div>
            </>
          )}
          {/* {
            <Nux
              title="Share this story!"
              text={
                <>
                  <br />
                  <p>{`If you can't read a word, tap on it for help!`}</p>
                  <br />
                  <p>
                    {`You can then press the`}{" "}
                    {`logo to hear the word spoken. But careful - this will use up one of your ${storyParameters.livesEmoji} helpers!`}
                  </p>
                  <br />
                </>
              }
              isShowing={true}
              onClose={() => alert(false)}
              greenButtonText="Share"
            />
          } */}
          {/* <Modal
            isShowing={showShareModal}
            onClose={() => setShowShareModal(false)}
            closeButton={true}
          >
            <h1>Share this story</h1>
            <p>{window.location.href}</p>
            <BtnJaunty
              onClick={() => {
                copyURLToClipboard();
                setCopied(true);
              }}
              text={copyBtnText}
              colour={copyBtnColour}
              imgSrc="/img/art/elements/questionMark.png"
              size="medium"
            />
          </Modal> */}
          <ShareThisStoryModal
            isShowing={showShareModal}
            onClose={() => setShowShareModal(false)}
          />

          <Modal
            isShowing={showCreateNewStoryModal}
            onClose={() => setShowCreateNewStoryModal(false)}
            closeButton={true}
          >
            <h1>You need to sign up (it's free!)</h1>
            <p>
              To write a story, you need an account. This will let you write
              stories, save favourites, and share with friends!
            </p>
            <BtnJaunty
              onClick={() => {
                navigate("/?state=signup");
              }}
              text="Create an account"
              colour="green"
              imgSrc="/img/art/book-stars.png"
              size="medium"
            />
          </Modal>
        </div>
      </div>
    </>
  );

  // return (
  //   <PageContent title="">
  //     {/* HOME */}
  //     {/* {storyView === "home" && ( */}
  //     <div className="flex-row">
  //       {/* Cover */}
  //       {/* <Suspense fallback={<p>Loading...</p>}>
  //           <Await resolve={story}>
  //             {(loadedStory) => {
  //               return <StoryCover story={loadedStory} />;
  //             }}
  //           </Await>
  //         </Suspense> */}
  //       <img className="coverImage" src={coverImage} alt="Cover image" />

  //       {/* Navigation */}
  //       <div className="flex-col">
  //         <h1 onClick={handleSetupStory}>Setup story</h1>
  //         <br></br>
  //         <br></br>
  //         <h1 onClick={handleReadStory}>Read story</h1>
  //         <br></br>
  //         <br></br>
  //         <h1 onClick={handleShareStory}>Share story</h1>
  //       </div>
  //     </div>
  //     {/* )} */}

  //     {/* PRE/SETUP */}
  //     {/* {storyView === "pre" && <StorySetup />} */}

  //     {/* READ */}
  //     {/* {storyView === "pre" && <StoryReader />} */}

  //     {/* POST/SHARE */}
  //     {/* {storyView === "pre" && <StoryShare />} */}
  //   </PageContent>
  // );
}

export default StoryHomePage;

// export async function loader({ params }) {
//   // Load data with axios
//   const response = await axios.get(
//     `${serverHost()}/stories/test/${params.id}`
//   );

//   if (response.data.status !== "success") {
//     throw json(
//       { message: "Could not get story." },
//       {
//         status: 500,
//       }
//     );
//   }
//   const story = response.data.data.data;
//   return story;
// }

// Defer the load until page is loaded
async function loadStory(id) {
  console.log("Running the storyLoader");
  const response = await fetch(`${serverHost()}/stories/${id}`);

  if (!response.ok) {
    throw json(
      { message: "Could not get story." },
      {
        status: 500,
      }
    );
  }

  const resData = await response.json();
  return resData.data.data; // This is the story object
}

// This is the loader
export async function loader({ params }) {
  // We give the story object a key called story
  return defer({ story: loadStory(params.id) });
}
