import { useNavigate } from "react-router-dom";

function LoadingOverlay({ builtStoryId }) {
  const giphyLinks = [
    "14uXQbPS73Y3qU",
    "33zX3zllJBGY8",
    "2GrNmaGsI2ybu",
    "c1c1M1a2yZDd9aVReu",
    "lowAFsBujmjQ0MQ55i",
    "Wq3andvvOQ4uf3yak1",
    "6GoN2XydMRV65YBdp2",
    "11BbGyhVmk4iLS",
    "UFGj6EYw5JhMQ",
    "ule4vhcY1xEKQ",
    "y5OffROvBod0s",
    "3o85xsz5nqVjzphbZC",
    "bYUbS6XYDi3Ze",
  ];
  const randomGiphy = giphyLinks[Math.floor(Math.random() * giphyLinks.length)];
  const randomGiphyURL = `https://giphy.com/embed/${randomGiphy}`;

  // Navigate to new story
  const navigate = useNavigate();
  const navigateToNewStoryHandler = () => {
    if (!builtStoryId) return;
    navigate(`/story/${builtStoryId}`);
  };

  return (
    <div className="loading-overlay">
      <h1 className="loading-text">We are building your story...</h1>
      <iframe src={randomGiphyURL} width="60%" height="60%"></iframe>
      <p>When your story is ready, click below:</p>
      <button
        className="btn"
        disabled={!builtStoryId}
        onClick={navigateToNewStoryHandler}
      >
        {builtStoryId ? "Read my story" : "Loading..."}
      </button>
    </div>
  );
}

export default LoadingOverlay;
