import classes from "./BtnJaunty.module.css";
import { useNavigate } from "react-router-dom";

function BtnJaunty(props) {
  //   const { children, ...rest } = props;
  //   return (
  //     <button className="btn-jaunty" {...rest}>
  //       {children}
  //     </button>
  //   );
  const { colour, text, imgSrc, size, disabled, pulse } = props;
  console.log("BtnJaunty props", props);

  // If there is a 'to' prop, then this is a link
  const navigate = useNavigate();
  let onClick = props.onClick;
  if (props.to) {
    onClick = () => {
      navigate(props.to);
    };
  }

  // Random initial rotation between -1 and 1 degrees
  const randomRotation = Math.floor(Math.random() * 3) - 1.5;

  // Four random values between 0 and 20
  const randomValues = Array.from({ length: 4 }, () =>
    Math.floor(Math.random() * 20)
  );

  return (
    <button
      // Added in onclick but will this screw other things?
      onClick={onClick}
      type={props.type}
      className={`
        ${classes.btnJaunty}
        ${colour ? classes[colour] : ""}
        ${size === "tiny" ? classes.btnJaunty__tiny : ""}
        ${size === "medium" ? classes.btnJaunty__medium : ""}
        ${size === "large" ? classes.btnJaunty__large : ""}
        ${pulse ? "pulse" : ""}
      `}
      style={{
        transform: "rotate(" + randomRotation + "deg)",
        borderRadius:
          "255px " +
          randomValues[0] +
          "px 225px " +
          randomValues[1] +
          "px/" +
          randomValues[2] +
          "px 225px " +
          randomValues[3] +
          "px 255px",
      }}
      disabled={disabled}
    >
      <div className={classes.imgAndTextRow}>
        {imgSrc && <img className={classes.btnImage} src={imgSrc} alt={text} />}
        {imgSrc && <div className={classes.blankPlacedholder}>&nbsp;</div>}
        {text && <h2>{text}</h2>}
      </div>
      {props.children}
    </button>
  );
}

export default BtnJaunty;
