import ShareCard from "./ShareCard";
import TitleBar from "../ui/TitleBar";

function SharesWithMePanel({ sharesWithMe }) {
  return (
    <>
      <TitleBar text="This story was shared with you by:" />
      {sharesWithMe.map((share) => {
        let shareDetails = {
          shareId: share.id,
          sharerId: share.sharerId._id,
          sharerName: share.sharerId.name,
          sharerMessage: share.sharerMessage,
          recipientComment: share.recipientComment,
        };
        return <ShareCard key={share._id} shareDetails={shareDetails} />;
      })}
    </>
  );
}

export default SharesWithMePanel;
