// Control a list of IDs of stories that have been read
// This controls the 'read' markings on stories around the site
// And also the counter on the top nav

import React, { useState, useEffect, createContext } from "react";
import { getAuthToken } from "../util/auth";
import { serverHost } from "../util/usefulServerCalls";
import axios from "axios";

const ReadsContext = createContext({
  reads: [],
  addRead: (storyId) => {},
  fetchReads: () => {},
  clear: () => {},
});

// Export a provider component
export const ReadsContextProvider = (props) => {
  // Reads
  const [reads, setReads] = useState([]);
  const [serverHasBeenCalled, setServerHasBeenCalled] = useState(false);

  // Populate reads from server on initial load
  const fetchReadsHandler = async () => {
    const token = getAuthToken();
    if (!token) {
      // console.log("👉 No token found, so not loading reads");
      return;
    }
    const response = await fetch(`${serverHost()}/users/myReads`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const resData = await response.json();
    // console.log("👉 Reads data loaded from server", resData.data);
    const readObjs = resData.data;
    // console.log("👉 Reads data loaded from server", readObjs);

    // Get storyIds from readObjs
    const readStoryIds = readObjs.map((readObj) => readObj.storyId);

    setReads(readStoryIds);
    setServerHasBeenCalled(true);
  };

  // Load the reads from the server at start
  useEffect(() => {
    // if (serverHasBeenCalled) return;
    fetchReadsHandler();
  }, []);

  // Add a read to the list
  const addReadHandler = async (storyId) => {
    // Add to context, but only add if not already there
    if (!reads.includes(storyId)) {
      setReads((prevReads) => {
        return prevReads.concat(storyId);
      });
    }

    const token = getAuthToken();
    const response = await axios.post(
      `${serverHost()}/stories/${storyId}/read`,
      {}, // No data to send
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  };

  // Story is read
  const storyIsRead = (storyId) => {
    return reads.includes(storyId);
  };

  return (
    <ReadsContext.Provider
      value={{
        reads: reads,
        addRead: addReadHandler,
        fetchReads: fetchReadsHandler,
        storyIsRead: storyIsRead,
      }}
    >
      {props.children}
    </ReadsContext.Provider>
  );
};

// Export
export default ReadsContext;
