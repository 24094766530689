// Use site-wide state on favourite stories to mark with or without a heart wherever they appear
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { getAuthToken } from "../util/auth";
import { json, useRouteLoaderData } from "react-router-dom";
import { serverHost } from "../util/usefulServerCalls";

const FavouritesContext = React.createContext({
  favourites: [],
  fetchFavourites: () => {},
  addFavourite: (storyId) => {},
  removeFavourite: (storyId) => {},
  itemIsFavourite: (storyId) => {},
  clear: () => {},
});

// Export a provider component
export const FavouritesContextProvider = (props) => {
  // Favourites
  const [favourites, setFavourites] = useState([]);

  // Populate favourites from server on initial load
  const fetchFavouritesHandler = async () => {
    const token = getAuthToken();
    if (!token) {
      // console.log("👉 No token found, so not loading favourites");
      return;
    }
    const response = await fetch(`${serverHost()}/favourites`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const resData = await response.json();
    // console.log("👉 Favourites data loaded from server", resData.data);
    const favouriteObjs = resData.data;
    const favouriteStoryIds = favouriteObjs.map(
      (favouriteObj) => favouriteObj.storyId.id // Populating storyId with full story, so need to now pull ID out. May change in future in which case we will need to change this.
    );
    setFavourites(favouriteStoryIds);
  };

  useEffect(() => {
    fetchFavouritesHandler();
  }, []);

  const emptyFavouritesHandler = () => {
    setFavourites([]);
  };

  const addFavouriteHandler = async (storyId) => {
    console.log("Adding favourite: " + storyId);
    // Add to context
    setFavourites((prevFavourites) => {
      return prevFavourites.concat(storyId);
    });
    // Also send to server to add to user's favourites
    const token = getAuthToken();
    const response = await axios.post(
      `${serverHost()}/stories/${storyId}/favourite`,
      {}, // No data to send
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  };

  const removeFavouriteHandler = async (storyId) => {
    console.log("Removing favourite: " + storyId);
    // Remove from context
    setFavourites((prevFavourites) => {
      return prevFavourites.filter((favourite) => favourite !== storyId);
    });
    // Also send to server to remove from user's favourites
    const token = getAuthToken();
    const response = await axios.delete(
      `${serverHost()}/stories/${storyId}/favourite`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  };

  const itemIsFavouriteHandler = (storyId) => {
    return favourites.includes(storyId);
  };

  const contextValue = {
    favourites: favourites,
    fetchFavourites: fetchFavouritesHandler,
    addFavourite: addFavouriteHandler,
    removeFavourite: removeFavouriteHandler,
    itemIsFavourite: itemIsFavouriteHandler,
    clear: emptyFavouritesHandler,
  };

  return (
    <FavouritesContext.Provider value={contextValue}>
      {props.children}
    </FavouritesContext.Provider>
  );
};

// Default export
export default FavouritesContext;
