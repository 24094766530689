import { Link } from "react-router-dom";
import classes from "./BadgeLink.module.css";

function BadgeLink(props) {
  let containerClass = classes.badgeLinkContainer;
  let to = props.to;
  if (props.comingSoon) {
    containerClass = `${classes.badgeLinkContainer} ${classes.comingSoon}`;
    to = "";
  }

  return (
    <div className={containerClass} disabled={props.comingSoon}>
      <Link to={to} className={classes.badgeLink}>
        <img className={classes.badgeLinkImg} src={props.imgSrc} />
        <h1 className={classes.badgeLinkTitle}>{props.text}</h1>
      </Link>
      {props.comingSoon && (
        <p className={classes.comingSoonText}>Coming soon!</p>
      )}
    </div>
  );
}

export default BadgeLink;
