import classes from "./BottomBanner.module.css";
import { serverHost } from "../../util/usefulServerCalls";

function BottomBanner() {
  return (
    <div className={classes.bottomBanner}>
      <div className={classes.content}>
        <p>
          <span className={classes.yellowHighlight}>
            Whizz Bang Story Maker
          </span>{" "}
          is currently in early Beta. Please don't hurt any of the bugs you
          find.
        </p>
      </div>
    </div>
  );
}

export default BottomBanner;
