// Modal to share story
import Modal from "../ui/Modal2";
import BtnJaunty from "../ui/BtnJaunty";
import copyURLToClipboard from "../util/CopyToClipboard";
import { useState, useEffect, useContext } from "react";
import ShareThisStoryPanel from "./ShareThisStoryPanel";
import StoryShare from "../story/StoryShare";
import TitleBar from "../ui/TitleBar";
import UserContext from "../../store/UserContext";
import classes from "./ShareThisStoryModal.module.css";

function ShareThisStoryModal(props) {
  // Assess whether user is logged in
  const userCtx = useContext(UserContext);
  let userIsLoggedIn = userCtx.user && userCtx.user._id ? true : false;

  // Share story copy to clipboard - style changes on button
  const [copied, setCopied] = useState(false);
  let copyBtnColour = "blue";
  let copyBtnText = "Copy";
  let copyBtnImg = "book.png";
  if (copied) {
    copyBtnColour = "green";
    copyBtnText = "Link copied!";
    copyBtnImg = "book-stars.png";
  }
  // Style turns back after 3 seconds
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  // If not logged in, a user just sees the option to copy a link to the story
  // If logged in, they can make story sharable (to get a link) and they can also see their friends / add-friend button
  let body;
  if (!userIsLoggedIn) {
    // If not logged in, a user just sees the option to copy a link to the story
    body = (
      <>
        <TitleBar text="Share with anyone" />
        <div className="spacing">
          <p
            className={classes.storyURL}
            onClick={() => {
              copyURLToClipboard();
              setCopied(true);
            }}
          >
            {window.location.href}
          </p>
        </div>
        <div className="spacing">
          <BtnJaunty
            onClick={() => {
              copyURLToClipboard();
              setCopied(true);
            }}
            text={copyBtnText}
            colour={copyBtnColour}
            imgSrc={`/img/art/${copyBtnImg}`}
            size="medium"
          />
        </div>
      </>
    );
  } else {
    // If logged in, they can make story sharable (to get a link) and they can also see their friends / add-friend button
    // body = <ShareThisStoryPanel alreadyShared={props.alreadyShared} />;
    // Will tell them if they can't shrae a story if they are not author. All stored in StoryShare
    body = <StoryShare />;
  }

  return (
    <Modal
      isShowing={props.isShowing}
      onClose={props.onClose}
      closeButton={true}
    >
      {body}
    </Modal>
  );
}

export default ShareThisStoryModal;
