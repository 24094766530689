import classes from "./FreeformEntry.module.css";
import { useState } from "react";
import GoButton from "./GoButton";
import { FaArrowAltCircleRight } from "react-icons/fa";

function FreeformEntry(props) {
  const [enteredValue, setEnteredValue] = useState("");

  // Ready if user has entered 2 OR already filled with initial value
  const readyToSubmit =
    enteredValue.length > 2 ||
    (props.initialValue && props.initialValue.length > 2);

  const submitWithValue = (e) => {
    e.preventDefault();
    const enteredValue = e.target[0].value;
    // props.onFreeformSubmit(enteredValue);
    props.onFreeformSubmit({
      payload: enteredValue,
      type: props.freeFormSubmitPayloadType, // Sent by parent
    });
  };

  const onChangeHandler = (e) => {
    setEnteredValue(e.target.value);
  };

  return (
    <>
      <form onSubmit={submitWithValue}>
        <input
          className={classes.inputTextField}
          autoFocus
          type="text"
          placeholder={props.placeholder}
          value={enteredValue ? enteredValue : props.initialValue}
          onChange={onChangeHandler}
        />
        <GoButton disabled={!readyToSubmit} />

        {/* <FaArrowAltCircleRight type="submit" /> */}
      </form>
    </>
  );
}

export default FreeformEntry;
