import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import classes from "./Modal2.module.css";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

// Modal can have optional props.closeButton = true, which will add a red cross in the corner
const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <CSSTransition
          in={props.isShowing}
          timeout={props.timeout || 300}
          classNames="TransitionBackdrop"
          mountOnEnter
          unmountOnExit
        >
          <Backdrop onClose={props.onClose} />
        </CSSTransition>,
        portalElement
      )}

      {/* Content of the modal */}
      {ReactDOM.createPortal(
        <CSSTransition
          in={props.isShowing}
          timeout={props.timeout || 300}
          classNames="TransitionModalFlyInBottom"
          mountOnEnter
          unmountOnExit
        >
          <ModalOverlay>
            {/* Optional red cross in corner to close */}
            {props.closeButton && (
              <img
                src="/img/art/elements/redCross.png"
                alt="Close window"
                className={classes.closeButton}
                onClick={props.onClose}
              />
            )}
            {props.children}
          </ModalOverlay>
        </CSSTransition>,
        portalElement
      )}
    </>
  );
};

export default Modal;
