import {
  Form,
  NavLink,
  useRouteLoaderData,
  Link,
  useNavigate,
} from "react-router-dom";
import classes from "./MainNavigation.module.css";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../store/UserContext";
import ReadsContext from "../../store/ReadsContext";
import FavouritesContext from "../../store/FavouritesContext";
import FriendsContext from "../../store/FriendsContext";

import MainNavCounterIcon from "./MainNavCounterIcon";
import BtnJaunty from "../ui/BtnJaunty";

import { useCustomNavigate } from "../../util/navigateUtils";

import Modal from "../ui/Modal2";

function MainNavigation() {
  // Navigate
  const navigate = useNavigate();
  // User context
  const userCtx = useContext(UserContext);
  // Favourites context
  const favouritesCtx = useContext(FavouritesContext);
  // Read stories context
  const readCtx = useContext(ReadsContext);
  // Friends context
  const friendsCtx = useContext(FriendsContext);
  console.log(friendsCtx.friendsPendingCount);

  // Token to check if user is logged in
  const token = useRouteLoaderData("root");
  const userLoggedIn = token && userCtx.user ? true : false;

  // Effect for bouncing the point indicators when changed
  const [btnBouncingFavourites, setBtnBouncingFavourites] = useState(false);
  const favouritePointsClasses = `${classes.pointsIndicator} ${
    btnBouncingFavourites ? classes.bump : ""
  }`;
  const [btnBouncingWritten, setBtnBouncingWritten] = useState(false);
  const writtenPointsClasses = `${classes.pointsIndicator} ${
    btnBouncingWritten ? classes.bump : ""
  }`;
  const [btnBouncingShared, setBtnBouncingShared] = useState(false);
  const sharedPointsClasses = `${classes.pointsIndicator} ${
    btnBouncingShared ? classes.bump : ""
  }`;
  const [btnBouncingRead, setBtnBouncingRead] = useState(false);
  const readPointsClasses = `${classes.pointsIndicator} ${
    btnBouncingRead ? classes.bump : ""
  }`;
  const [btnBouncingFriends, setBtnBouncingFriends] = useState(false);
  const friendsPointsClasses = `${classes.pointsIndicator} ${
    btnBouncingFriends ? classes.bump : ""
  }`;

  // When something is added to the favourites, set the state to true, then after 300ms set it to false
  useEffect(() => {
    if (favouritesCtx.favourites.length > 0) {
      setBtnBouncingFavourites(true);
    } else {
      return;
    }
    const timer = setTimeout(() => {
      setBtnBouncingFavourites(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [favouritesCtx.favourites]);

  // When points are added, set the state to true, then after 300ms set it to false
  useEffect(() => {
    if (userCtx.user.points.written > 0) {
      setBtnBouncingWritten(true);
    } else {
      return;
    }
    const timer = setTimeout(() => {
      setBtnBouncingWritten(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [userCtx.user.points.written]);

  // When points are added, set the state to true, then after 300ms set it to false
  useEffect(() => {
    if (userCtx.user.points.shared > 0) {
      setBtnBouncingShared(true);
    } else {
      return;
    }
    const timer = setTimeout(() => {
      setBtnBouncingShared(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [userCtx.user.points.shared]);

  // When points are added, set the state to true, then after 300ms set it to false
  useEffect(() => {
    if (readCtx.reads.length > 0) {
      setBtnBouncingRead(true);
    } else {
      return;
    }
    const timer = setTimeout(() => {
      setBtnBouncingRead(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [readCtx.reads.length]);

  // Friends points
  useEffect(() => {
    if (friendsCtx.friendsAcceptedCount > 0) {
      setBtnBouncingFriends(true);
    } else {
      return;
    }
    const timer = setTimeout(() => {
      setBtnBouncingFriends(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [friendsCtx.friendsAcceptedCount]);

  // Show modal if user is not logged in and clicks 'about' etc.
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [showSchoolsModal, setShowSchoolsModal] = useState(false);

  const aboutModal = (
    <Modal onClose={() => setShowAboutModal(false)} isShowing={showAboutModal}>
      <div className={classes.homeModalContent}>
        <div className={classes.modalHeaderContainer}>
          <h1 className={classes.modalHeader}>Whizz Bang Story Maker</h1>
          <p className={classes.modalParagraph}>
            Whizz-Bang Story Maker is a magical new platform for children aged 4
            to 8 to create and share stories with friends in a way they never
            will have done before! Designed to make reading and story-telling
            fun, and sharable with friends.
          </p>
        </div>

        <div className={classes.modalButtonContainer}>
          <BtnJaunty
            onClick={() => {
              // This is a redirect, but with a query parameter of state=signup
              setShowAboutModal(false);
              goToHomepageWithState("signup");
            }}
            text="Create your child's account now!"
            imgSrc="/img/art/book-stars.png"
            imgAlt="Sign up"
            colour="green"
          />
        </div>

        <section className={classes.modalSection}>
          <div className={classes.modalGridImageRHS}>
            <div className={classes.modalTextContainer}>
              <h1 className={classes.modalHeader}>Magic</h1>
              <p className={classes.modalParagraph}>
                Using the power of AI, children can magically see their zaniest
                ideas come to life within seconds. A small band of zany robots
                will take input from your child, writing stories and painting
                beautiful illustrations in no time at all, for your child to
                read and share with their friends.
              </p>
            </div>
            <div className={classes.modalImageContainer}>
              <img
                className={classes.modalImage}
                src="/img/art/animated/magic.png"
              />
            </div>
          </div>
        </section>

        <section className={classes.modalSection}>
          <div className={classes.modalGridImageLHS}>
            <div className={classes.modalImageContainer}>
              <img
                className={classes.modalImage}
                src="/img/art/animated/crash.png"
              />
            </div>
            <div className={classes.modalTextContainer}>
              <h1 className={classes.modalHeader}>Creative</h1>
              <p className={classes.modalParagraph}>
                Your child can answer simple prompts and questions as creatively
                as they wish, and see these ideas come to life. They can also
                work with a grown-up to write out and illustrate their own story
                from scratch. If they wish, they can use Whizz-Bot to help
                suggest even more creative ideas on top of their work.
              </p>
            </div>
          </div>
        </section>

        <section className={classes.modalSection}>
          <div className={classes.modalGridImageRHS}>
            <div className={classes.modalTextContainer}>
              <h1 className={classes.modalHeader}>Reading</h1>
              <p className={classes.modalParagraph}>
                Whizz-Bang was built with fostering a love of reading at its
                heart. Every word can be broken down into its parts (phonics),
                to help younger children sound out those longer words. Or, if
                this is not enough, the child can use up one of their robot
                helpers to hear a word read out to them!
              </p>
            </div>
            <div className={classes.modalImageContainer}>
              <img
                className={classes.modalImage}
                src="/img/art/animated/reading.png"
              />
            </div>
          </div>
        </section>

        <section className={classes.modalSection}>
          <div className={classes.modalGridImageLHS}>
            <div className={classes.modalImageContainer}>
              <img
                className={classes.modalImage}
                src="/img/art/animated/social.png"
              />
            </div>
            <div className={classes.modalTextContainer}>
              <h1 className={classes.modalHeader}>Social</h1>
              <p className={classes.modalParagraph}>
                Whizz-Bang is a safe place to connect with friends that a child
                knows in real life, allowing simple and fun sharing of stories
                created. There is nothing more motivating than reading a story
                your friend has made just for you, where the two of you are the
                heroes going on an adventure!
              </p>
            </div>
          </div>
        </section>

        <section className={classes.modalSection}>
          {/* Button to sign up */}
          <div className={classes.modalButtonContainer}>
            <BtnJaunty
              onClick={() => {
                // This is a redirect, but with a query parameter of state=signup
                setShowAboutModal(false);
                goToHomepageWithState("signup");
              }}
              text="Create your child's account now!"
              imgSrc="/img/art/book-stars.png"
              imgAlt="Sign up"
              colour="green"
            />
          </div>
        </section>
      </div>
    </Modal>
  );

  const teamModal = (
    <Modal onClose={() => setShowTeamModal(false)} isShowing={showTeamModal}>
      <div className={classes.homeModalContent}>
        <p>
          Made with ❤️ by David Abelman, a father to two young boys, in London.
        </p>

        <p>
          If you are a creative or teacher interested in collaborating, or an
          educational institution interested in using the platform, you can get
          in touch at davidabelman@gmail.com.
        </p>
      </div>
    </Modal>
  );

  const pricingModal = (
    <Modal
      onClose={() => setShowPricingModal(false)}
      isShowing={showPricingModal}
    >
      <div className={classes.homeModalContent}>
        <p>
          Whizz-Bang Story Maker is currently free whilst in Beta. In future
          there will be a low-cost subscription model for some features (for
          example, generating longer stories or illustrations), to cover costs
          and development.
        </p>
      </div>
    </Modal>
  );

  const schoolsModal = (
    <Modal
      onClose={() => setShowSchoolsModal(false)}
      isShowing={showSchoolsModal}
    >
      <div className={classes.homeModalContent}>
        <section className={classes.modalSection}>
          <div className={classes.modalGridImageRHS}>
            <div className={classes.modalTextContainer}>
              <h1 className={classes.modalHeader}>Schools</h1>
              <p className={classes.modalParagraph}>
                If you are a school that would like to use Whizz-Bang Story
                Maker for your entire class (for free!) please get in touch.
              </p>
              <br />
              <br />
              <BtnJaunty
                // to="mailto:whizzbang@storymaker.world"
                onClick={() => {
                  window.location.href = "mailto:whizzbang@storymaker.world";
                }}
                text="Send us an email"
                imgSrc="/img/art/envelope.png"
                colour="blue"
              />
            </div>
            <div className={classes.modalImageContainer}>
              <img
                className={classes.modalImage}
                src="/img/art/animated/social.png"
              />
            </div>
          </div>
        </section>

        {/* <p>
          If you are a school that would like to use Whizz-Bang Story Maker for
          your entire class, please get in touch.
        </p>
        <BtnJaunty
          // to="mailto:whizzbang@storymaker.world"
          onClick={() => {
            window.location.href = "mailto:whizzbang@storymaker.world";
          }}
          text="Send us an email"
          imgSrc="/img/art/envelope.png"
          colour="blue"
        /> */}
      </div>
    </Modal>
  );

  // Custom navigate to homepage with state in query parameters - used if not logged in
  const { goToHomepageWithState } = useCustomNavigate();

  return (
    <header>
      {aboutModal}
      {teamModal}
      {pricingModal}
      {schoolsModal}
      <nav className={classes.mainNavigation}>
        <div className={classes.mainContainerLHS}>
          <NavLink to={userLoggedIn ? "/home" : "/"}>
            <img
              className={classes.mainNavLogoImg}
              src="/img/art/frontpage_logo.png"
            />
          </NavLink>
        </div>

        {/* Points */}
        <div className={classes.navPoints}>
          {userLoggedIn && (
            <ul>
              <li className={writtenPointsClasses}>
                {/* <Link to="/library/mine">
                  Written ✍️: {userCtx.user.points.written}
                </Link> */}
                <MainNavCounterIcon
                  to="/factory"
                  imgSrc="/img/art/machine.png"
                  imgAlt="Written"
                  counter={userCtx.user.points.written}
                />
              </li>
              <li className={favouritePointsClasses}>
                <MainNavCounterIcon
                  to="/favourites"
                  imgSrc="/img/art/heartRed.png" // Could use chest.png
                  imgAlt="Favourites"
                  counter={favouritesCtx.favourites.length}
                />
              </li>

              <li className={readPointsClasses}>
                {/* <Link to="/library">Read 📖: {userCtx.user.points.read}</Link> */}
                <MainNavCounterIcon
                  to="/library"
                  imgSrc="/img/art/book.png"
                  imgAlt="Read"
                  counter={readCtx.reads.length}
                />
              </li>

              <li className={friendsPointsClasses}>
                <MainNavCounterIcon
                  to="/friends"
                  imgSrc="/img/art/robot-head.png"
                  imgAlt="Friends"
                  counter={friendsCtx.friendsAcceptedCount}
                  newIcon={friendsCtx.friendsPendingCount > 0}
                />
              </li>
              {/* <li className={sharedPointsClasses}>
                <MainNavCounterIcon
                  to="/library/shared"
                  imgSrc="/img/art/envelope.png"
                  imgAlt="Shared"
                  counter={userCtx.user.points.shared}
                />
              </li> */}
            </ul>
          )}
        </div>
        <div className={classes.navUserActions}>
          <ul>
            {/* Where the user's buttons are if logged in */}
            {userLoggedIn && (
              <>
                <li>
                  <div className={classes.nameAndPhoto}>
                    <NavLink to="/profile">{userCtx.user.name}</NavLink>
                    <NavLink to="/profile">
                      <img className={classes.photo} src={userCtx.user.photo} />
                    </NavLink>
                  </div>
                </li>
              </>
            )}

            {/* Or login button is if they are not already logged in */}
            {!userLoggedIn && (
              <>
                <li>
                  <BtnJaunty
                    onClick={() => {
                      setShowAboutModal(true);
                    }}
                    text="About"
                    imgSrc="/img/art/heartRed.png"
                    imgAlt="About"
                    colour="violet"
                  />
                </li>
                <li>
                  <BtnJaunty
                    onClick={() => {
                      setShowSchoolsModal(true);
                    }}
                    text="Schools"
                    imgSrc="/img/art/elements/star_colour.png"
                    imgAlt="Schools"
                    colour="violet"
                  />
                </li>
                <li>
                  <BtnJaunty
                    onClick={() => {
                      navigate("/login");
                    }}
                    text="Log in"
                    imgSrc="/img/art/robot-head.png"
                    imgAlt="Log in"
                    colour="blue"
                  />
                </li>
                <li>
                  <BtnJaunty
                    onClick={() => {
                      // This is a redirect, but with a query parameter of state=signup
                      goToHomepageWithState("signup");
                    }}
                    text="Sign up"
                    imgSrc="/img/art/book-stars.png"
                    imgAlt="Sign up"
                    colour="green"
                  />
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default MainNavigation;
