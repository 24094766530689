// Button that user can press, disappears and lets them type if so
import classes from "./BtnForFreeform.module.css";
import { useState } from "react";
import GoButton from "./GoButton";

function BtnForFreeform(props) {
  const [isClicked, setIsClicked] = useState(false);
  const [enteredValue, setEnteredValue] = useState("");
  const readyToSubmit = enteredValue.length > 2;

  // When button is clicked, set isClicked to true, affects visuals
  const buttonWasClickedHandler = () => {
    setIsClicked(true);
  };

  // Submit with a value to whatever function was passed
  const submitWithValue = (e) => {
    e.preventDefault();
    const enteredValue = e.target[0].value;
    props.onFreeformSubmit({
      payload: enteredValue,
      type: props.freeFormSubmitPayloadType, // Sent by parent
    });
  };

  const onChangeHandler = (e) => {
    setEnteredValue(e.target.value);
  };

  return (
    <div>
      {!isClicked && (
        <button
          type="button"
          className={classes.btn}
          onClick={buttonWasClickedHandler}
        >
          <div className={classes.btnChoiceContentHolder}>
            {<img src={"/img/art/elements/questionMark.png"} />}
            {props.btnText || "Write my own"}
          </div>
        </button>
      )}
      {isClicked && (
        <>
          <form onSubmit={submitWithValue}>
            <input
              className={classes.inputTextField}
              onChange={onChangeHandler}
              autoFocus
              type="text"
            />
            <GoButton disabled={!readyToSubmit} />
          </form>
        </>
      )}
    </div>
  );
}

export default BtnForFreeform;
