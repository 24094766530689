// Export sight words to use elsewhere
export const sightWords = [
  "the",
  "you",
  "said",
  "no",
  "only",
  "once",
  "two",
  "your",
  "are",
  "be",
  "done",
  "gone",
  "don't",
  "of",
  "my",
  "i",
  "they",
  "they're",
  "give",
  "live",
  "whom",
  "whose",
  "what",
  "want",
  "there",
  "some",
  "he",
  "go",
  "are",
  "which",
  "rich",
  "very",
  "own",
  "call",
  "such",
  "is",
  "much",
  "they",
  "her",
  "old",
  "would",
  "should",
  "put",
  "so",
  "to",
  "i'm",
  "you",
  "was",
  "one",
  "who",
];
