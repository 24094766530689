import React, { useState, useRef } from "react";
import BtnJaunty from "./BtnJaunty";
import classes from "./UserComment.module.css";

function UserComment({ name, imgSrc, comment, canSubmit, onSubmit }) {
  console.log("This is the sharer message on load: ", comment);
  // Component holding a user's name, image, and comment
  // canSubmit means if empty, the user can submit a comment
  // onSubmit is a function to call when the user submits a comment

  const [isEditing, setIsEditing] = useState(false);

  const commentRef = useRef();

  const submitComment = (e) => {
    // Submit the comment
    e.preventDefault();
    const commentText = commentRef.current.value;
    onSubmit(commentText);
    setIsEditing(false);
  };

  const editComment = () => {
    // Edit the comment
    setIsEditing(true);
  };

  const commentForm = (
    <form className={classes.commentForm} onSubmit={submitComment}>
      <input
        type="text"
        ref={commentRef}
        className="form__cleanInput"
        autoFocus
      />
      <BtnJaunty text="Send" colour="purple" type="submit" size="tiny" />
    </form>
  );

  const writeMessageBtn = (
    <BtnJaunty
      onClick={editComment}
      text="Send a message"
      colour="purple"
      size="tiny"
    />
  );

  const speechBubble = (
    <div className={classes.speechBubbleContainer}>
      <div className={classes.speechBubble}>"{comment}"</div>
      {/* <div onClick={editComment}>Edit</div> */}
    </div>
  );

  return (
    <div className={classes.userComment}>
      <div className={classes.userImage}>
        <img src={imgSrc} alt={name} />
      </div>

      <div className={classes.comment}>
        {/* {name} */}
        {/* //TODO REMOVE ^^^ */}
        {/* <div className={classes.name}>{name}</div> */}

        {/* If no message, show button to add a message */}
        {canSubmit && !comment && !isEditing && writeMessageBtn}

        {/* If editing, show form and hide edit button */}
        {isEditing && commentForm}

        {/* If there is a message, show the message */}
        {!isEditing && comment && speechBubble}

        {/* If we want an edit button */}
        {/* {canSubmit && !isEditing && (
          <BtnJaunty onClick={editComment} text="Edit" colour="purple" />
        )} */}
      </div>
    </div>
  );
}

export default UserComment;
