import React, { useContext } from "react";
import FavouritesContext from "../../store/FavouritesContext";
import classes from "./FavouriteControllerButton.module.css";

function FavouriteControllerButton(props) {
  // Heart class with size
  const heartClassWithSize = `${classes.heartImg} ${
    props.size === "small" ? classes.small : ""
  }`;

  // Set state
  const [heartClass, setHeartClass] = React.useState(heartClassWithSize);

  const { addFavourite, removeFavourite, itemIsFavourite } =
    useContext(FavouritesContext);

  const addToFavouritesHandler = (storyId) => {
    addFavourite(storyId); // Use context function

    // Add bump class to heart temporarily
    setHeartClass(`${heartClassWithSize} bump`);

    // Remove bump class after 300ms
    setTimeout(() => {
      setHeartClass(heartClassWithSize);
    }, 300);
  };

  const removeFromFavouritesHandler = (storyId) => {
    // Ask user via alert if they are sure, if they are in the trasure trove
    if (window.location.href.includes("favourites")) {
      const proceed = window.confirm(
        "Are you sure you want to remove this from your favourites?"
      );
      if (!proceed) {
        return;
      }
    }
    // Remove from favourites
    removeFavourite(storyId); // Use context function
  };

  const toggleFavouritessHandler = (storyId) => {
    if (itemIsFavourite(storyId)) {
      removeFromFavouritesHandler(storyId);
    } else {
      addToFavouritesHandler(storyId);
    }
  };

  // When something is added to the favourites, set the state to true, then after 300ms set it to false

  let pathToHeart = "/img/art/heartGrey.png";
  if (itemIsFavourite(props.storyId)) {
    pathToHeart = "/img/art/heartMulti.png";
  }

  return (
    <div>
      <img
        src={pathToHeart}
        alt="heart"
        className={heartClass}
        onClick={() => {
          toggleFavouritessHandler(props.storyId);
        }}
      />
    </div>
  );

  // return (
  //   <div
  //     className={`${classes.favouriteContainer}
  //             ${
  //               itemIsFavourite(props.storyId)
  //                 ? classes.favouriteRainbow
  //                 : classes.favouriteGrey
  //             }
  //           `}
  //   >
  //     {itemIsFavourite(props.storyId) && (
  //       <button
  //         onClick={() => {
  //           removeFromFavouritesHandler(props.storyId);
  //         }}
  //       >
  //         💔 Remove from favourites
  //       </button>
  //     )}
  //     {!itemIsFavourite(props.storyId) && (
  //       <button
  //         onClick={() => {
  //           addToFavouritesHandler(props.storyId);
  //         }}
  //       >
  //         ❤️ Add to favourites
  //       </button>
  //     )}
  //   </div>
  // );
}

export default FavouriteControllerButton;
