import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import "./Transitions.css";
import "./Art.css";
import "./Forms.css";
import "./css/flex.css";
import "./css/RootPages.css";
import "./index.css";
import "./Animations.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./store/UserContext";
import { FavouritesContextProvider } from "./store/FavouritesContext";
import { ReadsContextProvider } from "./store/ReadsContext";
import { FriendsContextProvider } from "./store/FriendsContext";
import { AudioContextProvider } from "./store/AudioContext";

// import ScrollToTop from "./components/util/ScrollToTop";
// import { LoadedStoriesContextProvider } from "./store/LoadedStoriesContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FavouritesContextProvider>
      {/* <LoadedStoriesContextProvider> */}
      <UserContextProvider>
        <ReadsContextProvider>
          <FriendsContextProvider>
            <AudioContextProvider>
              <App />
            </AudioContextProvider>
          </FriendsContextProvider>
        </ReadsContextProvider>
      </UserContextProvider>
      {/* </LoadedStoriesContextProvider> */}
    </FavouritesContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
