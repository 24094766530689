import classes from "./StoryPage.module.css";
import WordHelper from "./WordHelper";
import { useState } from "react";
import axios from "axios";
import { json } from "react-router-dom";
import { getAuthToken } from "../../util/auth";
import { CSSTransition } from "react-transition-group";
import PaperImage from "../ui/PaperImage";
import { serverHost } from "../../util/usefulServerCalls";
import { sightWords } from "../../util/sightWords";

function StoryPage(props) {
  const [wordToHelp, setWordToHelp] = useState(null);
  const [wordSplit, setWordSplit] = useState(null);
  const [wordHelperModalShowing, setWordHelperModalShowing] = useState(false);

  const callServerForWordToHelp = async (word) => {
    // Get word from our server
    const url = `${serverHost()}/words/${word}`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${getAuthToken()}` },
    });
    if (response.data.status !== "success") {
      throw json({ message: "Could not load word helper." }, { status: 500 });
    }

    let wordSplit = response.data.data[0].wordSplit;

    // Take the first letter of the word from original in case uppercase
    let wordSplitCorrectCase = word[0] + wordSplit.slice(1);
    setWordSplit(wordSplitCorrectCase);
  };

  const handleWordClick = (e, word) => {
    let wordNoPunctuation = word.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
    setWordSplit(null);
    callServerForWordToHelp(wordNoPunctuation);
    setWordToHelp(wordNoPunctuation);
    setWordHelperModalShowing(true);
    props.setRightArrowIsVisible(false);
    props.setLeftArrowIsVisible(false);
  };

  // Loop through each individual word in the story
  // and create a span for each one
  const words = props.text.split(" ");
  const wordSpans = words.map((word, index) => {
    // Is it on the red word list?
    let tempRedWordList;
    if (props.sightWordsMarked) {
      tempRedWordList = sightWords;
    } else {
      tempRedWordList = [];
    }
    return (
      <span
        key={index}
        className={`${classes.word} ${
          tempRedWordList.includes(word.toLowerCase()) && classes.wordRed
        }`}
        style={{
          animationDelay: `${index * 0.1}s`,
        }}
        onClick={(e) => {
          handleWordClick(e, word);
        }}
      >
        {word}
      </span>
    );
  });

  console.log("This is our image", props.image);
  return (
    <div
      className={classes.bookPageContainer}
      // Can have background image here if we want
      // style={{
      //   backgroundImage: `url("${props.image}")`,
      //   backgroundSize: "cover",
      // }}
    >
      <div
        // The div should have an extra class if there is no image to adjust the grid
        className={`${classes.pageContent} ${
          !props.image && classes.pageContentNoImage
        }`}
      >
        {props.image && (
          <div className={classes.illustrationContainer}>
            <PaperImage imgSrc={props.image} seedText={props.image} />
          </div>
        )}
        <div className={classes.text}>{wordSpans}</div>;
        <WordHelper
          word={wordToHelp}
          wordSplit={wordSplit}
          wordHelperModalShowing={wordHelperModalShowing}
          setWordHelperModalShowing={setWordHelperModalShowing}
          onEarClick={props.onEarClick}
          isSpeaking={props.isSpeaking}
          setRightArrowIsVisible={props.setRightArrowIsVisible}
          setLeftArrowIsVisible={props.setLeftArrowIsVisible}
        />
      </div>
    </div>
  );
}

export default StoryPage;
