import classes from "./PaperCard.module.css";

function PaperCard(props) {
  let paperCardClass = `${classes.paperCard} ${props.className}`;
  if (props.shadow === "big") {
    paperCardClass = `${classes.paperCardBigShadow} ${paperCardClass}`;
  }
  if (props.colour === "purple") {
    paperCardClass = `${classes.paperCardPurple} ${paperCardClass}`;
  }

  return (
    <article
      className={paperCardClass}
      style={{ transform: `rotate(${props.rotate}deg)` }}
    >
      {/* <div className={classes.cardBody}>{props.children}</div> */}
      {props.children}
    </article>
  );
}

export default PaperCard;
