import React from "react";
import classes from "./FullScreenCard.module.css";
import { CSSTransition } from "react-transition-group";

function FullScreenCard(props) {
  const nodeRef = React.useRef(null);
  return (
    <CSSTransition
      in={props.isShowing}
      timeout={props.timeout || 500}
      classNames={
        props.stepDirection === "forwards"
          ? "TransitionModalFlyInBottom"
          : "TransitionModalFlyInBottom"
      }
      // classNames="TransitionSideFlyInForwards"
      mountOnEnter
      unmountOnExit
    >
      <div
        className={classes.fullScreenCard}
        ref={nodeRef} // Strict mode hack attempt - https://github.com/reactjs/react-transition-group/issues/668
      >
        {props.children}
        <div className={classes.sideBySideSpaceBetween}>
          {props.backButton ? props.backButton : <div></div>}
          {props.skipButton ? props.skipButton : <div></div>}
        </div>
      </div>
    </CSSTransition>
  );
}

export default FullScreenCard;
