// Use site-wide state about the user to fill in name etc. where appropriate
import React from "react";
import { useState, useEffect } from "react";
import { getAuthToken } from "../util/auth";
import { serverHost } from "../util/usefulServerCalls";

const UserContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  user: null,
  incrementPoints: (obj) => {},
});

// Export a provider component
export const UserContextProvider = (props) => {
  // Login status (alternative to using a root-level loader in App.js react router)
  // const [token, setToken] = useState(null);
  // const userIsLoggedIn = !!token;

  // Adding this in 2023-09
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // User data available in app
  const emptyUser = {
    name: "",
    email: "",
    photo: "",
    points: {},
    _id: "",
  };
  const [user, setUser] = useState(emptyUser);

  const loginHandler = (token) => {
    // setToken(token);
    loadUserFromServer();
    console.log("Login handler called");
    setIsLoggedIn(true); // Adding this in 2023-09
    console.log("We've set logged in as true");
  };
  const logoutHandler = () => {
    // setToken(null);
    console.log("👉 Removing user context");
    setUser(emptyUser);
    setIsLoggedIn(false); // Adding this in 2023-09
  };

  // As an example for now
  const incrementPointsHandler = (obj) => {
    // Type of points
    const type = obj.type;
    // Number of points
    const points = obj.points;

    console.log("Incrementing points", type, points);

    setUser((prevUser) => {
      return {
        ...prevUser,
        points: { ...prevUser.points, [type]: prevUser.points[type] + points },
      };
    });

    // And locally (really perhaps should use the server response)
    // setUser((prevUser) => {
    //   return { ...prevUser, points: prevUser.points + points };
    // });
  };

  // Load user data from server
  const loadUserFromServer = async () => {
    let token = getAuthToken();
    if (
      !token ||
      token === "undefined" ||
      token === "null" ||
      token === "EXPIRED"
    ) {
      // console.log("👉 No token found, so not loading user data");
      // Remove token from browser
      localStorage.removeItem("token");
      localStorage.removeItem("expiration");
      logoutHandler();

      return;
    } else {
      console.log("👉 Loading user data using token", token);
    }
    console.log("Awaiting response on 'me' endpoint");
    const response = await fetch(`${serverHost()}/users/me`, {
      headers: {
        Authorization: "Bearer " + getAuthToken(),
      },
    });
    console.log("Response", response);
    const responseData = await response.json();
    console.log("👉 User data loaded from server", responseData.data);
    const loadedUser = {
      name: responseData.data.name,
      email: responseData.data.email,
      photo: responseData.data.photo,
      points: responseData.data.points,
      credit: responseData.data.credit,
      _id: responseData.data._id,
    };

    // If something went wrong, log out and don't update the user
    if (!responseData.data._id) {
      logoutHandler();
      return;
    }

    setUser(loadedUser);
  };

  useEffect(() => {
    // Load user data from server
    loadUserFromServer();

    // TODO Also get metadata for books that they can access (other than ones in the library)
  }, []);

  const contextValue = {
    // Login stuff
    // token: token,
    // isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    isLoggedIn: isLoggedIn, // Adding this in 2023-09
    // User data
    user: user,
    incrementPoints: incrementPointsHandler,
    reloadUser: loadUserFromServer,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};

// Default export
export default UserContext;
