import { useEffect, useState, useContext } from "react";
import classes from "./ShareThisStoryPanel.module.css";
import axios from "axios";
import { getAuthToken } from "../../util/auth";
import { json, useParams } from "react-router-dom";
import UserContext from "../../store/UserContext";
import TitleBar from "../ui/TitleBar";
import ShareWithFriendCard from "./ShareWithFriendCard";
import { serverHost } from "../../util/usefulServerCalls";
import BtnJaunty from "../ui/BtnJaunty";
import FindNewFriendsPanel from "../friends/FindNewFriendsPanel";

function ShareThisStoryPanel({ alreadyShared, storyContent }) {
  // Story ID
  let params = useParams();
  let storyIdInURL = params.id;

  // Get friends list (not just accepted friends any more - also pending)
  const [acceptedFriends, setAcceptedFriends] = useState([]);

  // Friends list with messages / share state
  const [acceptedFriendsWithShareState, setAcceptedFriendsWithShareState] =
    useState([]);

  // Share messages stored in state
  const [shareMessages, setShareMessages] = useState([]);

  useEffect(() => {
    // Use context in future for friends list
    loadFriendsList();
  }, []);

  // Load friends list
  const loadFriendsList = async () => {
    const response = await axios.get(`${serverHost()}/friendships?status=all`, {
      headers: { Authorization: `Bearer ${getAuthToken()}` },
    });
    if (response.data.status !== "success") {
      throw json({ message: "Could not get friends." }, { status: 500 });
    }
    setAcceptedFriends(response.data.data);
  };

  useEffect(() => {
    populateAcceptedFriendsWithShareState(acceptedFriends);
  }, [acceptedFriends]);

  // Iterate through friends list and populate which have been shared with, and any messages there have been (from alreadyShared variable)
  const populateAcceptedFriendsWithShareState = (friendsList) => {
    let acceptedFriendsWithShareState = [];
    console.log("alreadyShared", alreadyShared);
    friendsList.forEach((friend) => {
      let alreadySharedWithThisFriend = alreadyShared.filter(function (share) {
        //return share?.recipientId?._id === friend?.otherId?._id;
        return (
          // Either the email address matches for those that haven't signed up yet
          friend.otherEmail === share.recipientEmail ||
          // Or the IDs match for those that have, but not the blank ones which are the ones not signed up yet
          (friend?.otherId?._id === share?.recipientId?._id &&
            share.recipientEmail === undefined)
        );
      });
      let sharerMessage = "";
      let recipientComment = "";
      let shareId = undefined;
      if (alreadySharedWithThisFriend.length > 0) {
        sharerMessage = alreadySharedWithThisFriend[0].sharerMessage;
        recipientComment = alreadySharedWithThisFriend[0].recipientComment;
        shareId = alreadySharedWithThisFriend[0]._id;
      }
      acceptedFriendsWithShareState.push({
        ...friend,
        // alreadyShared: alreadySharedWithThisFriend.length > 0,
        shareId: shareId,
        sharerMessage: sharerMessage,
        recipientComment: recipientComment,
      });
    });
    setAcceptedFriendsWithShareState(acceptedFriendsWithShareState);
  };

  // User context for updating share count
  const userCtx = useContext(UserContext);

  // Hanlder for when share message is changed. We need to update state.
  const handleShareMessageChange = (event) => {
    let newValue = event.target.value;
    let friendId = event.target.id;
    let newShareMessages = shareMessages.map((messageObj) => {
      if (messageObj.friendId === friendId) {
        messageObj.message = newValue;
      }
      return messageObj;
    });
    setShareMessages(newShareMessages);
  };

  //// ELEMENTS ////
  const shareWithFriendGrid = (
    <div className={classes.shareCardsGrid}>
      {acceptedFriendsWithShareState.map((friend, i) => {
        console.log("SHARE MESSAGE 😊", friend.shareMessage);

        // If status is pending, write name and "pending" message
        // if (friend.status === "pending") {
        //   return (
        //     <div key={friend._id}>
        //       <p>Pending</p>
        //     </div>
        //   );
        // }

        return (
          <ShareWithFriendCard
            key={friend._id}
            friend={friend}
            onShare={() => {
              shareStoryWithFriend(friend.otherId._id);
            }}
          />
        );
      })}

      {/* {acceptedFriends.map((friend) => {
          // Get share message for this friend
          let shareMessage = shareMessages.filter(function (messageObj) {
            return messageObj.friendId === friend.otherId._id;
          })[0].message;
          console.log(shareMessage);
          if (friend.otherId._id !== storyContent.author._id) {
            // Only show friends who are not the author. Can probably do this filter elsewhere, or perhaps still show friend but just disable/grey out the button?
            // Want to do a similar filter for freinds already shared with. Perhaps it is state on the component we render here, and we pass it in as a prop.
            return (
              <div key={friend.otherId._id}>
                <p>{friend.otherId.name}</p>
                <input
                  type="text"
                  onChange={handleShareMessageChange}
                  value={shareMessage}
                  id={friend.otherId._id}
                ></input>
                <button
                  onClick={() => {
                    shareStoryWithFriend(friend.otherId._id);
                  }}
                >
                  Share with {friend.otherId.name}
                </button>
              </div>
            );
          }
        })} */}
    </div>
  );

  // Removed this, as we show 'add friend' panel instead
  // const noFriendsButton = (
  //   <div className={classes.noFriendsButton}>
  //     <p>You have no friends on Whizz Bang yet.</p>
  //     <br />
  //     <BtnJaunty
  //       text="Add friends"
  //       to="/friends"
  //       imgSrc="/img/art/robot-head.png"
  //     />
  //   </div>
  // );

  // Find new friends panel
  const findNewFriendsPanel = (
    <FindNewFriendsPanel storyIdToShare={storyIdInURL} />
  );

  return (
    <>
      {acceptedFriends.length > 0 && (
        <>
          <article className={classes.section}>
            <TitleBar text="Share with your friends" />
            {shareWithFriendGrid}
          </article>
          <article className={classes.section}>
            <TitleBar text="Invite new friends" />
            {findNewFriendsPanel}
          </article>
        </>
      )}
      {acceptedFriends.length === 0 && (
        <>
          <article className={classes.section}>
            <TitleBar text="Add some friends to share" />
            {findNewFriendsPanel}
          </article>
        </>
      )}
    </>
  );
}

export default ShareThisStoryPanel;
