import classes from "./GoButton.module.css";

function GoButton(props) {
  return (
    <button type="submit" className={classes.goBtn} disabled={props.disabled}>
      Go!
    </button>
  );
}

export default GoButton;
