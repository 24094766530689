import classes from "./PaperImage.module.css";
function PaperImage({ seedText, imgSrc }) {
  // Returns a random number between -magnitude/2 and magnitude/2
  function randomNumberFromSeed(magnitude, seedText, seedPosition) {
    let seedPositionAdj = seedPosition % seedText.length; // Incase seedPosition is too big
    let char = seedText.split("/").slice(-1)[0].charCodeAt(seedPositionAdj);
    return (char % magnitude) - magnitude / 2;
  }

  // Set random values based on seed text
  const randomRotationImage = randomNumberFromSeed(4, seedText, 30);

  // Four random values between 0 and 20
  const borderRadiusRandoms = [
    Math.abs(randomNumberFromSeed(20, seedText, 32)),
    Math.abs(randomNumberFromSeed(20, seedText, 19)),
    Math.abs(randomNumberFromSeed(20, seedText, 2)),
    Math.abs(randomNumberFromSeed(20, seedText, 6)),
  ];

  return (
    <div
      className={classes.paperImageContainer}
      style={{
        // Rotate can't be on paper layer due to z-index issues with the shadow
        transform: "rotate(" + -2.5 * randomRotationImage + "deg)",
        left: randomRotationImage + "px",
      }}
    >
      <img
        src={imgSrc}
        style={{
          borderRadius: `255px ${borderRadiusRandoms[0]}px 225px ${borderRadiusRandoms[1]}px/${borderRadiusRandoms[2]}px 225px ${borderRadiusRandoms[3]}px 255px`,
        }}
      />
    </div>
  );
}

export default PaperImage;
