import PageContent from "../../components/layout/PageContent";
import TitleBar from "../../components/ui/TitleBar";

function CreditPage() {
  return (
    <>
      <PageContent>
        <TitleBar text="Whizz Bang Story Maker is currently free!" />
        <br />
        <p>
          For now, this project is free! To cover our costs, there will soon be
          a small fee to use some features of the Story Maker. You will be able
          to purchase credits here.
        </p>
      </PageContent>
    </>
  );
}

export default CreditPage;
