import classes from "./UserImage.module.css";

function UserImage({ user }) {
  let src, name;
  if (user && user.photo && user.name) {
    src = user.photo;
    name = user.name;
  } else {
    src = "/img/users/questionMark.png";
    name = "a user";
  }
  return (
    <>
      <img className={classes.userImage} src={src} alt={`Image of ${name}`} />
    </>
  );
}

export default UserImage;
