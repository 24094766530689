import classes from "./BtnMinor.module.css";

// import { useState } from "react";

function BtnMinor(props) {
  return (
    <button className={classes.BtnMinor} onClick={props.onClick}>
      <div>
        {props.imgSrc && <img src={"/img/icons/" + props.imgSrc + ".png"} />}
        {props.btnText}
      </div>
    </button>
  );
}

export default BtnMinor;
