import axios from "axios";
import { getAuthToken } from "../../util/auth";
import BtnJaunty from "../ui/BtnJaunty";
import classes from "./FriendCard.module.css";

function FriendCard(props) {
  return (
    <div className={classes.cardBody}>
      {/* LHS */}
      <div className={classes.imgContainer}>
        <img src={props.friend.otherId.photo} alt="profile picture" />
      </div>

      {/* MIDDLE */}
      <div className={classes.nameAndMessageContainer}>
        <h1 className={classes.name}>{props.friend.otherId.name}</h1>
        <p className={classes.small}>{props.friend.otherId.email}</p>
        {props.friend.status === "pending" && props.friend.requestMessage && (
          <div className={classes.requestMessage}>
            <h3 className={classes.small}>{props.friend.otherId.name} says:</h3>
            <p className={classes.speechBubble}>
              "{props.friend.requestMessage}"
            </p>
          </div>
        )}
      </div>

      {/* RHS */}
      <div className={classes.buttonsContainer}>
        {/* Allow them to respond if pending */}
        {props.friend.status === "pending" && (
          <>
            <BtnJaunty
              text="👋 Hello friend!"
              onClick={() => props.onRespond(props.friend.id, "accept")}
              size="tiny"
              colour="purple"
              // imgSrc="/img/art/elements/greenTick.png"
            />
            <BtnJaunty
              text="👎 No thanks"
              onClick={() => props.onRespond(props.friend.id, "reject")}
              size="tiny"
              colour="grey"
              // imgSrc="/img/art/elements/redCross.png"
            />
          </>
        )}

        {/* Show remove button if already friends */}
        {props.friend.status === "accepted" && (
          <BtnJaunty
            text="🫤 Remove friend"
            onClick={() =>
              props.onRemove(props.friend.id, props.friend.originalRole)
            }
            size="tiny"
            colour="red"
          />
        )}
      </div>
    </div>
  );
}

export default FriendCard;
