import React, { useContext } from "react";
import { AudioContext } from "../../store/AudioContext";
import classes from "./StopButton.module.css";
import BtnJaunty from "../../components/ui/BtnJaunty";

const StopButton = () => {
  const { audio, isPlaying, setIsPlaying } = useContext(AudioContext);

  const stopAudio = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0; // Reset the audio to the start
      setIsPlaying(false);
    }
  };

  return (
    // <img
    //   src="/img/art/elements/stop_music_tmp.png"
    //   alt="Stop music button"
    //   onClick={stopAudio}
    //   className={`${classes.stopButton} ${
    //     isPlaying ? classes.stopButtonVisible : ""
    //   }`}
    //   style={{ cursor: "pointer" }}
    // />
    <div
      className={`${classes.stopButton} ${
        isPlaying ? classes.stopButtonVisible : ""
      }`}
    >
      <BtnJaunty
        text="Stop the music!"
        imgSrc="/img/art/elements/stopTheMusic.png"
        onClick={stopAudio}
        colour="purple"
      />
    </div>
  );
};

export default StopButton;
