import { Link } from "react-router-dom";
import FavouriteControllerButton from "../ui/FavouriteControllerButton";
import classes from "./StoryCompletedCard.module.css";
import React, { useContext, useEffect, useState, useRef } from "react";
import FavouritesContext from "../../store/FavouritesContext";
import Confetti from "react-confetti";
import BtnJaunty from "../ui/BtnJaunty";
import UserContext from "../../store/UserContext";

function StoryCompletedCard(props) {
  // For favourites button - we don't want to show it if already fav
  const { itemIsFavourite } = useContext(FavouritesContext);

  // Window size needed for confetti
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const giphyLinks = [
    "uU3oBlPJTx184",
    "3oEdv5jk7miq98Jv0c",
    "WXB88TeARFVvi",
    "VBEd4PzBjdG3S",
    "2fJ3kgoTA96EwddgCZ",
    "uSYQsJQWEv6O4",
    "uJYq7gjdDHqTe",
    "E0KmHELTpq9pK",
    "bS6X4qlDLknJe",
    "l0Exk8EUzSLsrErEQ",
  ];
  const randomGiphy = giphyLinks[Math.floor(Math.random() * giphyLinks.length)];
  const randomGiphyURL = `https://media.giphy.com/media/${randomGiphy}/giphy.gif`;

  // If logged in, we show a 'share with friends' button
  const userCtx = useContext(UserContext);

  return (
    <>
      <Confetti
        numberOfPieces={140}
        width={windowSize.current[0]}
        height={windowSize.current[1]}
        gravity={0.05}
        // friction={0.01}
      />
      <div className={classes.completeContainer}>
        <h2 className={classes.titleText}>
          You finished the story... well done!
        </h2>
        <div className={classes.sideBySide}>
          <div className={classes.giphyContainer}>
            <img className={classes.giphyImage} src={randomGiphyURL} />
          </div>
          <div className={classes.textContainer}>
            <img src="/img/art/animated/dancing.png" />
          </div>
        </div>
        {/* If logged in */}
        {userCtx.user && userCtx.user._id ? (
          <BtnJaunty
            to={`/story/${props.storyId}/share`}
            text="Share it with friends!"
            colour="blue"
            imgSrc="/img/art/robot-head.png"
          />
        ) : (
          <BtnJaunty
            text="Share this story!"
            imgSrc="/img/art/send.png"
            colour="blue"
            size="tiny"
            onClick={() => {
              props.showShareModal(true);
            }}
          />
        )}
        <BtnJaunty
          to={`/story/${props.storyId}`}
          text="Read it again!"
          colour="green"
          imgSrc="/img/art/elements/arrowBackward.png"
        />
        <br />
        {/* {!itemIsFavourite(props.storyId) && (
          <div>
            <p>Click the heart to add this story to your box:</p>
            <FavouriteControllerButton storyId={props.storyId} />
          </div>
        )} */}
      </div>
    </>
  );
}

export default StoryCompletedCard;
