import classes from "./StoryTitleBanner.module.css";
import { NavLink } from "react-router-dom";
import { useContext, useEffect } from "react";
import FavouritesContext from "../../store/FavouritesContext";
import FavouriteControllerButton from "../ui/FavouriteControllerButton";
// import Heart from "../ui/Heart";

function StoryTitleBanner(props) {
  const { addFavourite, removeFavourite, itemIsFavourite } =
    useContext(FavouritesContext);

  // Class for heart
  // useEffect(() => {
  //   let heartClass = classes.heart;
  //   if (itemIsFavourite(props.storyContent.id)) {
  //     heartClass = classes.heart + " " + classes.heartFavourite;
  //   }
  // }, []);

  // Return if story content is loaded. In some strange cases (maybe just test stories) an error occurs when the story content is not loaded for some reason.
  let banner = null;
  if (props.storyContent.author) {
    banner = (
      <>
        <div className={classes.container}>
          {/* Need a blank one to balance the grid */}
          <div></div>

          {/* Middle text */}
          <NavLink to={`/story/${props.storyContent.id}`}>
            <div className={classes.titleWithImage}>
              <img
                className={classes.storyCoverImage}
                src={props.storyContent.coverImage}
              />
              <p>
                {props.storyContent.title}, by {props.storyContent.author.name}
              </p>
            </div>
          </NavLink>

          {/* Right side */}
          {/* <button onClick={addToFavourites} className={classes.addToFavourites}>Add to favourites</button> */}
          <FavouriteControllerButton
            storyId={props.storyContent.id}
            size="small"
          />

          {/* <svg className={classes.heart} viewBox="0 0 32 29.6">
            <path
              d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
            />
          </svg> */}
        </div>
      </>
    );
  }

  return <>{banner}</>;
}

export default StoryTitleBanner;
