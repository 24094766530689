import classes from "./ShareWithFriendCard.module.css";
import ShareCard from "./ShareCard";
import BtnJaunty from "../ui/BtnJaunty";
import { useEffect, useState, useContext } from "react";
import { json, useParams } from "react-router-dom";
import UserContext from "../../store/UserContext";
import axios from "axios";
import { getAuthToken } from "../../util/auth";
import UserComment from "../ui/UserComment";
import { serverHost } from "../../util/usefulServerCalls";

function ShareWithFriendCard({ tmp, friend, onShare }) {
  // Story ID
  let params = useParams();
  let storyIdInURL = params.id;

  // User context for updating share count
  const userCtx = useContext(UserContext);

  // Friend name needs to be set since the user may not have signed up yet
  const [friendName, setFriendName] = useState(friend?.otherId?.name);

  // Is card a 'pending' friend request (outgoing)?
  const [isPendingOutgoing, setIsPendingOutgoing] = useState(false);
  const [isPendingOutgoingEmailOnly, setIsPendingOutgoingEmailOnly] =
    useState(false);

  // Is card a 'pending' friend request (incoming)?
  const [isPendingIncoming, setIsPendingIncoming] = useState(false);

  // Friend name will exist unless the user hasn't yet signed up
  useEffect(() => {
    if (friendName === undefined) {
      // User not yet signed up (must be pending)
      setFriendName(friend.otherEmail);
      setIsPendingOutgoing(true);
      setIsPendingOutgoingEmailOnly(true);
    }

    if (friend.status === "pending" && friend.originalRole === "requester") {
      setIsPendingOutgoing(true);
    }

    if (friend.status === "pending" && friend.originalRole === "recipient") {
      setIsPendingIncoming(true);
    }
  }, [friendName, friend.otherEmail, friend.originalRole, friend.status]);

  // We want to know if it's a pending incoming request

  const friendId = friend?.otherId?._id;
  const friendPhoto = friend?.otherId?.photo;
  const recipientComment = friend?.recipientComment;

  const [sharerMessage, setSharerMessage] = useState(friend.sharerMessage);
  const [shareId, setShareId] = useState(friend.shareId);
  const [isSharing, setIsSharing] = useState(false);

  // const [isShared, setIsShared] = useState(!!shareId);
  const isShared = !!shareId;

  // Did the recipient reply?
  const recipientReplied = !!recipientComment;

  // Handler to share a story with a friend
  const shareStoryWithFriendHandler = async (friendId, friendEmail) => {
    // Get share message for this friend
    // let shareMessage = shareMessages.filter(function (messageObj) {
    //   return messageObj.friendId === friendId;
    // })[0].message;
    setIsSharing(true);

    // Send share request to server
    const response = await axios.post(
      `${serverHost()}/stories/${storyIdInURL}/shares`,
      {
        recipientId: friendId,
        recipientEmail: friendEmail,
        sharerMessage: "",
      },
      {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
      }
    );

    // Error case
    if (response.data.status !== "success") {
      console.log("Something went wrong...");
      throw json(
        { message: "Could not share story with friend." },
        { status: 500 }
      );
    }

    // Increase share count in user context
    userCtx.incrementPoints({ type: "shared", points: 1 });

    // Set state
    // setIsShared(true);
    console.log("About to set shareId: ", response.data);
    setShareId(response.data.data._id);
    setIsSharing(false);
  };

  // Handler to unshare a story with a friend
  const retractShareFromFriend = async (shareId) => {
    console.log("Retracting share");
    setIsSharing(true);
    // Send share request to server
    try {
      const response = await axios.delete(
        `${serverHost()}/stories/${storyIdInURL}/shares/${shareId}`,
        {
          headers: { Authorization: `Bearer ${getAuthToken()}` },
        }
      );
    } catch (err) {
      console.log("Error: ", err);
      alert("Could not share retract share from friend.");
    }

    // Increase share count in user context
    userCtx.incrementPoints({ type: "shared", points: -1 });

    // Set state
    // setIsShared(false);
    setShareId(null);
    setSharerMessage(null);
    setIsSharing(false);
  };

  // Handler for comment on a share
  const commentOnShareHandler = async (comment) => {
    // Send comment to server
    const response = await axios.post(
      `${serverHost()}/shares/${shareId}/comment?actor=sharer`,
      {
        comment,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      }
    );
    if (response.data.status !== "success") {
      throw json({ message: "Could not comment on share." }, { status: 500 });
    }

    setSharerMessage(comment);
    return response.data;
  };

  // Define the share button.
  let shareButton = (
    <BtnJaunty
      text={isSharing ? `Sharing...` : `Share`}
      imgSrc="/img/art/envelope.png"
      colour="purple"
      onClick={() => {
        shareStoryWithFriendHandler(friendId);
      }}
    />
  );

  // If the user is not yet a connection, but they have and ID, just change the text
  let shareButtonPendingOutgoing = (
    <BtnJaunty
      text={isSharing ? `Sharing...` : `Share when we are friends`}
      imgSrc="/img/art/envelope.png"
      colour="purple"
      onClick={() => {
        shareStoryWithFriendHandler(friendId);
      }}
    />
  );

  // If the user is not yet a connection and has not yet signed up, we need to do something alternative
  if (isPendingOutgoingEmailOnly) {
    shareButtonPendingOutgoing = (
      <BtnJaunty
        text={isSharing ? `Sharing...` : `Share when we are friends`}
        imgSrc="/img/art/envelope.png"
        colour="purple"
        onClick={() => {
          shareStoryWithFriendHandler(friendId, friend.otherEmail);
        }}
      />
    );
  }

  // If the user is an incoming request, we need to accept the request and share
  // Not doing this for now
  // if (isPendingIncoming) {
  //   shareButton = (
  //     <BtnJaunty
  //       text={isSharing ? `Sharing...` : `Accept request & share`}
  //       imgSrc="/img/art/envelope.png"
  //       colour="purple"
  //       onClick={() => {
  //         shareStoryWithFriendHandler(friendId); //
  //       }}
  //     />
  //   );
  // }

  // If incoming friend request that we haven't yet dealt with, just don't show it for now as an option to share with
  if (isPendingIncoming) {
    return "";
  }

  return (
    <article className={classes.card}>
      <div
        className={`${classes.cardBody} ${
          isShared ? classes.cardBody__shared : ""
        }`}
      >
        {/* Show photo only if it's a real account, emails don't get a photo */}

        <img
          src={isPendingOutgoing ? "/img/users/26.png" : friendPhoto}
          alt={friendName}
        />
        <p
          // Make text smaller if it's an email not a name
          className={
            isPendingOutgoingEmailOnly ? classes.smallerTextForEmail : ""
          }
        >
          {friendName}
        </p>
        <div className={classes.shareButton}>
          {isShared ? (
            <BtnJaunty
              text={isSharing ? `Loading...` : `Shared`}
              imgSrc="/img/art/envelope.png"
              colour="green"
              onClick={() => {
                retractShareFromFriend(shareId);
              }}
            />
          ) : isPendingOutgoing ? (
            shareButtonPendingOutgoing
          ) : (
            shareButton
          )}
        </div>
      </div>

      {isShared && (
        <div className={classes.shareMessages}>
          <UserComment
            name="My name"
            imgSrc={userCtx.user.photo}
            comment={sharerMessage}
            canSubmit={true}
            onSubmit={commentOnShareHandler}
          />
          {recipientReplied && (
            <UserComment
              name={friendName}
              imgSrc={friendPhoto}
              comment={recipientComment}
            />
          )}
        </div>
      )}
    </article>
  );
}

export default ShareWithFriendCard;
