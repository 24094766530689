import { Link, Outlet } from "react-router-dom";

function FactoryRoot() {
  return (
    <>
      {/* <div className="rootPageContainer">
        <div className="rootPageHeader">
          <Link to="/factory">FACTORY</Link>
        </div>
        <div className="rootPageBody">
          <Outlet />
        </div>
      </div> */}

      <Outlet />
    </>
  );
}

export default FactoryRoot;
