import StoryCard from "../../components/ui/StoryCard";
import PageContent from "../../components/layout/PageContent";
import { useLoaderData } from "react-router-dom";
import TitleBar from "../../components/ui/TitleBar";
import { loadFromLibrary } from "../../util/libraryLoader";
import BtnChoice from "../../components/ui/BtnChoice";
import BtnJaunty from "../../components/ui/BtnJaunty";
import FriendsContext from "../../store/FriendsContext";
import { useContext } from "react";

function SharedStoriesPage() {
  // Get loaded stories
  const data = useLoaderData();

  // Only show stories from friends where we accepted the ID
  const friendsCtx = useContext(FriendsContext);
  const friendsAcceptedIds = friendsCtx.friendsAcceptedIds;

  // Show error if there is one
  if (data && data.isError) {
    return <p>data.error.message</p>;
  }

  // How many stories are there?
  const storyCards = data.map((share) => {
    // Skip if the sharer is not a friend
    if (!friendsAcceptedIds.includes(share.sharerId._id)) {
      return;
    }
    // Shared details put into an object
    let shareDetails = {
      shareId: share.id,
      sharerId: share.sharerId._id,
      sharerName: share.sharerId.name,
      sharerPhoto: share.sharerId.photo,
      sharerMessage: share.sharerMessage,
      recipientComment: share.recipientComment,
    };
    return (
      <StoryCard
        story={share.storyId}
        isShared={true}
        key={share.id}
        shareDetails={shareDetails}
      />
    );
  });

  // If we have shared stories but not yet accepted friend request we may have an array of undefined storyCards
  const hasOnlyUndefined = storyCards.every((element) => element === undefined);

  if (hasOnlyUndefined) {
    return (
      <div className="story-cards-container">
        <br />
        <TitleBar text="Add friends to see stories shared with you!" />
        <p>
          Once you're connected with a friend, you can see the stories you've
          shared with each other!
        </p>
        <div>
          <BtnJaunty
            text="Add friends"
            to="/friends"
            colour="blue"
            imgSrc="/img/art/robot-head.png"
          />
        </div>
      </div>
    );
  }

  if (storyCards.length === 0) {
    return (
      <div className="story-cards-container">
        <br />
        <TitleBar text="No stories are shared with you yet 😭" />
        <p>
          You can add friends or family, and ask them to write you a story and
          share it with you!
        </p>
        <div>
          <BtnJaunty
            text="Add friends"
            to="/friends"
            colour="blue"
            imgSrc="/img/art/robot-head.png"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <PageContent title=""> */}
      <div className="story-cards-container">{storyCards}</div>
      {/* </PageContent> */}
    </>
  );
}

export default SharedStoriesPage;

export async function loader({ request, params }) {
  console.log("Shared stories loader...");
  return await loadFromLibrary("shared");
}
