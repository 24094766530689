import classes from "./GameOverModal.module.css";

function GameOverModal() {
  return (
    <div className={classes.gameOverModal}>
      <h2>Game Over!</h2>
      <p>Try again!</p>
      <button
        className="btn"
        onClick={() => {
          window.location.reload();
        }}
      >
        Restart
      </button>
    </div>
  );
}

export default GameOverModal;
