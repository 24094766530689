import PageContent from "../components/layout/PageContent";
import { Link } from "react-router-dom";
import BigPictureLink from "../components/ui/BigPictureLink";
import BadgeLink from "../components/ui/BadgeLink";
import UserContext from "../store/UserContext";
import { useContext } from "react";

function LibraryPage() {
  // User context
  const userCtx = useContext(UserContext);

  // Get loaded user
  const user = userCtx.user;

  return (
    <>
      <div className="badge-grid-container">
        <div className="badge-grid">
          <BadgeLink
            to="mine"
            text={`Stories by me (${user.name})`}
            imgSrc="/img/art/badges/i_made.png"
          />
          <BadgeLink
            to="shared"
            text="Stories my friends shared"
            imgSrc="/img/art/badges/friends_shared.png"
          />
          <BadgeLink
            to="public"
            text="The Whizz-Bang library"
            imgSrc="/img/art/badges/whizz_bang_library.png"
            comingSoon={true}
          />
        </div>
      </div>
    </>
  );
}

export default LibraryPage;
