import { useState } from "react";
import { useSpeechSynthesis } from "react-speech-kit";

function TestPage() {
  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
    onEnd: () => console.log("Done speaking."),
  });
  const text = "Some dummy text";

  return (
    <>
      <h1>Testing page</h1>
      <h2>Random things</h2>
      <button onClick={() => speak({ text: text })}>Speak</button>
    </>
  );
}

export default TestPage;
