import classes from "./ProfileStat.module.css";

function ProfileStat(props) {
  return (
    <div className={`${classes.profileStat}`}>
      <img src={props.imgSrc} />
      <h2 className={classes.number}>{props.number}</h2>
      <p className={classes.text}>{props.text}</p>
    </div>
  );
}

export default ProfileStat;
