import { useLoaderData, json, useRouteLoaderData } from "react-router-dom";

import { loadFromLibrary } from "../../util/libraryLoader";

import PageContent from "../../components/layout/PageContent";
import StoryCard from "../../components/ui/StoryCard";

function PublicStoriesPage() {
  // Get loaded stories
  // const data = useLoaderData();
  const data = useLoaderData();
  if (data && data.isError) {
    return <p>data.error.message</p>;
  }
  const stories = data;
  console.log("Here are the loaded stories", stories);

  return (
    <>
      <div className="story-cards-container">
        {stories.map((story) => {
          return <StoryCard story={story} key={story.id} />;
        })}
      </div>
    </>
  );
}

export default PublicStoriesPage;

export async function loader({ request, params }) {
  return await loadFromLibrary("public");
}

// export async function loader({ request, params }) {
//   console.log("Request is", request);
//   console.log("Params is", params);
//   const mode = request.url.split("/").pop(); // Get last part of URL, will be public/mine/shared
//   console.log("Mode is", mode);
//   if (mode !== "public" && mode !== "mine" && mode !== "shared") {
//     return null;
//   }

//   // Get auth token
//   const token = getAuthToken();

//   // Load data with axios
//   const response = await axios.get(
//     `${serverHost()}/stories?privacy=${mode}`,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );

//   if (response.data.status !== "success") {
//     throw json(
//       { message: "Could not get stories." },
//       {
//         status: 500,
//       }
//     );
//   }
//   const stories = response.data.data;
//   console.log(stories);
//   return stories;
// }
