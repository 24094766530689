import axios from "axios";
import { useRouteLoaderData, Link, Form } from "react-router-dom";
import PageContent from "../components/layout/PageContent";
import { getAuthToken } from "../util/auth";
import { useContext, useState } from "react";
import UserContext from "../store/UserContext";
import FavouritesContext from "../store/FavouritesContext";
import ReadsContext from "../store/ReadsContext";
import CameraModal from "../components/camera/CameraModal";
import AvatarModal from "../components/camera/AvatarModal";
import TitleBar from "../components/ui/TitleBar";
import classes from "./ProfilePage.module.css";
import ProfileStat from "../components/ui/ProfileStat";
import { serverHost } from "../util/usefulServerCalls";

import "react-html5-camera-photo/build/css/index.css";
import BtnJaunty from "../components/ui/BtnJaunty";

function ProfilePage() {
  // User context
  const userCtx = useContext(UserContext);
  const favouritesCtx = useContext(FavouritesContext);
  const readCtx = useContext(ReadsContext);

  // Get loaded user
  const user = userCtx.user;

  // State for if photo is being taken or avatar being chosen
  const [isTakingPhoto, setIsTakingPhoto] = useState(false);
  const [isChoosingAvatar, setIsChoosingAvatar] = useState(false);

  ////////////////////////////
  // HANDLERS
  ////////////////////////////
  const openCameraModalHandler = () => {
    setIsTakingPhoto(true);
  };

  const openAvatarModalHandler = () => {
    setIsChoosingAvatar(true);
  };

  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    console.log("takePhoto");
  }

  ////////////////////////////
  // ELEMENTS
  ////////////////////////////
  const logoutButton = (
    <Form action="/logout" method="POST">
      <BtnJaunty
        type="submit"
        text="🚪 Log out"
        colour="red"
        // imgSrc="/img/art/elements/arrowForward.png"
        // size="medium"
      />
    </Form>
  );
  const takePhotoButton = (
    <BtnJaunty
      onClick={openCameraModalHandler}
      imgSrc="/img/art/camera_shadow.png"
      text="Take photo"
      // size="medium"
    />
  );
  const chooseAvatarButton = (
    <BtnJaunty
      onClick={openAvatarModalHandler}
      text="Choose picture"
      colour="purple"
      imgSrc="/img/art/elements/questionMark.png"
      // size="medium"
    />
  );

  let creditText, creditImgSrc;
  if (user.credit === 0) {
    creditImgSrc = "/img/art/elements/star_grey.png";
    creditText = (
      <>
        <p>
          You do not have credit.&nbsp;&nbsp;{" "}
          <BtnJaunty
            text="Click here to get some!"
            colour="green"
            to="/credit"
          />
        </p>
      </>
    );
  } else {
    creditImgSrc = "/img/art/elements/star_colour.png";
    creditText = (
      <>
        <p> You have {user.credit} credit.</p>
      </>
    );
  }

  ////////////////////////////
  // RENDER
  ////////////////////////////

  if (!user) {
    return <p>Could not get user</p>;
  }

  return (
    <>
      <div className={`${classes.profileContainer}`}>
        <div className={classes.LHSimage}>
          <img className={classes.profileImage} src={user.photo} />
          <div className={classes.photoActionButtons}>
            {takePhotoButton}
            {chooseAvatarButton}
          </div>
        </div>

        <div className={classes.RHSinfo}>
          <div className={classes.topRightButtons}>{logoutButton}</div>
          <div className={classes.userDetails}>
            <h1>{user.name}</h1>
            {/* {premiumStatus} */}
          </div>
          <div className={classes.userStats}>
            {/* <ProfileStat
              imgSrc="/img/logos/logo1.png"
              number="X"
              text="Friends"
            /> */}

            {/* TODO Credit will go here, removed temporarily */}
            {/* <ProfileStat
              imgSrc={creditImgSrc}
              number={userCtx.user.credit}
              text={creditText}
            /> */}
            <ProfileStat
              imgSrc="/img/art/book.png"
              number={readCtx.reads.length}
              text={`You have read ${readCtx.reads.length} stories`}
            />
            <ProfileStat
              imgSrc="/img/art/machine.png"
              number={user.points.written}
              text={`You have written ${user.points.written} stories`}
            />
            <ProfileStat
              imgSrc="/img/art/envelope.png"
              number={user.points.shared}
              text={`You have shared ${user.points.shared} stories with friends`}
            />
            <ProfileStat
              imgSrc="/img/art/heartRed.png"
              number={favouritesCtx.favourites.length}
              text={`You have ${favouritesCtx.favourites.length} favourite stories in your box`}
            />
          </div>
        </div>

        {/* <Link to="edit">Edit profile</Link> */}

        {isTakingPhoto && (
          <CameraModal
            onClose={() => setIsTakingPhoto(false)}
            isShowing={isTakingPhoto}
            userCtx={userCtx}
          />
        )}

        {isChoosingAvatar && (
          <AvatarModal
            onClose={() => setIsChoosingAvatar(false)}
            isShowing={isChoosingAvatar}
            userCtx={userCtx}
          />
        )}
      </div>
    </>
  );
}

export default ProfilePage;

export async function loader() {
  // Get auth token
  const token = getAuthToken();

  // Load data with axios
  const response = await axios.get(`${serverHost()}/users/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  console.log("Profile loader response", response);

  return response.data.data;
}
