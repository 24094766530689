import BtnJaunty from "../ui/BtnJaunty";
import Modal from "../ui/Modal2";
import classes from "./GameOver.module.css";

const GameOver = function (props) {
  return (
    <Modal onClose={props.onClose} isShowing={props.isShowing}>
      <div className={classes.gameOverContent}>
        <h1>You have run out of &nbsp;❤️ helpers!</h1>
        {/* <p>Image of helpers goes here</p> */}
        <p>
          If you want more helpers next time, go to the 'Settings' page to
          select more.
        </p>
        <BtnJaunty
          text="Try reading again"
          imgSrc="/img/art/elements/arrowBackward.png"
          onClick={props.onRestart}
          colour="blue"
        />
      </div>
    </Modal>
  );
};

export default GameOver;
