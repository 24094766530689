import PageContent from "../../components/layout/PageContent";
import { Link } from "react-router-dom";
import BadgeLink from "../../components/ui/BadgeLink";
import classes from "./FactoryPage.module.css";
import TitleBar from "../../components/ui/TitleBar";
import BtnJaunty from "../../components/ui/BtnJaunty";

function FactoryPage() {
  return (
    <>
      {/* <PageContent title=""> */}
      <div className="badge-grid-container">
        <TitleBar text="Choose your robot:" />
        <div className="badge-grid">
          <BadgeLink
            to="magic"
            text="Question-Bot"
            imgSrc="/img/art/badges/question-bot.png"
          />
          <BadgeLink
            to="seed"
            text="Seedling-Bot"
            imgSrc="/img/art/badges/seedling-bot.png"
            comingSoon={true}
          />
          <BadgeLink
            to="adventure"
            text="Adventure-Bot"
            imgSrc="/img/art/badges/adventure-bot.png"
            comingSoon={true}
          />
          {/* <BadgeLink
            to="write"
            text="Write Your Own!"
            imgSrc="/img/art/badges/keyboard.png"
            comingSoon={true}
          /> */}
        </div>
        {/* TODO add back in when ready */}
        {/* <div className="upsell">
          <TitleBar text="Want to write your own story?" />
          <p>
            You can work with a grown-up to type out your own story!
            <br />
            <br />
            <BtnJaunty
              to="/factory"
              text="Coming soon"
              colour="purple"
              imgSrc="/img/art/badges/keyboard.png"
            />
          </p>
        </div> */}
        );
        {/* <Link to="magic" className="card">
          🪄 Magic story writer
        </Link>
        <Link to="write" className="card">
          ✏️ Write it yourself
        </Link> */}
        {/* </PageContent> */}
      </div>
    </>
  );
}

export default FactoryPage;
