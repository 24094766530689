import React, { useContext, useEffect } from "react";
import UserContext from "../store/UserContext";
import FavouritesContext from "../store/FavouritesContext";
import LoadedStoriesContext from "../store/LoadedStoriesContext";

function ExitPage() {
  // Remove all contexts for logged in user
  const userCtx = useContext(UserContext);
  const favouritesCtx = useContext(FavouritesContext);
  const loadedStoriesCtx = useContext(LoadedStoriesContext);

  useEffect(() => {
    userCtx.logout();
    favouritesCtx.clear();
    loadedStoriesCtx.clear();

    // Redirect to home page
    window.location.href = "/";
  }, []);

  return <></>;
}

export default ExitPage;
