import PageContent from "../components/layout/PageContent";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import UserContext from "../store/UserContext";
import ReadsContext from "../store/ReadsContext";
import FavouritesContext from "../store/FavouritesContext";
import FriendsContext from "../store/FriendsContext";
import LoadedStoriesContext from "../store/LoadedStoriesContext";
import { getAuthToken } from "../util/auth";
import BigPictureLink from "../components/ui/BigPictureLink";
import { useState } from "react";
import classes from "./HomePage.module.css";

function HomePage() {
  // Navigate to marketing site if user is not logged in
  const navigate = useNavigate();

  // User is sent here after logging in, reload Login context
  const userCtx = useContext(UserContext);
  const favouritesCtx = useContext(FavouritesContext);
  const loadedStoriesCtx = useContext(LoadedStoriesContext);
  const readsCtx = useContext(ReadsContext);
  const friendsCtx = useContext(FriendsContext);

  // Select which hint to show
  const [hintToShow, setHintToShow] = useState({
    type: "",
    text: "",
    placement: "",
  });
  useEffect(() => {
    if (
      userCtx.user.points.written === 0 &&
      readsCtx.reads.length === 0 &&
      favouritesCtx.favourites.length === 0
    ) {
      setHintToShow({
        type: "write",
        text: "Try starting here! ➟",
        placement: "left",
      });
    } else if (
      userCtx.user.points.written > 0 &&
      readsCtx.reads.length <= 1 &&
      favouritesCtx.favourites.length === 0
    ) {
      setHintToShow({
        type: "read",
        text: "You can read stories here! ➟",
        placement: "left",
      });
    } else if (
      userCtx.user.points.written > 0 &&
      readsCtx.reads.length > 1 &&
      favouritesCtx.favourites.length === 0
    ) {
      setHintToShow({
        type: "favourites",
        text: "⬅ You can add any story you read to your box by pressing the ❤️ button",
        placement: "right",
      });
    } else if (
      userCtx.user.points.written > 0 &&
      readsCtx.reads.length > 1 &&
      favouritesCtx.favourites.length > 0 &&
      friendsCtx.friends.length === 0
    ) {
      setHintToShow({
        type: "friends",
        text: "⬅ You can invite friends to join you here",
        placement: "right",
      });
    } else {
      setHintToShow({
        type: "",
        text: "",
        placement: "",
      });
    }
  }, [
    userCtx.user.points.written,
    readsCtx.reads.length,
    favouritesCtx.favourites.length,
    friendsCtx.friends.length,
  ]);

  useEffect(() => {
    // Set contexts:
    // Log in and get user favourites
    let token = getAuthToken();
    if (
      token !== "undefined" &&
      token !== "EXPIRED" &&
      !!token &&
      !userCtx.user._id
    ) {
      userCtx.login();
      favouritesCtx.fetchFavourites();
      readsCtx.fetchReads();
      friendsCtx.fetchFriends();
    }

    // If user is not logged in, we load the marketing site
    if (!token) {
      navigate("/");
    }
  }, []);

  ////////////////////////////
  // RENDER
  ////////////////////////////

  return (
    <div className={classes.homePage}>
      <div className={classes.bigButtonContainerSquare}>
        <div className={classes.bigButtonContainer}>
          <BigPictureLink
            to="/factory"
            alt="Go to the story factory to make your own stories!"
            // imgSrc="/img/art/main_make.png"
            // imgSrc="/img/art/machine-tall.png"
            imgSrc="/img/art/write_hover.png"
            imgSrcHover="/img/art/write_base.png"
            className={hintToShow.type === "write" ? "pulseSmall" : null}
            hint={hintToShow.type === "write" ? hintToShow : null}
          />
          <BigPictureLink
            to="/favourites"
            alt="Access your favourite stories here"
            // imgSrc="/img/art/chest.png"
            // imgSrc="/img/art/main_box.png"
            imgSrc="/img/art/box_base.png"
            imgSrcHover="/img/art/box_hover.png"
            className={hintToShow.type === "favourites" ? "pulseSmall" : null}
            hint={hintToShow.type === "favourites" ? hintToShow : null}
          />
          <BigPictureLink
            to="/library"
            alt="Go to the library to read stories"
            // imgSrc="/img/art/main_read.png"
            // imgSrc="/img/art/book.png"
            imgSrc="/img/art/library_base.png"
            imgSrcHover="/img/art/library_hover.png"
            className={hintToShow.type === "read" ? "pulseSmall" : null}
            hint={hintToShow.type === "read" ? hintToShow : null}
          />
          <BigPictureLink
            to="/friends"
            alt="Connect with your friends here"
            // imgSrc="/img/art/main_friends.png"
            // imgSrc="/img/art/robot-head.png"
            imgSrc="/img/art/friends_base.png"
            imgSrcHover="/img/art/friends_hover.png"
            className={hintToShow.type === "friends" ? "pulseSmall" : null}
            hint={hintToShow.type === "friends" ? hintToShow : null}
          />
        </div>
      </div>
    </div>
  );

  // return (
  //   <PageContent title="">
  //     <div className="flexgrid-row">
  //       <div className="flexgrid-column">
  //         <BigPictureLink
  //           // There will be an intermediate page of /factory here
  //           to="/factory/magic"
  //           alt="Go to the story factory to make your own stories!"
  //           imgSrc="/img/art/machine_base.png"
  //           imgSrcHover="/img/art/machine_hover.png"
  //           // text="Create a story!"
  //         />
  //       </div>
  //       <div className="flexgrid-column">
  //         <BigPictureLink
  //           to="/favourites"
  //           alt="See the stories I've saved"
  //           imgSrc="/img/art/pencil_original.png"
  //           imgSrcHover="/img/art/pencil_moved.png"
  //           // text="My treasure box"
  //         />
  //       </div>
  //     </div>
  //     <div className="flexgrid-row">
  //       <div className="flexgrid-column">
  //         <BigPictureLink
  //           to="/library"
  //           alt="Go to the library to read stories!"
  //           imgSrc="/img/art/textWriteStory.png"
  //           imgSrcHover="/img/art/textWriteStory_hover.png"
  //           // text="To the library!"
  //         />
  //       </div>
  //       <div className="flexgrid-column">
  //         <BigPictureLink
  //           to="/friends"
  //           alt="See my friends and invite more"
  //           imgSrc="/img/art/yellowWriteStory.png"
  //           imgSrcHover="/img/art/yellowWriteStory_hover.png"
  //           // text="My friends"
  //         />
  //       </div>
  //     </div>
  //   </PageContent>
  // );
}

export default HomePage;
