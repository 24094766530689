// Takes an array of friends and renders a FriendCard for each one
// filtered under certain conditions
import FriendCard from "./FriendCard";

import classes from "./FriendsPanel.module.css";

function FriendsPanel(props) {
  let filteredFriends;
  if (props.filter === "accepted") {
    filteredFriends = props.friends.filter(
      (friend) => friend.status === "accepted"
    );
  }
  if (props.filter === "incoming") {
    filteredFriends = props.friends.filter(
      (friend) => friend.status === "pending"
    );
  }

  return (
    <div className={classes.friendsGrid}>
      {filteredFriends.map((friend) => {
        return (
          <FriendCard
            key={friend._id}
            friend={friend}
            setFriends={props.setFriends}
            friends={props.friends}
            onRespond={props.onRespond}
            onRemove={props.onRemove}
          />
        );
      })}
    </div>
  );
}

export default FriendsPanel;
