import { useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams, json } from "react-router-dom";
import { getAuthToken } from "../../util/auth";
import LoadedStoriesContext from "../../store/LoadedStoriesContext";
import axios from "axios";
import UserContext from "../../store/UserContext";
import StoryLives from "../../components/story/StoryLives";
import StoryTitleBanner from "../../components/story/StoryTitleBanner";
import { serverHost } from "../../util/usefulServerCalls";

import classes from "./StoryRoot.module.css";

function StoryRoot() {
  const navigate = useNavigate();
  let params = useParams();
  let storyIdInURL = params.id;

  // Loaded stories from context provider
  const loadedStoriesCtx = useContext(LoadedStoriesContext);
  const userCtx = useContext(UserContext);

  // State
  const [storyContent, setStoryContent] = useState({}); // Story content
  const [sharesFromMe, setSharesFromMe] = useState([]); // Shares (from me)
  const [sharesWithMe, setSharesWithMe] = useState([]); // Shares (with me)
  const [storyParameters, setStoryParameters] = useState({
    difficulty: [],
    numberOfLives: 5,
    livesEmoji: "❤️",
  }); // Story parameters

  // Load story content
  const loadStoryContent = async (storyIdInURL) => {
    // Also save the story into context here so we do not have to reload it if they leave and return to this page
    const response = await axios.get(
      `${serverHost()}/stories/${storyIdInURL}`,
      {
        headers: { Authorization: "Bearer " + getAuthToken() },
      }
    );
    if (response.data.status !== "success") {
      throw json({ message: "Could not get story." }, { status: 500 });
    }
    setStoryContent(response.data.data);
    if (!loadedStoriesCtx.isStoryLoaded(response.data.data._id)) {
      loadedStoriesCtx.addLoadedStory(response.data.data);
    }
  };

  // Load shares of this story FROM me TO others (if any)
  const loadSharesFromMe = async (storyIdInURL) => {
    const response = await axios.get(
      `${serverHost()}/stories/${storyIdInURL}/shares?direction=sent`,
      { headers: { Authorization: "Bearer " + getAuthToken() } }
    );
    if (response.data.status !== "success") {
      throw json({ message: "Could not get shares." }, { status: 500 });
    }
    setSharesFromMe(response.data.data);
  };

  // Load shares of this story WITH me FROM others (if any)
  const loadSharesWithMe = async (storyIdInURL) => {
    const response = await axios.get(
      `${serverHost()}/stories/${storyIdInURL}/shares?direction=received`,
      { headers: { Authorization: "Bearer " + getAuthToken() } }
    );
    if (response.data.status !== "success") {
      throw json({ message: "Could not get shares." }, { status: 500 });
    }
    setSharesWithMe(response.data.data);
  };

  useEffect(() => {
    // if (!storyContent || !storyContent.id !== storyIdInURL) {
    //   console.log("Story content not loaded or not correct story. Loading...");
    //   loadStoryContent(storyIdInURL);
    // }
    loadStoryContent(storyIdInURL);
    // When page loads, load shares from me and shares with me
    // This should perhaps be done only on the share page? Or would that relad them every time? Maybe that is OK.
    loadSharesFromMe(storyIdInURL);
    loadSharesWithMe(storyIdInURL);
  }, [storyIdInURL]);

  // if (window.location.href.includes("share") && !storyContent._id) {
  //   console.log(">>>> RENAVIGATE");
  //   navigate(`/story/${storyIdInURL}`);
  // }

  // If storyContent is blank (e.g. page has been reloaded) we should be running useEffect.
  // But for some reason not working, so we can load storyContent here if it is blank.

  if (!storyContent._id && window.location.href.includes("share")) {
    navigate(`/story/${storyIdInURL}`);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StoryTitleBanner storyContent={storyContent} />
      <div className={classes.storyContainer}>
        <Outlet
          context={[
            storyContent,
            setStoryContent,
            sharesFromMe,
            setSharesFromMe,
            sharesWithMe,
            setSharesWithMe,
            storyParameters,
            setStoryParameters,
          ]}
        />
      </div>
    </>
  );

  // return (
  //   <div className="container">
  //     <h1>📘 {storyContent.title}</h1>
  //     <h2>
  //       {storyContent.author && storyContent.author._id === userCtx.user._id
  //         ? `You wrote this story (make this a visual clue)`
  //         : `Story by ${
  //             storyContent.author && storyContent.author.name
  //           } (make this a visual clue)`}
  //     </h2>
  //     <i
  //       onClick={() => {
  //         navigate(`/story/${storyIdInURL}`);
  //       }}
  //     >
  //       (Back to this story's homepage)
  //     </i>
  //     <div className="flex-row">
  //       <button
  //         onClick={() => {
  //           console.log(storyContent);
  //         }}
  //       >
  //         See storyContent state
  //       </button>
  //       <button
  //         onClick={() => {
  //           console.log(sharesFromMe);
  //         }}
  //       >
  //         See sharesFromMe state
  //       </button>
  //       <button
  //         onClick={() => {
  //           console.log(sharesWithMe);
  //         }}
  //       >
  //         See sharesWithMe state
  //       </button>
  //       <button
  //         onClick={() => {
  //           console.log(loadedStoriesCtx);
  //         }}
  //       >
  //         See loadedStoriesCtx
  //       </button>
  //       <button
  //         onClick={() => {
  //           console.log(storyParameters);
  //         }}
  //       >
  //         See story parameters
  //       </button>
  //     </div>
  //     <Outlet
  //       context={[
  //         storyContent,
  //         setStoryContent,
  //         sharesFromMe,
  //         setSharesFromMe,
  //         sharesWithMe,
  //         setSharesWithMe,
  //         storyParameters,
  //         setStoryParameters,
  //       ]}
  //     />
  //   </div>
  // );
}

export default StoryRoot;
