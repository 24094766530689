import React, { useState } from "react";
import { isCompositeComponent } from "react-dom/test-utils";

// When a story is loaded to be read, we store it here.
// Thus if we come back to it in the same session, we don't need to reload it.
// We can switch between the pre/read/post parts of the story without reloading

// TODO clear context when we log out

const LoadedStoriesContext = React.createContext({
  loadedStories: {},
  addLoadedStory: (storyObj) => {},
  isStoryLoaded: (storyId) => {},
  clear: () => {},
});

// Export a provider component
export const LoadedStoriesContextProvider = (props) => {
  // Loaded stories
  const [loadedStories, setLoadedStories] = useState([]);

  const addLoadedStory = (storyObj) => {
    // Add to context
    const newStoryId = storyObj._id;
    setLoadedStories((prevLoadedStories) => {
      return { ...prevLoadedStories, [newStoryId]: storyObj };
    });
  };

  const isStoryLoadedHandler = (storyId) => {
    return !!loadedStories[storyId];
  };

  const emptyLoadedStoriesHandler = () => {
    setLoadedStories([]);
  };

  const context = {
    loadedStories: loadedStories,
    addLoadedStory: addLoadedStory,
    isStoryLoaded: isStoryLoadedHandler,
    clear: emptyLoadedStoriesHandler,
  };

  return (
    <LoadedStoriesContext.Provider value={context}>
      {props.children}
    </LoadedStoriesContext.Provider>
  );
};

export default LoadedStoriesContext;
