import classes from "./PageContent.module.css";

function PageContent({ title, children }) {
  return (
    <div className={`${classes.content} container`}>
      <h1 className={classes.pageTitle}>{title}</h1>
      {children}
    </div>
  );
}

export default PageContent;
