import React from "react";
import classes from "./BuilderCard.module.css";
import { CSSTransition } from "react-transition-group";
import Card from "../../components/ui/Card";
import BtnChoice from "../../components/ui/BtnChoice";
import PaperCard from "../../components/ui/PaperCard";

function BuilderCard(props) {
  // Image on left hand side
  // If passed through, show it
  // If not, choose a random robot image
  let leftHandSideImage;
  if (props.imgSrc) {
    leftHandSideImage = <img src={props.imgSrc} />;
  } else {
    leftHandSideImage = <img src={"/img/robots/blue.png"} />;
  }

  const nodeRef = React.useRef(null);
  return (
    <CSSTransition
      in={props.isShowing}
      timeout={props.timeout || 300}
      classNames={
        props.stepDirection === "forwards"
          ? "TransitionSideFlyInForwards"
          : "TransitionSideFlyInBackwards"
      }
      // classNames="TransitionSideFlyInForwards"
      mountOnEnter
      unmountOnExit
    >
      <div
        className={classes.builderCard}
        ref={nodeRef} // Strict mode hack attempt - https://github.com/reactjs/react-transition-group/issues/668
      >
        <PaperCard>
          <div className={classes.topOverBottom}>
            <div className={classes.sideBySide}>
              <div className={classes.imageHalf}>{leftHandSideImage}</div>

              {/* Question */}
              <div className={classes.textHalf}>
                <div className={classes.question}>
                  <label htmlFor={props.id}>
                    <h2 className={classes.preQuestion}>{props.preQuestion}</h2>
                    <h2>{props.question}</h2>
                  </label>
                </div>

                {/* Any content below question */}
                {props.buttonChoices}
                {props.freeformIfChosen}
                {props.freeformField}
                {props.children}
              </div>
            </div>
            <div className={classes.sideBySideSpaceBetween}>
              {props.backButton ? props.backButton : <div></div>}
              {props.skipButton ? props.skipButton : <div></div>}
            </div>
          </div>
          {/* <input type="text" id={props.id} ref={props.ref} />
        <button type="submit">Let's go!</button>
        <button type="button" onClick={props.onBack}>
          Back
        </button> */}
          {/* </form> */}
        </PaperCard>
      </div>
    </CSSTransition>
  );
}

export default BuilderCard;
